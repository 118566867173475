import {ContentLanguages, ContentTypes, DayOfWeek, HighlightType, Roles, VersionStatus} from "../constants/common";

export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

export const isAdmin = (role) => {
    return role === 1;
}

export const getRoleDisplay = (role, accessRights) => {
    if (role === 1) {
        return "Admin"
    } else {
        let role_displayed = [];
        accessRights.forEach(item => {
            if (item.granted) {
                role_displayed.push(getAccessRightItemDisplay(item.module_key))
            }
        })
        return role_displayed.join(', ')
    }
}

export const getRoleSelectedDisplay = (accessRights) => {
    let role_displayed = [];
    accessRights.map(item => role_displayed.push(getAccessRightItemDisplay(item)))
    return role_displayed.join(', ')
}

export const getAccessRightItemDisplay = (accessRight) => {
    const accessRightItem = Roles.find(item => item.value === accessRight)
    return accessRightItem ? accessRightItem.label : '';
}

export const getLanguages = () => {
    return JSON.parse(localStorage.getItem("languages"));
}

export const getLanguageDisplay = (language) => {
    const languages = getLanguages();
    const lang = languages.find(item => item.language_id === language)
    return lang ? lang.name_en : '';
}

export const getHighlightTypeItemDisplay = (highlightType) => {
    const highlightTypeItem = HighlightType.find(item => item.value === highlightType)
    return highlightTypeItem ? highlightTypeItem.label : '';
}

export const getCategoryByPath = (path, categories) => {
    return categories.find(item => item.path === path);
}

export const getContentTypeDisplay = (type) => {
    const contentType = ContentTypes.find(item => item.value === type);
    return contentType ? contentType.label : '';
}

export const getContentLanguageDisplay = (type) => {
    const lang = ContentLanguages.find(item => item.value === type);
    return lang ? lang.label : '';
}

export const getVersionStatusDisplay = (type) => {
    const lang = VersionStatus.find(item => item.value === type);
    return lang ? lang.label : '';
}

export const getDayOfWeekSelectedDisplay = (itemSelected) => {
    let dow_displayed = [];
    const allIndex = itemSelected.findIndex(item => item === '-1');
    if (allIndex === -1) {
        itemSelected.forEach(item => {
            const dow = getDayOfWeekDisplay(item);
            if (dow && dow !== '') {
                dow_displayed.push(getDayOfWeekDisplay(item))
            }
        })
        return dow_displayed.length > 0 ? dow_displayed.join(', ') : '';
    } else {
        return 'All days';
    }
}

export const getDayOfWeekDisplay = (type) => {
    const lang = DayOfWeek.find(item => item.value === type);
    return lang ? lang.label : '';
}

export const formatCurrency = (n, currency) => {
    if (!n) {
        return ''
    }
    return (n < 0 ? '-' : '') + currency + Math.abs(n).toFixed(0).replace(/./g, function (c, i, a) {
        return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
    });
}

export const downLoadFile = (response, filename = null) => {
    if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(response, filename);
    } else {
        const downloadLink = document.createElement('a');
        downloadLink.download = filename;
        downloadLink.style1 = 'display: none';
        downloadLink.href = window.URL.createObjectURL(response);
        if (filename) {
            downloadLink.download = filename;
        }
        document.body.appendChild(downloadLink);
        downloadLink.click();
        window.URL.revokeObjectURL(downloadLink.href);
        downloadLink.parentNode.removeChild(downloadLink);
    }
}