// Essential for all components
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import { Flex } from 'reflexbox'
import {Button} from "@material-ui/core";
import { styled } from '@material-ui/styles';
import get from 'lodash-es/get';
import moment from 'moment';

import {setBreadcrumb} from "../../Redux/Action/breadcrumbAction";
import {getUserInfo} from "../../Redux/Action/authAction";
import step1Green from "../../images/step-1-green.png";
import step2White from "../../images/step-2-white.png";
import step3White from "../../images/step-3-white.png";
import riskIndex1 from "../../images/risk-index-1.png";
import riskIndex2 from "../../images/risk-index-2.png";
import riskIndex3 from "../../images/risk-index-3.png";
import riskIndex4 from "../../images/risk-index-4.png";
import riskIndex5 from "../../images/risk-index-5.png";
import riskIndex6 from "../../images/risk-index-6.png";
import riskIndex7 from "../../images/risk-index-7.png";
import backIcon from "../../images/back.png";
import quarterly from "../../images/Landingpg/Quarterly Fund fact sheet.png";
import { apiFunds } from '../../Api/_ApiFunds';
import { apiUserAccount } from '../../Api/_ApiUserAccount';

import bullhorn from "../../images/ManageFund/bullhorn.png";
import { apiTradeHistories } from '../../Api/_ApiTradeHistories';

const FundContainer = styled(Flex)({
    backgroundColor: 'white',
    width: '100%',
    paddingTop: '1.5rem',
    paddingBottom: '1.5rem',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    border: 1,
    borderStyle: 'solid',
    borderColor: '#e0e0e0',
    cursor: 'pointer'
});

const OneTimeFund = props => {
    const { t, fund, onClick } = props;
    const title = get(fund, 'title')
    const amount = get(fund, 'amount')
    return (
        <FundContainer marginY="1rem" flexDirection="column" onClick={onClick}>
            <Flex className="light-black">
                    {t(`ManageFunds:${title}`)}
                </Flex>
                <Flex flexDirection="row" justifyContent="space-between" mt="1.5rem">
                    <Flex className="small-font-size">
                        {t("ManageFunds:totalAmount")}
                    </Flex>
                    <Flex className="black-text">
                        {`${t("Common:General.hkd")}`} {Number(amount).toLocaleString('en', {maximumFractionDigits: 2, minimumFractionDigits: 2})}
                    </Flex>
            </Flex>
        </FundContainer>
    )
}

const FundToFund = props => {
    const { t, fund, onClick } = props;
    const { title, subtitle, reviewDate, riskIndex, unit, amount, sequence } = fund;
    let riskIcon = null;
    switch (+riskIndex) {
        case 1: {
            riskIcon = riskIndex1;
            break;
        }
        case 2: {
            riskIcon = riskIndex2;
            break;
        }
        case 3: {
            riskIcon = riskIndex3;
            break;
        }
        case 4: {
            riskIcon = riskIndex4;
            break;
        }
        case 5: {
            riskIcon = riskIndex5;
            break;
        }
        case 6: {
            riskIcon = riskIndex6;
            break;
        }
        case 7: {
            riskIcon = riskIndex7;
            break;
        }
        default: {}
    }
    if (sequence) {
        return (
            <FundContainer marginY="1rem" flexDirection="column" onClick={()=>onClick(fund)}>
                <Flex className="light-black">
                    {subtitle}
                </Flex>
                <Flex className="light-black">
                    {t(`Fund:${title}`)}
                </Flex>
                <Flex flexDirection="row" justifyContent="space-between" alignItems="center" mt="1.5rem">
                    <Flex className="small-font-size">
                        {t("ManageFunds:riskClass")}
                    </Flex>
                    {reviewDate ? <Flex flexDirection="column">
                        <img className="risk-index-icon" src={riskIcon} alt="riskIcon" />
                        <Flex className="small-font-size" textAlign="right">
                            {t("ManageFunds:asOf")} {moment(reviewDate).format('DD/MM/YYYY')}
                        </Flex>
                    </Flex> :
                    <Flex fontSize="1rem">
                        {subtitle.includes('DIS') ? <Flex flexDirection="column" alignItems="flex-end">
                            <Flex>{t("ManageFunds:referToRiskClassOf")}</Flex>
                            <Flex>{t("ManageFunds:riskClassOfSHK148SHK149")}</Flex>
                        </Flex> : '-'}
                    </Flex>}
                </Flex>
                <LineBreak />
                {unit && <Flex flexDirection="row" justifyContent="space-between">
                    <Flex className="small-font-size">
                        {t("ManageFunds:holdingUnits")} /
                    </Flex>
                    <Flex className="black-text">
                        {unit.toLocaleString('en', {maximumFractionDigits: 3, minimumFractionDigits: 3})}
                    </Flex>
                </Flex>}
                <Flex flexDirection="row" justifyContent="space-between">
                    <Flex className="small-font-size">
                        {t("ManageFunds:balance")}
                    </Flex>
                    <Flex className="black-text">
                        {t("Common:General.hkd")} {amount.toLocaleString('en', {maximumFractionDigits: 2, minimumFractionDigits: 2})}
                    </Flex>
                </Flex>
            </FundContainer>
        )
    }
}

const FundToFundList = (props) => {
    const { t, onClick, funds } = props;
    return funds.map((fund, index) => <FundToFund key={index} fund={fund} onClick={onClick} t={t} />)
}

const LineBreak = (props) => {
    const { text } = props;
    return ( text ?
        <div className="strike" style={{ marginTop: '1rem', marginBottom: '1rem', fontWeight: 'bold' }}>
            <span>{text}</span>
        </div> :
        <hr style={{ marginTop: '1.5rem', marginBottom: '1.5rem' }}/>
    )
}

class ManageFunds1 extends Component {

    state = {
        oneTimeFund: [],
        fundToFund: [],
        instructionType: this.props.history.location.state.instructionType,
        asOfDate: null,
        hasInProgressInstruction: null
    };

    componentDidMount() {
        this._getUserFunds();
        this._getAsOfDate();
        this.getFundSwitchHistory();
    }

    dynamicSort(property) {
        let sortOrder = 1;
        if(property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a,b) {
            /* next line works with strings and numbers, 
            * and you may want to customize it to your needs
            */
            let result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    getFundSwitchHistory = () => {
        const { auth } = this.props;
        const username = auth.userInfo.username;
        const params = {
            user: username,
            fund_type: 'fundSwitch',
            status: 'in_progress'
        }
        apiTradeHistories.getUserTradeHistories(params).then(obj => {
            if (obj && obj.status === 200 && obj.data.length > 0) {
                this.setState({ hasInProgressInstruction: true });
            }
        });
    }

    _getAsOfDate = () => {
        apiFunds.getCurrentAsOfDate().then(obj => {
            const asOfDate = obj.data.as_of_date;
            this.setState({ asOfDate: asOfDate });
        })
    }

    _getDISFund = () => {
        return apiFunds.getFundVersion().then(res => {
            if (res && res.status === 200) {
                const version = res.data.int_value;
                const params = {
                    version: version,
                    fund_code: 'DIS148 / DIS149'
                }
                return apiFunds.getAllFunds(params).then(obj => {
                    if (obj && obj.status === 200) {
                        return obj.data[0];
                    }
                })
            }
        })
    }

    _getUserFunds = () => {
        const username = this.props.auth.userInfo.username;
        this._getDISFund().then(DISFund => {
            const body = {
                user: username,
                '$expand': 'fund',
            }
    
            apiFunds.getUserFunds(body).then(obj => {
                const fundToFund = [];
                let allFundAmount = 0;
                const DISFunds = [];
                if (obj.data.length > 0) {
                    obj.data.forEach(item => {
                        allFundAmount += item.amount
                        const fund = {
                            fundId: item.fund.fund_id,
                            fundCode: item.fund.fund_code,
                            fund: item.fund,
                            title: item.fund.fund_name,
                            subtitle: item.fund.fund_code,
                            date: item.fund.latest_date,
                            reviewDate: item.fund.review_date,
                            riskIndex: item.fund.risk_index,
                            unit: item.holding_unit,
                            interest: item.fund.interst,
                            amount: item.amount,
                            sequence: item.fund.sequence,
                        }
                        if (item.fund.fund_code.includes('DIS')) {
                            DISFunds.push(fund);
                        } else {
                            fundToFund.push(fund);
                        }
                    });
                    if (DISFunds.length > 0) {
                        DISFund.amount = 0;
                        DISFund.fundId = DISFund.fund_id;
                        DISFund.subtitle = DISFund.fund_code;
                        DISFund.title = DISFund.fund_name;
                        DISFund.unit = '-';
                        DISFund.reviewDate = null;
                        DISFunds.forEach(fund => {
                            DISFund.amount += fund.amount
                        })
                        fundToFund.push(DISFund);
                    }
                    const oneTimeFund = [{
                        title: 'allFunds',
                        amount: allFundAmount,
                        unit: null,
                    }]
                    this.setState({ fundToFund: fundToFund.sort(this.dynamicSort("sequence")), oneTimeFund: oneTimeFund });
                } else {
                    apiFunds.getFundVersion().then(res => {
                        if (res && res.status === 200) {
                            const version = res.data.int_value;
                            apiUserAccount.getUserAccount(username, version).then(obj2 => {
                                if (obj2 && obj2.status === 200) {
                                    const allFundAmount = obj2.data[0].total_fund_balance;
                                    const oneTimeFund = [{
                                        title: 'allFunds',
                                        amount: allFundAmount,
                                        unit: null,
                                    }]
                                    this.setState({ fundToFund: fundToFund.sort(this.dynamicSort("sequence")), oneTimeFund: oneTimeFund });
                                }
                            })
                        }
                    })
                }
            });
        })
    }

    // BUTTON FUNCTION

    back = () => {
        const { history } = this.props;
        history.goBack();
    }

    handleClick = (fundType) => {
        const { i18n } = this.props;
        const { instructionType } = this.state;
        this.props.history.push('/' + i18n.language + '/manage-funds/2', { fundType: fundType, instructionType: instructionType, allFunds: !fundType.riskIndex && fundType.amount && !fundType.unit ? true : false });
    }

    render() {
        const { t, i18n } = this.props;
        const { fundToFund, oneTimeFund, asOfDate, hasInProgressInstruction } = this.state;
        const isChinese = i18n.language === 'zh-HK'
        const locale = isChinese === 'zh-HK' ? 'zh-hk' : 'en';
        const asOfDateFormat = isChinese ? 'YYYY年M月D日' : 'D MMM, YYYY'

        return (                    
            <div>
                <Flex className="main__container fund-manager">
                    <Flex flexDirection="column" width="100%">
                        <Flex flex={1} justifyContent="start" alignItems="center" className="header-title" style={{ paddingBottom: '0'}}>
                            <img src={backIcon} className="back-icon" alt="back-icon" onClick={this.back}/>
                            <Flex ml="1rem">
                                {`${t("ManageFunds:manageFunds")}`}
                            </Flex>
                        </Flex>
                        <Flex flex={1} flexDirection="column" justifyContent="start" className="container" marginTop="1rem">
                            <Flex flex={1} justifyContent="start" alignItems="center" marginY="1rem">
                                <img src={quarterly} className="quarterly" alt="back-icon" onClick={this.back}/>
                                <Flex ml="1rem" fontSize="1.3rem">
                                    {`${t("ManageFunds:quarterly")}`}
                                </Flex>
                            </Flex>
                            <Flex mb="1rem" justifyContent="flex-start" fontSize="1rem">
                                <Flex alignItems="center" mr={i18n.language === 'en-US' ? '2vw' : '5vw'}>
                                    <img className="step-icon" src={step1Green} alt="step-1-green" />
                                    <Flex ml={i18n.language !== 'en-US' && '0.5rem'}>
                                        {t("ManageFunds:step1Green")}
                                    </Flex>
                                </Flex>

                                <Flex alignItems="center" mr={i18n.language === 'en-US' ? '2vw' : '5vw'}>
                                    <img className="step-icon" src={step2White} alt="step-2-white" />
                                    <Flex ml={i18n.language !== 'en-US' && '0.5rem'}>
                                        {t("ManageFunds:step2White")}
                                    </Flex>
                                </Flex>

                                <Flex alignItems="center" mr={i18n.language === 'en-US' ? '2vw' : '5vw'}>
                                    <img className="step-icon" src={step3White} alt="step-3-white" />
                                    <Flex ml={i18n.language !== 'en-US' && '0.5rem'}>
                                        {t("ManageFunds:step3White")}
                                    </Flex>
                                </Flex>
                            </Flex>
                            
                            <Flex flex={1} justifyContent="start" className="section-title black-text large-font-size">
                                {t("ManageFunds:selectWhichFundToSwitchOut")}
                            </Flex>
                            <Flex flex={1} justifyContent="start" className="light-black mt10 medium-font-size ">
                                {t("ManageFunds:switchOutAllFundsOrAnIndividualFundAtATime")}
                            </Flex>
                            {hasInProgressInstruction && <Flex flex={1} justifyContent="center" mt="1rem">
                                <div style={{width: '5%', backgroundColor: '#E38400', textAlign: 'center'}}>
                                    <img src={bullhorn} alt="bullhorn" style={{width: '70%', paddingTop: '1rem'}} />
                                </div>
                                <div style={{width: '95%', padding: '1rem', color: 'black', fontSize: '1rem', backgroundColor: 'white' }}>
                                    {t("ManageFunds:note")}
                                </div>
                            </Flex>}
                            <Flex flex={1} justifyContent="start" className="mt40 small-font-size">
                                {t("ManageFunds:AsOf")} {moment(asOfDate).locale(locale).format(asOfDateFormat)}
                            </Flex>

                            <Flex flex={1} justifyContent="start" className="mt20 black-text medium-font-size" style={{fontWeight: 'bold'}}>
                                {t("ManageFunds:switchOutAllFunds")}
                            </Flex>
                            <Flex flex={1} justifyContent="start" className="black-text medium-font-size" style={{fontWeight: 'bold'}}>
                                <p style={{ fontSize: 'inherit' }}>
                                    ({t("ManageFunds:oneTimeRebalancing")})&nbsp;
                                    {/*<a href={`https://www.manulife.com.hk/${isChinese ? 'zh-hk' : 'en'}/individual/services/manage-your-account/switch-funds-pf-how-it-works.html`} target="_blank" rel="noopener noreferrer" className='hyperlink link'>{t("Common:General.learnMore")}</a>*/}
                                </p>
                            </Flex>

                            <OneTimeFund t={t} fund={oneTimeFund[0]} onClick={()=>this.handleClick(oneTimeFund[0])} />

                            <LineBreak text={t("Common:General.or")} />

                            <Flex flex={1} justifyContent="start" className="mt10 black-text medium-font-size" fontWeight="bold">
                                {t("ManageFunds:switchOutAnIndividualFund")}
                            </Flex>
                            <Flex flex={1} justifyContent="start" className="black-text medium-font-size" fontWeight="bold">
                                <p style={{ fontSize: 'inherit' }}>
                                    ({t("ManageFunds:fundToFundSwitch")})&nbsp;
                                    {/*<a href={`https://www.manulife.com.hk/${isChinese ? 'zh-hk' : 'en'}/individual/services/manage-your-account/switch-funds-pf-how-it-works.html#switch`} target="_blank" rel="noopener noreferrer" className='hyperlink link'>{t("Common:General.learnMore")}</a>*/}
                                </p>
                            </Flex>

                            <FundToFundList t={t} onClick={this.handleClick} funds={fundToFund} />

                            {/*<Flex mt="1.5rem" className="small-font-size light-black">*/}
                            {/*    <p>*/}
                            {/*        ^ {t("ManageFunds:toLearnMoreAboutTheManulifeRiskClassPlease")}*/}
                            {/*        <a href={`https://individuallogin.manulife.com.hk/tfm/fcm/fsout?lang=${isChinese ? 'zh_TW' : 'en_US'}`} target="_blank" rel="noopener noreferrer" className='hyperlink link'>{t("Common:General.clickHere")}</a>{i18n.language === 'en-US' ? '.' : '。'}*/}
                            {/*    </p>*/}
                            {/*</Flex>*/}

                            <Flex flex={1} className="button-wrapper" mt="2rem">
                                <Button onClick={this.back} className="transparent-button m0 p1rem black-text" style={{ maxWidth: 500, fontWeight: 'bold', fontSize: '1.3rem', borderRadius: 0}}>{t("Common:Button.back")}</Button>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    breadcrumbArr: state.breadcrumb.breadcrumbArr
});
const mapDispatchToProps = dispatch => ({
    setBreadcrumbP: data => dispatch(setBreadcrumb(data)),
    getUserInfoP: data => dispatch(getUserInfo(data)),
});
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(ManageFunds1)));
