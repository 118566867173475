// Essential for all components
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

// Styling
import Grid from '@material-ui/core/Grid';

import successIcon from '../../images/success.png';

class ResetPasswordSuccess extends Component {

    render() {
        const { t, i18n } = this.props;

        return (
            <div className="forgot-page">
                <div className="main__container flex-center-item">
                    <Grid item xm={12} md={6} xl={4} className="grid">
                        <div className="form-wrapper forgot-form">
                            <Grid item xs={12} className="grid color-white title">
                                <h1>{t('Common:General.ResetPassword')}</h1>
                                <h3 className="pt20">{t("LoginRegister:resetPassword.successDescription1")}</h3>
                                <h3>{t("LoginRegister:resetPassword.successDescription2")}</h3>
                            </Grid>
                            <Grid item xs={12} className="grid pt20 flex-center-item">
                                <img className="success-icon" src={successIcon} alt="Success" />
                            </Grid>
                            <Grid item xs={12} className="grid flex-center-item">
                                <Link to={"/" + i18n.language + '/login'}>{t("LoginRegister:forgetPassword.backTo")}</Link>
                            </Grid>
                        </div>
                    </Grid>
                </div>
            </div>
        );
    }
}

export default withTranslation()(withRouter(ResetPasswordSuccess));
