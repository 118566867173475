// Essential for all components
import React from 'react';

// Material UI
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';

export default class EnhancedTableHead extends React.Component {
    createSortHandler = property => event => {
        this.props.onRequestSort(event, property);
    };

    getMaxItemCapacity = ( totalNumberCount, itemsPerPage, currentPage ) => {
        if ( ( (currentPage+1) * itemsPerPage) >totalNumberCount ){
            return (totalNumberCount%itemsPerPage);
        }
        return itemsPerPage;
    }

    render() {
        const {
            order, orderBy,
            rows,

            //define is it show button SelectAll
            enable_selectAll, onSelectAllClick,
            numSelected, rowCount,
        } = this.props;
        const isShow_selectAll = enable_selectAll || false;
        return (
            <TableHead>
                <TableRow>
                    {isShow_selectAll &&
                        <TableCell padding="checkbox">
                            <Checkbox
                                checked={rowCount > 0 && numSelected === rowCount}
                                onChange={onSelectAllClick}
                            />
                        </TableCell>
                    }

                    {rows.map(row => {

                        if (row.disableSorting) {
                            return (
                                <TableCell
                                    key={row.id}
                                    // numeric={row.numeric}
                                    padding={row.disablePadding ? 'none' : 'default'}
                                    sortDirection={orderBy === row.id ? order : false}
                                > {row.label}
                                </TableCell>
                            );
                        } else {
                            return (
                                <TableCell
                                    key={row.id}
                                    // numeric={row.numeric}
                                    padding={row.disablePadding ? 'none' : 'default'}
                                    sortDirection={orderBy === row.id ? order : false}
                                >
                                    <Tooltip
                                        title="Sort"
                                        placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                                        enterDelay={300}
                                    >
                                        <TableSortLabel
                                            classes={{
                                                active: 'sort-active'
                                            }}
                                            active={orderBy === row.id}
                                            direction={order}
                                            onClick={this.createSortHandler(row.id)}
                                            style={ row.width ? {width: row.width} : {}}
                                        >
                                            {row.label}
                                        </TableSortLabel>
                                    </Tooltip>
                                </TableCell>
                            );
                        }
                    }, this)}
                </TableRow>
            </TableHead>
        );
    }
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};
