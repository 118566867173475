// Essential for all components
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import { Flex } from 'reflexbox';

import backIcon from "../../images/back.png";

const imagePaths = {
    'en-US': {
        place1st: require('../../images/Landingpg/Registration-period/1stplace.png'),
        place2nd: require('../../images/Landingpg/Registration-period/2ndplace.png'),
        place3rd: require('../../images/Landingpg/Registration-period/3rdplace.png'),
        place4th: require('../../images/Landingpg/Registration-period/4thplace.png'),
        place5th: require('../../images/Landingpg/Registration-period/5thplace.png'),
        content1st: require('../../images/Landingpg/Registration-period/1st prize.png'),
        content2nd_5th: require('../../images/Landingpg/Registration-period/2nd-5th prize.png'),
        luckydraw: require('../../images/Landingpg/Registration-period/LuckydrawENG.png'),
        result_1st: require('../../images/Landingpg/Registration-period/1st eng.png'),
        result_2nd: require('../../images/Landingpg/Registration-period/2nd eng.png'),
        result_3rd: require('../../images/Landingpg/Registration-period/3rd eng_3.png'),
        result_4th: require('../../images/Landingpg/Registration-period/4th eng_2.png'),
        result_5th: require('../../images/Landingpg/Registration-period/5th eng_2.png'),
        result_6th_200th: require('../../images/Landingpg/Registration-period/6th-200th eng .png'),
    },

    'zh-HK': {
        place1st: require('../../images/Landingpg/Registration-period/1stplace.png'),
        place2nd: require('../../images/Landingpg/Registration-period/2ndplace.png'),
        place3rd: require('../../images/Landingpg/Registration-period/3rdplace.png'),
        place4th: require('../../images/Landingpg/Registration-period/4thplace.png'),
        place5th: require('../../images/Landingpg/Registration-period/5thplace.png'),
        content1st: require('../../images/Landingpg/Registration-period/1st prize.png'),
        content_2nd_5th: require('../../images/Landingpg/Registration-period/2nd-5th prize.png'),
        luckydraw: require('../../images/Landingpg/Registration-period/Luckydraw.png'),
        result_1st: require('../../images/Landingpg/Registration-period/1st.png'),
        result_2nd: require('../../images/Landingpg/Registration-period/2nd CHI.png'),
        result_3rd: require('../../images/Landingpg/Registration-period/3rd_1.png'),
        result_4th: require('../../images/Landingpg/Registration-period/4th_1.png'),
        result_5th: require('../../images/Landingpg/Registration-period/5th_1.png'),
        result_6th_200th: require('../../images/Landingpg/Registration-period/6th200th.png'),
    }
};

class Prize extends Component {

    back = () => {
        const { history } = this.props;
        history.goBack();
    }

    render() {
        const { t, i18n } = this.props;
        return (                    
            <div>
                <Flex className="main__container head-container bg-ranking-badge">
                    <Flex flex={1} flexDirection="column">
                        <Flex flex={1} justifyContent="start" alignItems="center" className="header-title">
                            <img src={backIcon} className="back-icon" alt="back-icon" onClick={this.back}/>
                            <Flex ml="1rem">
                                {t("Prize:prize")}
                            </Flex>
                        </Flex>
                        <Flex className='landing-content' flexDirection="column" style={{ backgroundImage: 'none' }}>
                            <div className='rank'>
                                <div className='rank-1-2-3'>
                                    <div className='wraperRank-2nd-3rd'>
                                        <div className='rank-2nd-3rd'>
                                            <img src={imagePaths[i18n.language].place2nd} alt='place2nd' />
                                            <div>
                                                <div className={`border-2rd-5th ${i18n.language === 'en-US' ? 'border-2rd-5th-eng' : ''}`}>
                                                    <p>
                                                        {t("Landingpg:hk10-up")}
                                                    </p>
                                                    <div>
                                                        {t("Landingpg:hk10-down")}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='rank-1st'>
                                        <img src={imagePaths[i18n.language].place1st} alt='place1st' />
                                        <div>
                                            <div className={`border-1st ${i18n.language === 'en-US' ? 'border-1st-eng' : ''}`}>
                                                <p style={{lineHeight: 1}}>
                                                    {t("Landingpg:cashRewardEquivalent")}
                                                </p>
                                                <div>
                                                    {t("Landingpg:ofHisHerVirtual")}
                                                </div>
                                                <div style={{fontSize: '1.3vw', fontWeight: '100'}}>
                                                    {t("Landingpg:capped")}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='wraperRank-2nd-3rd'>
                                        <div className='rank-2nd-3rd'>
                                            <img src={imagePaths[i18n.language].place3rd} alt='place3rd' />
                                            <div>
                                                <div className={`border-2rd-5th ${i18n.language === 'en-US' ? 'border-2rd-5th-eng' : ''}`}>
                                                    <p>
                                                        {t("Landingpg:hk8-up")}
                                                    </p>
                                                    <div>
                                                        {t("Landingpg:hk8-down")}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='rank-1-2-3-Mobi'>
                                    <div className='rank-1st'>
                                        <img src={imagePaths[i18n.language].place1st} alt='place1st' />
                                        <div className={`border-1st ${i18n.language === 'en-US' ? 'border-1st-eng' : ''}`}>
                                            <p>
                                                {t("Landingpg:cashRewardEquivalent")}
                                            </p>
                                            <div>
                                                {t("Landingpg:ofHisHerVirtual")}
                                            </div>
                                        </div>
                                    </div>

                                    <div className='rank-2-3-Mobi'>
                                        <div className='wraperRank-2nd-3rd'>
                                            <div className='rank-2nd-3rd'>
                                                <img src={imagePaths[i18n.language].place2nd} alt='place2nd' />
                                                <div>
                                                    <div className={`border-2rd-5th ${i18n.language === 'en-US' ? 'border-2rd-5th-eng' : ''}`}>
                                                        <p>
                                                            {t("Landingpg:hk10-up")}
                                                        </p>
                                                        <div>
                                                            {t("Landingpg:hk10-down")}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='wraperRank-2nd-3rd'>
                                            <div className='rank-2nd-3rd'>
                                                <img src={imagePaths[i18n.language].place3rd} alt='place3rd' />
                                                <div>
                                                    <div className={`border-2rd-5th ${i18n.language === 'en-US' ? 'border-2rd-5th-eng' : ''}`}>
                                                        <p>
                                                            {t("Landingpg:hk8-up")}
                                                        </p>
                                                        <div>
                                                            {t("Landingpg:hk8-down")}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='rank-4-5' style={{ marginTop: i18n.language === 'en-US' && '1rem' }}>
                                    <div className='rank-4th-5th'>
                                        <img src={imagePaths[i18n.language].place4th} alt='place4th' />
                                        <div>
                                            <div className={`border-2rd-5th ${i18n.language === 'en-US' ? 'border-2rd-5th-eng' : ''}`}>
                                                <p>
                                                    {t("Landingpg:hk6-up")}
                                                </p>
                                                <div>
                                                    {t("Landingpg:hk6-down")}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='rank-4th-5th'>
                                        <img src={imagePaths[i18n.language].place5th} alt='place5th' />
                                        <div>
                                            <div className={`border-2rd-5th ${i18n.language === 'en-US' ? 'border-2rd-5th-eng' : ''}`}>
                                                <p>
                                                    {t("Landingpg:hk5-up")}
                                                </p>
                                                <div>
                                                    {t("Landingpg:hk5-down")}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={`star ${i18n.language === 'en-US' ? 'star-eng' : ''}`}>
                                <img src={imagePaths[i18n.language].luckydraw} alt='luckydraw' />
                            </div>

                            <div className='result'>
                                <div className='result-1-2-3'>
                                    <img src={imagePaths[i18n.language].result_1st} alt='result_1st' />
                                    <img src={imagePaths[i18n.language].result_2nd} alt='result_2nd' />
                                    <img src={imagePaths[i18n.language].result_3rd} alt='result_3rd' />
                                </div>

                                <div className='result-4-5'>
                                    <img src={imagePaths[i18n.language].result_4th} alt='result_4th' />
                                    <img src={imagePaths[i18n.language].result_5th} alt='result_5th' />
                                </div>

                                <div style={{ flexDirection: 'column', alignItems: 'center' }} className={`result-6 ${i18n.language === 'en-US' ? 'result-6-eng' : ''}`} id="tAndC">
                                    <img src={imagePaths[i18n.language].result_6th_200th} alt='result_6th_200th' />
                                    <Flex className='total-prizes'>
                                        {t("Landingpg:total200")}
                                    </Flex>
                                    <div style={{padding: `${i18n.language === 'en-US' ? `10px` : `5px`} 10px`}} className="draw-result-container">
                                        <div className="draw-result" style={{ fontWeight: 'bolder' }}>
                                            {t("Landingpg:drawResult")}
                                        </div>
                                        <div className="draw-result-note">
                                            {t("Landingpg:drawResult_note")}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Flex>
                    </Flex>
                </Flex>
            </div>
        );
    }
}

export default withTranslation()(withRouter(Prize));