// auth
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const GET_USER_INFO = 'GET_USER_INFO';
export const REFRESH_TOKEN_BY_REFRESH_TOKEN = 'REFRESH_TOKEN_BY_REFRESH_TOKEN';
export const LOGOUT = 'LOGOUT';
export const FORGET_PASSWORD = 'FORGET_PASSWORD';
export const FORGET_PASSWORD_CLEAR = 'FORGET_PASSWORD_CLEAR';

// breadcrumb
export const SET_BREADCRUMB = 'SET_BREADCRUMB';
export const GET_BREADCRUMB = 'GET_BREADCRUMB';
export const ADD_BREADCRUMB = 'ADD_BREADCRUMB';

// message
export const SET_MESSAGE = 'SET_MESSAGE';
export const GET_MESSAGE = 'GET_MESSAGE';
export const ADD_MESSAGE = 'ADD_MESSAGE';

// Current Paginator
export const SET_PAGINATOR = 'SET_PAGINATOR';
export const GET_PAGINATOR = 'GET_PAGINATOR';

// categories
export const GET_ALL_CATEGORIES = 'GET_ALL_CATEGORIES';