// Essential for all components
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import { Flex } from 'reflexbox'
import {Button, makeStyles} from "@material-ui/core";
import moment from 'moment';

import {setBreadcrumb} from "../../Redux/Action/breadcrumbAction";
import {getUserInfo} from "../../Redux/Action/authAction";
import backIcon from "../../images/back.png";
import { WEBSITE_URL } from '../../config';

const cnTitle = <span>您已<b>成功遞交</b>有關指示。</span>;
const engTitle = <span>Your instruction has been <i>successfully submitted</i></span>;

const cnExistingChoiceDescription = <span>此項已遞交的指示只適用於您現時持有的基金。如要更改您的未來款項投資分配，請按「<b>管理基金 / 投資選項</b>」並遞交「<b>更改未來投資</b>」指示。</span>
const engExistingChoiceDescription = <span>This submitted instruction applies only to your existing fund holding. To change the investment allocations for your future monies, please click "<b>Manage funds</b>" and submit "<b>Change future investments</b>" instruction.</span>

const cnFutureChoiceDescription = <span>此項已遞交的指示只適用於您的未來款項投資分配。如要轉換現時持有的基金，請按「<b>管理基金 / 投資選項</b>」並遞交「<b>轉換基金</b>」指示。</span>
const engFutureChoiceDescription = <span>This submitted instruction applies only to your future investment. To switch your existing fund holdings, please click "<b>Manage funds</b>" and submit "<b>Switch funds</b>" instruction.</span>

const SwitchSection = (props) => {
    const { t, percentage, title, subtitle, fundSwitch, index, instructionType } = props;
    return (
        <Flex flexDirection="column" flex={1}>
            {index === 0 && <Flex flexDirection="column">
                {instructionType === 'existing' ? <Flex className="black-text" mt="2rem">
                    {fundSwitch === 'out' ? `${t("ManageFunds:switchOut")}` : `${t("ManageFunds:switchIn")}`}
                </Flex> :
                <Flex className="black-text" mt="2rem">
                    {t("ManageFunds:newInvestmentInstructionDetails")}
                </Flex>}
                <Flex flex={1} className="section-header small-font-size black-text" mt="1rem" fontSize={instructionType === 'existing' ? null : '0.85rem'}>
                    <Flex flex={1} className="stripe">
                        <Flex flex={instructionType === 'existing' ? 2.5 : 2 } marginY="1rem">
                            {t("ManageFunds:fundName")}
                        </Flex>
                        <Flex flex={instructionType === 'existing' ? 1 : 1.3} justifyContent="flex-start" marginY="1rem" ml={instructionType === 'existing' ? null : 10}>
                            {fundSwitch === 'out' ?  t("ManageFunds:contribution") : instructionType === 'existing' ? t("ManageFunds:voluntary") : t("ManageFunds:mandatory")} %
                        </Flex>
                        {instructionType !== 'existing' && <Flex flex={1} justifyContent="flex-start" marginY="1rem">
                            {t("ManageFunds:voluntary")} %
                        </Flex>}
                    </Flex>
                </Flex>
            </Flex>}
            <Flex mt={index === 0 ? "2rem" : "1rem"} mb="1rem" flexDirection="row" justifyContent="space-between" alignItems="center" className="light-black small-font-size">
                <Flex flex={instructionType === 'existing' ? 2.5 : 2} flexDirection="column" paddingRight="1rem">
                    <Flex>
                        {subtitle}
                    </Flex>
                    <Flex>
                        {t(`Fund:${title}`)}
                    </Flex>
                </Flex>
                <Flex flex={instructionType === 'existing' ? 1 : 1.3} justifyContent="flex-start" alignItems="center">
                    <Flex className="black-text medium-font-size">{instructionType === 'existing' ? percentage : 0}</Flex>
                    <Flex>%</Flex>
                </Flex>
                {instructionType !== 'existing' && <Flex flex={1} justifyContent="flex-start" alignItems="center">
                    <Flex className="black-text medium-font-size">{percentage}</Flex>
                    <Flex>%</Flex>
                </Flex>}
            </Flex>
            <LineBreak fullWidth />
        </Flex>
    )
}

const SwitchFundList = (props) => {
    const { t, switchInFunds, switchOutFund, instructionType } = props;
    if (switchOutFund) {
        return <SwitchSection index={0} t={t} fundSwitch="out" percentage={100} title={switchOutFund.title} subtitle={switchOutFund.subtitle} instructionType={instructionType} />
    }
    return switchInFunds.map((fund, index) => {
        return <SwitchSection key={index} index={index} t={t} fundSwitch="in" percentage={fund.switchInPercentage} title={fund.title} subtitle={fund.subtitle} instructionType={instructionType} />
    })
}

const LineBreak = (props) => {
    const { text, fullWidth } = props;
    return ( text ?
        <div className="strike" style={{ marginTop: '1rem', marginBottom: '1rem' }}>
            <span>{text}</span>
        </div> :
        <hr style={{ marginTop: '1.5rem', marginBottom: '1.5rem' }} className={fullWidth ? 'full-hr' : ''} />
    )
}

const withStyles = (Component) => {
    return function WrappedComponent(props) {
        const useStyles = makeStyles({});
        const classes = useStyles();
        return <Component classes={classes} {...props} />
    }
}

class ManageFunds4 extends Component {
    
    state = {
        instructionType: this.props.history.location.state.instructionType,
        switchInFunds: this.props.history.location.state.switchInFunds,
        switchOutFund: this.props.history.location.state.switchOutFund,
        submissionDateTime: this.props.history.location.state.submissionDateTime,
        refNo: this.props.history.location.state.refNo,
        allFunds: this.props.history.location.state.allFunds,
    };

    navigateToFirstPage = () => {
        const { i18n } = this.props;
        window.location.replace('/' + i18n.language + `/manage-funds/0`)
        window.removeEventListener('popstate', this.navigateToFirstPage, true);
    }

    componentDidMount() {
        window.addEventListener('popstate', this.navigateToFirstPage, true);
    }

    // BUTTON FUNCTION

    back = () => {
        const { history } = this.props;
        history.goBack();
    }

    handleClick = (page) => {
        const { i18n } = this.props;
        const { instructionType } = this.state;
        this.props.history.push('/' + i18n.language + `/manage-funds/${page}`, {instructionType: instructionType === 'existing' ? 'existing' : null});
    }

    render() {
        const { t, i18n, history } = this.props;
        const { switchInFunds, switchOutFund, instructionType, submissionDateTime, refNo, allFunds } = this.state;
        const isChinese = i18n.language === 'zh-HK'
        const existingChoiceDescription = isChinese ? cnExistingChoiceDescription : engExistingChoiceDescription;
        const futureChoiceDescription = isChinese ? cnFutureChoiceDescription : engFutureChoiceDescription;
        const locale = isChinese ? 'zh-hk' : 'en';
        const dateFormat = isChinese ? 'YYYY年M月D日 HH:mm:ss' : 'MMM D, YYYY HH:mm:ss';
        return (                    
            <div>
                <Flex className="main__container fund-manager" mb="4rem">
                    <Flex flex={1} flexDirection="column">
                        <Flex flex={1} justifyContent="start" alignItems="center" className="header-title" style={{ paddingBottom: '0'}}>
                            <img src={backIcon} className="back-icon" alt="back-icon" onClick={this.back}/>
                            <Flex ml="1rem">
                                {t("ManageFunds:manageFunds")}
                            </Flex>
                        </Flex>
                        <Flex flex={1} flexDirection="column" justifyContent="start" className="container" marginTop="2rem">
                            <Flex flex={1} justifyContent="start" className="section-title">
                                <p className="light-black medium-font-size">
                                    {i18n.language === 'zh-HK' ? cnTitle : engTitle}
                                </p>
                            </Flex>

                            <Flex alignItems="center" className="small-font-size">
                                <Flex className="light-black" mr="0.5rem">
                                    {t("ManageFunds:referenceNumber")}:
                                </Flex>
                                <Flex className="black-text">
                                    {refNo}
                                </Flex>
                            </Flex>

                            <Flex className="black-text" mt="2.5rem">
                                {t("ManageFunds:submissionDetails")}
                            </Flex>

                            <LineBreak fullWidth />
                            
                            <Flex justifyContent="space-between" className="small-font-size">
                                <Flex flex={1} justifyContent="flex-start">
                                    {t("ManageFunds:submissionDateTime")}
                                </Flex>
                                <Flex flex={1} justifyContent="flex-start" className="black-text">
                                    {moment(submissionDateTime).locale(locale).format(dateFormat)}
                                </Flex>
                            </Flex>

                            <LineBreak fullWidth />

                            {instructionType === 'existing' && <SwitchFundList t={t} switchOutFund={switchOutFund || {}} instructionType={instructionType} />}

                            <SwitchFundList t={t} switchInFunds={switchInFunds || {}} instructionType={instructionType} />

                            <Flex flex={1} className="light-black">
                                <p style={{ fontSize: '1rem' }}>
                                    {t('ManageFunds:pleaseNoteThatThisPageIsNotAnOfficialConfirmationOfTheTransaction')}{t('ManageFunds:viewConfirmation')}
                                    <span onClick={()=>{history.push('/' + i18n.language + `/trade-history/1`)}} style={{ cursor: 'pointer' }} className='hyperlink bold link'>{t('ManageFunds:transactionLog')}</span>
                                    {isChinese ? '。' : '.'}
                                </p>
                            </Flex>

                            <Flex flex={1} justifyContent="start" className="section-title light-black medium-font-size" mt="2rem">
                                {instructionType === 'existing' ? `${t("ManageFunds:doYouWantToChangeInvestmentAllocationsForYourFutureMonies")}` : `${t("ManageFunds:doYouWantToSwitchYourExistingFundHoldings")}`}
                            </Flex>

                            <Flex flex={1} justifyContent="start" className="light-black small-font-size" mt="1.5rem">
                                <p> 
                                    {instructionType === 'existing' ? existingChoiceDescription : futureChoiceDescription}
                                </p>
                            </Flex>

                            <Flex flex={1} justifyContent="center">
                                <Flex flexDirection="column" alignItems="center" className="button-wrapper" mt="1rem" maxWidth={500}>
                                    <Button className="primary-button m0" onClick={()=>{this.handleClick(0)}}>{t("ManageFunds:manageFundsInvestmentChoices")}</Button>
                                    {instructionType === 'existing' && !allFunds && <Button className="transparent-button m0 mt20" onClick={()=>{this.handleClick(1)}}>{t("ManageFunds:switchOutAnotherFund")}</Button>}
                                </Flex>
                            </Flex>
                            <Flex className="black-text small-font-size" mt="3rem" flexDirection="column">
                                {t("ManageFunds:notes")}:
                                <Flex flexDirection="column" mt="1rem" className="light-black">
                                    <Flex flexDirection="row">
                                        <Flex flex={1/15} maxWidth={30}>1.</Flex>
                                        <Flex flex={1}>
                                            <p style={{ margin: 0 }}>
                                                {t('ManageFunds:pleaseNoteThatThisPageIsNotAnOfficialConfirmationOfTheTransaction')}{t('ManageFunds:viewConfirmation')}
                                                <span onClick={()=>{history.push('/' + i18n.language + `/trade-history/1`)}} style={{ cursor: 'pointer' }} className='hyperlink bold link'>{t('ManageFunds:transactionLog')}</span>
                                                {isChinese ? '。' : '.'}
                                            </p>
                                        </Flex>
                                    </Flex>
                                    <Flex flexDirection="row">
                                        <Flex flex={1/15} maxWidth={30}>2.</Flex>
                                        <Flex flex={1}>
                                            <span>
                                                {t('ManageFunds:weWillSendAnAlertToYourRegisteredEmailAddressWhenTheConfirmationIsAvailableOnOurCustomerWebsite')}
                                            </span>
                                        </Flex>
                                    </Flex>
                                    <Flex flexDirection="row">
                                        <Flex flex={1/15} maxWidth={30}>3.</Flex>
                                        <Flex flex={1}>
                                            <span>
                                                {t('ManageFunds:weWillNotMailYouAPaperCopyOfTheConfirmation')}
                                            </span>
                                        </Flex>
                                    </Flex>
                                </Flex>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    breadcrumbArr: state.breadcrumb.breadcrumbArr
});
const mapDispatchToProps = dispatch => ({
    setBreadcrumbP: data => dispatch(setBreadcrumb(data)),
    getUserInfoP: data => dispatch(getUserInfo(data)),
});
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(ManageFunds4))));