// Essential for all components
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import { Flex } from 'reflexbox'
import moment from 'moment';

import {setBreadcrumb} from "../../Redux/Action/breadcrumbAction";
import {getUserInfo} from "../../Redux/Action/authAction";
import questionIcon from "../../images/question-mark.png";
import tickIcon from "../../images/tick.png";
import crossIcon from "../../images/cross.png";
import backIcon from "../../images/back.png"
import { apiMiniGames } from '../../Api/_ApiMiniGames';
import { apiBadges } from '../../Api/_ApiBadges';
import MiddleDialog from '../../components/MiddleDialog/MiddleDialog';

const QuizList = props => {
    const { onClick, selectedOptions, quizSelections, completed, t } = props;
    const { optionA, optionB, optionC } = selectedOptions;
    
    return quizSelections.map((quiz, index) => {
        let optionSelected;
        if (quiz.option === 'A') {
            optionSelected = optionA;
        } else if (quiz.option === 'B') {
            optionSelected = optionB;
        } else if (quiz.option === 'C') {
            optionSelected = optionC;
        }

        return (
            <Flex key={index} className="quiz-selection" onClick={() => onClick(quiz.option, quiz.answer)} style={{ borderColor: (optionSelected || completed) ? (quiz.answer ? '#009332' : '#b22c01') : 'inherit', pointerEvents: (optionSelected || completed) ? 'none' : 'auto' }}>
                {(optionSelected || completed) && <Flex mr="1rem">
                    <img src={quiz.answer ? tickIcon : crossIcon} width={20} height={20} alt="answer-icon" />
                </Flex>}
                <Flex minWidth={20}>
                    {quiz.option}.&nbsp;
                </Flex>
                <Flex>
                    {t(`MiniGame:${quiz.content}`)}
                </Flex>
            </Flex>
        )
    });
}

const NoticeDialog = (props) => {
    const { t, open, handleCloseDialog, correctAnswer } = props;
    return <MiddleDialog
        open={open}
        title={t("MiniGame:pleaseTryAgainToPickTheCorrectAnswer")}
        // title={correctAnswer ? t("MiniGame:congratulationsYouGotItRight") : t("MiniGame:pleaseTryAgainToPickTheCorrectAnswer")}
        buttonText={t("Common:Button.close")}
        handleCloseDialog={handleCloseDialog}
        handleClickDialog={handleCloseDialog}
    />
}

class MiniGame extends Component {
    
    state = {
        selectedOptions: {
            optionA: false,
            optionB: false,
            optionC: false,
        },
        openDialog: false,
        quizzes: this.props.history.location.state.quizzes,
        titleEng: this.props.history.location.state.titleEng,
        titleCn: this.props.history.location.state.titleCn,
        gameNumber: this.props.history.location.state.gameNumber,
        miniGameId: this.props.history.location.state.miniGameId,
        completed: this.props.history.location.state.completed,
        videoUrlEng: this.props.history.location.state.videoUrlEng,
        videoUrlCn: this.props.history.location.state.videoUrlCn,
        quizNumber: 1,
        badge: '',
        openNoticeDialog: false,
        correctAnswer: false,
        completedMiniGameCount: 0
    };

    // BUTTON FUNCTION

    handleClickDialog = () => {
        const { history, i18n } = this.props;
        this.setState({ openDialog: false });
        history.push('/' + i18n.language + '/badge');
    };

    handleCloseNoticeDialog = () => {
        const { correctAnswer, quizNumber } = this.state;
        if (correctAnswer) {
            // this.resetQuiz(quizNumber);
        }
        this.setState({ openNoticeDialog: false });
    };

    back = () => {
        const { history } = this.props;
        history.goBack();
    }

    resetQuiz = (quizNumber) => {
        this.setState({
            correctAnswer: false,
            quizNumber: quizNumber + 1,
            selectedOptions: {
                optionA: false,
                optionB: false,
                optionC: false,
            },
        });
        window.scrollTo(0, 0);
    }

    handleClick = (option, answer) => {
        const { selectedOptions, quizNumber, miniGameId } = this.state;
        if (option === 'A') {
            selectedOptions.optionA = true;
            this.setState({ selectedOptions });
        } else if (option === 'B') {
            selectedOptions.optionB = true;
            this.setState({ selectedOptions });
        } else if (option === 'C') {
            selectedOptions.optionC = true;
            this.setState({ selectedOptions });
        }
        if (answer) {
            // if (quizNumber !== 3) {
            //     this.setState({ openNoticeDialog: true, correctAnswer: true });
            // } else {
            const body = {
                user: this.props.auth.userInfo.username,
                mini_game: miniGameId,
                won_time: moment().valueOf()
            }
            apiMiniGames.completeMiniGame(body).then(() => {
                this.setState({ openDialog: true });
            })
            // }
        } else {
            this.setState({ openNoticeDialog: true });
        }
    }

    checkIsGameCompleted = () => {
        const { miniGameId } = this.state;
        const { history, i18n } = this.props;
        const param = {
            user: this.props.auth.userInfo.username,
            mini_game: miniGameId
        }
        apiMiniGames.getUserMiniGames(param).then(obj => {
            if (obj.data.length > 0) {
                history.push('/' + i18n.language + '/mini-game');
            }
        })
    }

    checkUserMiniGame = () => {
        const params = {
            user: this.props.auth.userInfo.username
        }
        apiMiniGames.getUserMiniGames(params).then(obj => {
            if (obj && obj.status === 200) {
                this.setState({ completedMiniGameCount: obj.data.length });
            }
        })
    }

    componentDidMount() {
        this.checkIsGameCompleted();
        this.checkUserMiniGame();
        const param = {
            sequence: this.state.gameNumber
        }
        apiBadges.getAllBadges(param).then(obj => {
            this.setState({ badge: obj.data[0] });
        })
    }

    render() {
        const { t, i18n } = this.props;
        const { selectedOptions, openDialog, quizzes, titleCn, titleEng, gameNumber, quizNumber, completed, badge, openNoticeDialog, correctAnswer, videoUrlEng, videoUrlCn, completedMiniGameCount } = this.state;
        const { question, quizSelections } = quizzes[quizNumber - 1];
        const title = i18n.language === 'en-US' ? titleEng : titleCn;
        const videoUrl = i18n.language === 'en-US' ? videoUrlEng : videoUrlCn;
        return (                    
            <div>
                <Flex className="main__container head-container">
                    <Flex flex={1} flexDirection="column">
                        <Flex flex={1} justifyContent="start" alignItems="center" className="header-title">
                            <img src={backIcon} className="back-icon" alt="back-icon" onClick={this.back}/>
                            <Flex ml="1rem">
                                {/*{`${t("MiniGame:miniGame")} ${gameNumber} ${t("MiniGame:quiz")} ${quizNumber}`}*/}
                                {`${t("MiniGame:miniGame")} ${gameNumber} `}
                            </Flex>
                        </Flex>

                        <Flex flex={1} className="game-container" flexDirection="column">
                            <Flex alignItems="center">
                                <Flex alignItems="center" style={{fontSize: "1.5rem", paddingLeft: "1rem", fontWeight: "bold"}}>
                                    {title}
                                </Flex>
                                {/* <Flex ml="0.5rem">
                                    <img src={questionIcon} width={40} height={40} alt="question-mark" />
                                </Flex> */}
                            </Flex>
                            <Flex flex={1} mt="2rem" justifyContent="center" className="video-container">
                                <Flex flex={1} className="video">
                                    <iframe width="100%" height="100%" src={videoUrl} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                </Flex>
                            </Flex>
                            <Flex mt="2rem" flexDirection="column">
                                <Flex color="blue" mb="1rem">
                                    {t("MiniGame:pleaseSelectTheCorrectAnswer")}
                                </Flex>
                                <Flex lineSpacing="3pt" fontWeight="bold">
                                    {t(`MiniGame:${question}`)}
                                </Flex>

                            </Flex>
                            <Flex mt="2rem" flexDirection="column">
                                <QuizList onClick={this.handleClick} quizSelections={quizSelections} completed={completed} selectedOptions={selectedOptions} t={t} />
                            </Flex>
                        </Flex>
                    </Flex>
                    <MiddleDialog
                        open={openDialog}
                        badge={badge}
                        title={`${t("MiniGame:congratulations")}!`}
                        description={
                            <Flex flexDirection="column">
                                <Flex>{`${t("MiniGame:completeMissionMessage1")} ${gameNumber} ${t("MiniGame:completeMissionMessage2")}`}</Flex>
                                {completedMiniGameCount === 5 && <Flex>{t("MiniGame:completeMissionMessage3")}</Flex>}
                            </Flex>
                        }
                        // description={`${t("MiniGame:youHaveCompletedThe")} ${badgeNumber}${badgeNumber === 1 ? 'st' : (badgeNumber === 2 ? 'nd' : (badgeNumber === 3 ? 'rd' : 'th'))} ${t("MiniGame:challengeAndWonTheBadge")} #${badgeNumber} "${badge.description}".`}
                        handleClickDialog={this.handleClickDialog}
                    />
                    <NoticeDialog
                        t={t}
                        open={openNoticeDialog}
                        handleCloseDialog={this.handleCloseNoticeDialog}
                        correctAnswer={correctAnswer}
                    />
                </Flex>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    breadcrumbArr: state.breadcrumb.breadcrumbArr
});
const mapDispatchToProps = dispatch => ({
    setBreadcrumbP: data => dispatch(setBreadcrumb(data)),
    getUserInfoP: data => dispatch(getUserInfo(data)),
});
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(MiniGame)));