// Essential for all components
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import './css/App.scss';
import { withTranslation } from 'react-i18next';
// Redux
import { connect } from 'react-redux';
import { login, logout } from './Redux/Action/authAction';
import { setBreadcrumb } from "./Redux/Action/breadcrumbAction";
//Material
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import { withStyles } from '@material-ui/core/styles';

import querySearch from "stringquery";
import { setAccessToken, setForceLogout, setforceLogoutMsg } from "./Api/_ApiFactoryWithHeader";
import { CacheService } from "./utils/CacheService";
import { apiLanguages } from "./Api/_ApiLanguages";

// Component
import Sidebar from "./components/100Include/Sidebar";
import PublicHeader from "./components/100Include/PublicHeader";
import PrivateHeader from "./components/100Include/PrivateHeader";
// Home
import ForgetPassword from './containers/00Home/ForgetPassword';
import ResetPassword from './containers/00Home/ResetPassword';
import Login from "./containers/00Home/Login";
import LoginRegister from "./containers/00Home/LoginRegister";
import ResetPasswordSuccess from "./containers/00Home/ResetPasswordSuccess";
import Landingpg from "./containers/00Home/Landingpg";
// 404
import PageNotFound from './containers/PageNotFound';
import ComingSoon from './containers/ComingSoon';
// User Manager
import Profile from "./containers/02Profile/Profile";
import { MySnackbarContentWrapper } from "./components/102MaterialDesign/MySnackbarContentWrapper";
import Snackbar from "@material-ui/core/Snackbar";
import AuctionManagement from "./containers/01AuctionManagement/AuctionManagement";
import AuctionUpdate from "./containers/01AuctionManagement/AuctionUpdate";
import ClientManagement from "./containers/02ClientManagement/ClientManagement";
import ClientDetails from "./containers/02ClientManagement/ClientDetails";
import ClientUpdate from "./containers/02ClientManagement/ClientUpdate";
import AddCredit from "./containers/02ClientManagement/AddCredit";
import OneToOneMsg from "./containers/02ClientManagement/OneToOneMsg";
import SendPushMsg from "./containers/02ClientManagement/SendPushMsg";
import SendWeChat from "./containers/02ClientManagement/SendWeChat";
import PushMsgSchedule from "./containers/03PushMsgSchedule/PushMsgSchedule";
import SendPushMsgUpdate from "./containers/03PushMsgSchedule/SendPushMsgUpdate";
import ConfigurationView from "./containers/05Configuration/ConfigurationView";
import ConfigurationDetail from "./containers/05Configuration/ConfigurationDetail";
import ConfigurationList from "./containers/05Configuration/ConfigurationList";
import RecyclableManagement from "./containers/05Configuration/RecyclableManagement";
import TenantUpdate from "./containers/06TenantManagement/TenantUpdate";
import TenantDetails from "./containers/06TenantManagement/TenantDetails";
import TenantManagement from "./containers/06TenantManagement/TenantManagement";
import TenantMemberUpdate from "./containers/06TenantManagement/TenantMemberUpdate";
import TenantMemberDetails from "./containers/06TenantManagement/TenantMemberDetails";
import TenantMemberManagement from "./containers/06TenantManagement/TenantMemberManagement";
import StoreView from "./containers/05Configuration/StoreView";
import StoreDetail from "./containers/05Configuration/StoreDetail";
import StoreManagement from "./containers/05Configuration/StoreManagement";
import ConfigurationPlatformList from "./containers/05Configuration/ConfigurationPlatformList";
import ReportTypeManagement from "./containers/04Report/ReportTypeManagement";
import ReportDateManagement from "./containers/04Report/ReportDateManagement";

import Report1Management from "./containers/04Report/Report1Management";
import Report3Management from "./containers/04Report/Report3Management";
import ReportPlatformList from './containers/04Report/ReportPlatformList';
import { setMessages } from "./Redux/Action/messageAction";
import AuctionDetails from "./containers/01AuctionManagement/AuctionDetails";
import AuctionFileUpdate from './containers/01AuctionManagement/AuctionFileUpdate';

import { ModuleRegistry } from '@ag-grid-community/core';
import { InfiniteRowModelModule } from '@ag-grid-community/infinite-row-model';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';

// external css files that should be bundled
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';
// import '@ag-grid-community/styles/ag-grid.css';
// import '@ag-grid-community/styles/ag-theme-alpine.css';
import UserManagement from './containers/01UserManagement/UserManagement';
import UserDetails from './containers/01UserManagement/UserDetails';
import UserUpdate from './containers/01UserManagement/UserUpdate';
import QrCodeGroupManagement from './containers/01UserManagement/QrCodeGroupManagement';
import FactoryManagement from './containers/01UserManagement/FactoryManagement';
import OrderManagement from './containers/01UserManagement/OrderManagement';
import OrderDetails from './containers/01UserManagement/OrderDetails';
import Reward1 from './containers/07Reward/Reward1';
import Reward2 from './containers/07Reward/Reward2';
import FundsManagement0 from './containers/08FundsManagement/FundsManagement0';
import FundsManagement1 from './containers/08FundsManagement/FundsManagement1';
import FundsManagement2 from './containers/08FundsManagement/FundsManagement2';
import FundsManagement3 from './containers/08FundsManagement/FundsManagement3';
import FundsManagement4 from './containers/08FundsManagement/FundsManagement4';
import MiniGame from './containers/09MiniGame/MiniGame';
import TradeHistory1 from './containers/10TradeHistory/TradeHistory1';
import TradeHistory2 from './containers/10TradeHistory/TradeHistory2';
import Badge from './containers/11Badge/Badge';
import HowToPlay from './containers/12HowToPlay/HowToPlay';
import Prize from './containers/13Prize/Prize';
import News from './containers/03News/News';
import Ranking from './containers/04Ranking/Ranking';
import BeforeLogin from './containers/14BeforeLogin/BeforeLogin';
import Landing from './containers/00Home/Landing';
import NewsDetail from './containers/03News/NewsDetail';
import MiniGameList from './containers/09MiniGame/MiniGameList';
import { apiFunds } from './Api/_ApiFunds';
import { apiUserAccount } from './Api/_ApiUserAccount';
import UserConfirmationSuccess from './containers/00Home/UserConfirmationSuccess';
import Admin from './containers/15Admin/Admin';
import { apiTask } from './Api/_ApiTask';
import { apiAuth } from './Api/ApiAuth';
import RegisterSuccess from './containers/00Home/RegisterSuccess';
import EmailSupport from './containers/16EmailSupport/EmailSupport';
import LandingHeader from "./components/100Include/LandingHeader";
import IndexPage from "./containers/00Home/IndexPage";
import IndexHeader from "./components/100Include/IndexHeader";
import TransactionLog from "./containers/10TradeHistory/TransactionLog";
import FundPrice from "./containers/17FundPrice/FundPrice";
import AdminPortal from "./containers/18AdminPortal/AdminPortal";
import LuckyDrawTandC from './containers/19LuckyDraw/LuckyDrawTandC';

// Using Material for custom layout and
const drawerWidth = 260;
const styles = theme => ({
    root: {
        display: 'flex',
        paddingBottom: 32
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    footer: {
        width: `100%`,
        top: 'auto',
        bottom: 0
    },
    menuButton: {
        marginRight: 20,
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
        background: 'white'
    },
    content: {
        width: `100%`,
        minHeight: '100vh',
        flexGrow: 1
    }
});

class App extends Component {

    queue = [];
    isLanding = false;

    // change URL
    constructor(props) {
        super(props);
        this.state = {
            debug: false,
            mobileOpen: false,
            openSnackbar: false,
            messageInfo: {},
            registration: true,
            switchFund: true,
        }

        setForceLogout(() => this.props.logoutP());
        const authData = CacheService.getAuthData();
        if (authData) {
            setAccessToken(authData.access_token);
        }

        ModuleRegistry.register(InfiniteRowModelModule);
        ModuleRegistry.register(ClientSideRowModelModule);
    }

    componentDidMount() {
        this._getTaskPermissionAsync();
    }

    componentDidUpdate = () => {
        window.scrollTo(0, 0);

        // check if token has expired
        // if (this.props.auth.auth) {
        //     const languages = localStorage.getItem("languages")
        //     if(!languages) {
        //         this.getLanguages();
        //     }
        // }

        const authData = CacheService.getAuthData();
        if (authData && this.props.auth.auth && authData.access_token !== this.props.auth.token) {
            let data = {
                token: authData.access_token,
                refreshToken: authData.refresh_token,
            }
            this.props.loginP(data);
        }

        if (this.props.messages.msgArr && this.props.messages.msgArr.length > 0) {
            this.displayMsg();
        }
    }

    handleDrawerToggle = () => {
        this.setState(state => ({ mobileOpen: !state.mobileOpen }));
    };

    getLanguages = () => {
        apiLanguages.getLanguages(null).then(obj => {
            if (obj && obj.status === 200) {
                localStorage.setItem("languages", JSON.stringify(obj.data));
            }
        })
    }

    // Message display
    displayMsg = () => {
        if (this.state.openSnackbar) {
            this.setState({ openSnackbar: false });
            this.processQueue();
        } else {
            this.processQueue();
        }
    }

    processQueue = () => {
        if (this.props.messages.msgArr && this.props.messages.msgArr.length > 0) {
            let msgArray = [...this.props.messages.msgArr];
            const msg = { ...msgArray.shift() }
            this.setState({
                messageInfo: msg,
                openSnackbar: true,
                uploading: false,
            }, () => {
                this.props.setMessagesP(msgArray);
            });
        }
    };

    handleExited = () => {
        this.processQueue();
    };

    handleCloseSnackbar = (event, reason) => {
        if (reason && ['click', 'timeout'].includes(reason)) {
            this.setState({ openSnackbar: false });
        }
    };
    // END MESSAGE

    _getTaskPermissionAsync = () => {
        return apiAuth.getClientCredentials().then((res) => {
            return this._getTaskPermission(res.data.access_token);
        })
    }

    _getTaskPermission = (token) => {
        const cb = (obj) => {
            if (obj) {
                obj.body.forEach(task => {
                    if (task.permission_name === 'registration') {
                        if (task.is_permitted) {
                            this.setState({ registration: true });
                        } else {
                            this.setState({ registration: false });
                        }
                    }
                })
            }
        }

        const eCb = (obj) => {
            console.log("eCb : ", obj);
        }

        const params = null;
        const accept = 'application/json';
        const contentType = "application/x-www-form-urlencoded";
        return apiTask.getTaskPermissions(params, token, cb, eCb, accept, contentType);
    }

    getComponent = (urlArray, params) => {
        let locate = urlArray[1],
            currentURL = urlArray[2],
            param1 = urlArray[3],
            param2 = urlArray[4],
            param3 = urlArray[5],
            param4 = urlArray[6];

        if (currentURL) {
            switch (currentURL) {
                case '': {
                    this.isLanding = true;
                    return <Landingpg />;
                }
                case 'landing': {
                    if (this.props.auth.auth) {
                        return <Redirect to={{ pathname: "/" + this.props.i18n.language + '/index' }} />;
                    }
                    this.isLanding = true;
                    return <Landingpg />;
                }

                case 'index': {
                    if (!this.props.auth.auth) {
                        return <Redirect to={{ pathname: "/" + this.props.i18n.language + '/landing' }} />;
                    }
                    return <Landing />;
                }
                case 'login': {
                    return <Login />;
                }
                case 'register': {
                    if (this.props.auth.auth) {
                        return <Redirect to={{ pathname: "/" + this.props.i18n.language + '/index' }} />;
                    }
                    // if (this.state.registration) {
                        return <Profile page="register" />;
                    // } else {
                    //     alert('Registration period is over');
                    //     return <Redirect to={{ pathname: "/" + this.props.i18n.language + '/before-login' }} />;
                    // }
                }
                case 'register-success': {
                    if (this.props.auth.auth) {
                        return <Redirect to={{ pathname: "/" + this.props.i18n.language + '/index' }} />;
                    }
                    return <RegisterSuccess />;
                }
                case 'edit-profile': {
                    if (!this.props.auth.auth) {
                        return <Redirect to={{ pathname: "/" + this.props.i18n.language + '/landing' }} />;
                    }
                    return <Profile page="editProfile" />;
                }
                case 'reward': {
                    if (!this.props.auth.auth) {
                        return <Redirect to={{ pathname: "/" + this.props.i18n.language + '/landing' }} />;
                    }
                    if (param1 === '1') {
                        return <Reward1 />;
                    } else if (param1 === '2') {
                        return <Reward2 />;
                    }
                    break;
                }
                case 'manage-funds': {
                    if (!this.props.auth.auth) {
                        return <Redirect to={{ pathname: "/" + this.props.i18n.language + '/landing' }} />;
                    }
                    if (param1 === '0') {
                        return <FundsManagement0 />;
                    }
                    else if (param1 === '1') {
                        return <FundsManagement1 />;
                    }
                    else if (param1 === '2') {
                        return <FundsManagement2 />;
                    }
                    else if (param1 === '3') {
                        return <FundsManagement3 />;
                    }
                    else if (param1 === '4') {
                        return <FundsManagement4 />;
                    }
                    break;
                }
                case 'mini-game': {
                    if (!this.props.auth.auth) {
                        return <Redirect to={{ pathname: "/" + this.props.i18n.language + '/landing' }} />;
                    }
                    if (param1) {
                        return <MiniGame />
                    } else {
                        return <MiniGameList />;
                    }
                }
                case 'fund-price': {
                    if (!this.props.auth.auth) {
                        return <Redirect to={{ pathname: "/" + this.props.i18n.language + '/landing' }} />;
                    }
                    return <FundPrice />;
                }
                case 'trade-history': {
                    if (!this.props.auth.auth) {
                        return <Redirect to={{ pathname: "/" + this.props.i18n.language + '/landing' }} />;
                    }
                    if (param1 === '1') {
                        return <TradeHistory1 />;
                        // return <TransactionLog />;
                    } else if (param1 === '2') {
                        return <TradeHistory2 />;
                    }
                    break;
                }
                case 'badge': {
                    // if (!this.props.auth.auth) {
                    //     return <Redirect to={{ pathname: "/" + this.props.i18n.language + '/landing' }} />;
                    // }
                    return <Badge />;
                }
                case 'how-to-play': {
                    if (!this.props.auth.auth) {
                        return <Redirect to={{ pathname: "/" + this.props.i18n.language + '/landing' }} />;
                    }
                    return <HowToPlay />;
                }
                case 'prize': {
                    if (!this.props.auth.auth) {
                        return <Redirect to={{ pathname: "/" + this.props.i18n.language + '/landing' }} />;
                    }
                    return <Prize />;
                }
                case 'news': {
                    if (!this.props.auth.auth) {
                        return <Redirect to={{ pathname: "/" + this.props.i18n.language + '/landing' }} />;
                    }
                    return <News />;
                }
                case 'news-detail': {
                    if (!this.props.auth.auth) {
                        return <Redirect to={{ pathname: "/" + this.props.i18n.language + '/landing' }} />;
                    }
                    return <NewsDetail />;
                }
                case 'ranking': {
                    if (!this.props.auth.auth) {
                        return <Redirect to={{ pathname: "/" + this.props.i18n.language + '/landing' }} />;
                    }
                    return <Ranking />;
                }
                case 'before-login': {
                    if (this.props.auth.auth) {
                        return <Redirect to={{ pathname: "/" + this.props.i18n.language + '/index' }} />;
                    }
                    return <BeforeLogin />;
                }

                // case 'forget-password': {
                //     return <ForgetPassword />;
                // }
                case 'reset-password': {
                    return <ResetPassword />;
                }
                case 'user-confirm-success': {
                    return <UserConfirmationSuccess />;
                }
                case 'admin': {
                    if (!this.props.auth.auth) {
                        return <Redirect to={{ pathname: "/" + this.props.i18n.language + '/login' }} />;
                    }
                    return <Admin />;
                }
                case 'admin-portal': {
                    if (!this.props.auth.auth) {
                        return <Redirect to={{ pathname: "/" + this.props.i18n.language + '/login' }} />;
                    }
                    return <AdminPortal />;
                }
                case 'support': {
                    if (!this.props.auth.auth) {
                        return <Redirect to={{ pathname: "/" + this.props.i18n.language + '/login' }} />;
                    }
                    return <EmailSupport />;
                }
                case 'lucky-draw-tandc': {
                    return <LuckyDrawTandC />;
                }
                // case 'reset-password-success': {
                //     return <ResetPasswordSuccess />;
                // }

                // case 'tenant-management': {
                //     if (!this.props.auth.auth) {
                //         return <Redirect push to={"/" + this.props.i18n.language + '/login'} />;
                //     }
                //     if (param1) {
                //         if (param2 === 'view') {
                //             return <TenantDetails id={param1} />
                //         } else {
                //             return <TenantUpdate id={param1} />;
                //         }
                //     } else {
                //         return <TenantManagement />;
                //     }
                // }
                // case 'tenant': {
                //     if (!this.props.auth.auth) {
                //         return <Redirect push to={"/" + this.props.i18n.language + '/login'} />;
                //     }
                //     if (param1) {
                //         if (param2 === 'view') {
                //             return <TenantDetails id={param1} />
                //         } else {
                //             return <TenantUpdate id={param1} />;
                //         }
                //     } else {
                //         return <TenantUpdate />;
                //     }
                // }

                // /*** USER MANAGEMENT ***/
                // case 'user-management': {
                //     if (!this.props.auth.auth) {
                //         return <Redirect push to={"/" + this.props.i18n.language + '/login'} />;
                //     }
                //     if (param1) {
                //         return <UserDetails id={param1} />;
                //     } else {
                //         return <UserManagement />;
                //     }
                // }
                // case 'user': {
                //     if (!this.props.auth.auth) {
                //         return <Redirect push to={"/" + this.props.i18n.language + '/login'} />;
                //     }
                //     return <UserUpdate id={param1} />;
                // }
                // case 'tenant-member-management': {
                //     if (!this.props.auth.auth) {
                //         return <Redirect push to={"/" + this.props.i18n.language + '/login'} />;
                //     }
                //     if (param1) {
                //         return <TenantMemberDetails id={param1} />;
                //     } else {
                //         return <TenantMemberManagement />;
                //     }
                // }
                // case 'tenant-member': {
                //     if (!this.props.auth.auth) {
                //         return <Redirect push to={"/" + this.props.i18n.language + '/login'} />;
                //     }
                //     return <TenantMemberUpdate id={param1} />;
                // }
                // // case 'user-group-management': {
                // //     return <UserGroupManagement/>;
                // // }
                // case 'factory-management': {
                //     if (!this.props.auth.auth) {
                //         return <Redirect push to={"/" + this.props.i18n.language + '/login'} />;
                //     }
                //     return <FactoryManagement/>;
                // }
                // case 'qr-code-group-management': {
                //     if (!this.props.auth.auth) {
                //         return <Redirect push to={"/" + this.props.i18n.language + '/login'} />;
                //     }
                //     return <QrCodeGroupManagement/>;
                // }
                // case 'order-management': {
                //     if (!this.props.auth.auth) {
                //         return <Redirect push to={"/" + this.props.i18n.language + '/login'} />;
                //     }
                //     if (param1) {
                //         return <OrderDetails id={param1} />;
                //     } else {
                //         return <OrderManagement />;
                //     }
                // }
                // case 'order': {
                //     if (!this.props.auth.auth) {
                //         return <Redirect push to={"/" + this.props.i18n.language + '/login'} />;
                //     }
                //     // return <OrderUpdate id={param1} />;
                // }
                // case 'profile': {
                //     if (!this.props.auth.auth) {
                //         return <Redirect push to={"/" + this.props.i18n.language + '/login'} />;
                //     }
                //     return <Profile />;
                // }

                // /*** AUCTION MANAGEMENT ***/
                // case 'auction-management': {
                //     if (!this.props.auth.auth) {
                //         return <Redirect push to={"/" + this.props.i18n.language + '/login'} />;
                //     }
                //     if (param1) {
                //         if (param2 && param2 === 'files' && param3) {
                //             return <AuctionFileUpdate id={param3} />
                //         } else {
                //             return <AuctionDetails id={param1} />;
                //         }
                //     } else {
                //         return <AuctionManagement />;
                //     }
                // }
                // case 'auction': {
                //     if (!this.props.auth.auth) {
                //         return <Redirect push to={"/" + this.props.i18n.language + '/login'} />;
                //     }
                //     return <AuctionUpdate id={param1} />;
                // }

                // /*** CLIENT MANAGEMENT ***/
                // case 'client-management': {
                //     if (!this.props.auth.auth) {
                //         return <Redirect push to={"/" + this.props.i18n.language + '/login'} />;
                //     }
                //     if (param1) {
                //         return <ClientDetails id={param1} />;
                //     } else {
                //         return <ClientManagement />;
                //     }
                // }
                // case 'client': {
                //     if (!this.props.auth.auth) {
                //         return <Redirect push to={"/" + this.props.i18n.language + '/login'} />;
                //     }
                //     return <ClientUpdate id={param1} />;
                // }
                // case 'add-credit': {
                //     if (!this.props.auth.auth) {
                //         return <Redirect push to={"/" + this.props.i18n.language + '/login'} />;
                //     }
                //     return <AddCredit id={param1} />;
                // }
                // case 'send-msg': {
                //     if (!this.props.auth.auth) {
                //         return <Redirect push to={"/" + this.props.i18n.language + '/login'} />;
                //     }
                //     return <OneToOneMsg id={param1} />;
                // }
                // case 'send-push-msg': {
                //     if (!this.props.auth.auth) {
                //         return <Redirect push to={"/" + this.props.i18n.language + '/login'} />;
                //     }
                //     return <SendPushMsg id={param1} />;
                // }
                // case 'send-wechat': {
                //     if (!this.props.auth.auth) {
                //         return <Redirect push to={"/" + this.props.i18n.language + '/login'} />;
                //     }
                //     return <SendWeChat id={param1} />;
                // }

                // case 'push-msg-schedule': {
                //     if (!this.props.auth.auth) {
                //         return <Redirect push to={"/" + this.props.i18n.language + '/login'} />;
                //     }
                //     if (param1) {
                //         return <SendPushMsgUpdate id={param1} />;
                //     } else {
                //         return <PushMsgSchedule />;
                //     }
                // }

                // /*** Report ***/
                // case 'report': {
                //     if (!this.props.auth.auth) {
                //         return <Redirect push to={"/" + this.props.i18n.language + '/login'} />;
                //     }
                //     return <ReportDateManagement />;
                // }
                // case 'report_1': {
                //     if (!this.props.auth.auth) {
                //         return <Redirect push to={"/" + this.props.i18n.language + '/login'} />;
                //     }
                //     return <Report1Management date={param1}/>;
                // }
                // case 'report_3': {
                //     if (!this.props.auth.auth) {
                //         return <Redirect push to={"/" + this.props.i18n.language + '/login'} />;
                //     }
                //     return <Report3Management date={param1}/>;
                // }

                // /*** CONFIGURATION ***/
                // case 'configuration': {
                //     if (!this.props.auth.auth) {
                //         return <Redirect push to={"/" + this.props.i18n.language + '/login'} />;
                //     }
                //     if (param1) {
                //         if (param2 === 'view') {
                //             return <ConfigurationView id={param1} />
                //         } else {
                //             return <ConfigurationDetail id={param1} />;
                //         }
                //     } else {
                //         return <ConfigurationList />;
                //     }
                // }
                // case 'recyclable-management': {
                //     if (!this.props.auth.auth) {
                //         return <Redirect push to={"/" + this.props.i18n.language + '/login'} />;
                //     }
                //     return <RecyclableManagement />;
                // }
                // case 'recyclable': {
                //     if (!this.props.auth.auth) {
                //         return <Redirect push to={"/" + this.props.i18n.language + '/login'} />;
                //     }
                //     return <RecyclableManagement />;
                // }
                // /*** Location ***/
                // case 'store-management': {
                //     if (!this.props.auth.auth) {
                //         return <Redirect push to={"/" + this.props.i18n.language + '/login'} />;
                //     }
                //     if (param1) {
                //         return <StoreView id={param1} />
                //     } else {
                //         return <StoreManagement />;
                //     }
                // }
                // case 'store': {
                //     return <StoreDetail id={param1} />
                // }
                default: {
                    if (!this.props.auth.auth) {
                        return <Redirect push to={"/" + this.props.i18n.language + '/landing'} />;
                    } else {
                        return <Redirect push to={"/" + this.props.i18n.language + '/index'} />;
                    }
                }
            }
        } else {
            if (!this.props.auth.auth) {
                return <Redirect push to={"/" + this.props.i18n.language + '/landing'} />;
            } else {
                return <Redirect push to={"/" + this.props.i18n.language + '/index'} />;
            }
        }
    }

    isPublicPage = (currentURL) => {
        return ['login', 'landing', 'register', 'register-success', 'user-confirm-success'].includes(currentURL);
    }

    isLandingPage = (currentURL) => {
        return ['landing'].includes(currentURL);
    }

    isIndexPage = (currentURL) => {
        return ['index'].includes(currentURL);
    }

    render() {
        const { classes, t, i18n } = this.props;
        const { openSnackbar, messageInfo } = this.state;

        let pathname = this.props.route.location.pathname,
            search = this.props.route.location.search,
            urlArray = pathname.split("/"),
            currentURL = urlArray[2],
            params = null;

        if (search !== "") {
            params = querySearch(search);
        }

        const isPublicPage = this.isPublicPage(currentURL);
        const isLandingPage = this.isLandingPage(currentURL);
        const isIndexPage = this.isIndexPage(currentURL);

        const sidebarDrawer = (
            <Sidebar
                currentURL={currentURL}
                toolbarClass={classes.toolbar}
            />);

        const sidebarNav = (
            !isPublicPage &&
            <nav
                className={classes.drawer}
                style={{
                    zIndex: 50
                }}
            >
                <Hidden smUp implementation="css">
                    <Drawer
                        container={this.props.container}
                        variant="temporary"
                        anchor={'left'}
                        open={this.state.mobileOpen}
                        onClose={this.handleDrawerToggle}
                        classes={{ paper: classes.drawerPaper, }}
                    >
                        {sidebarDrawer}
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer classes={{ paper: classes.drawerPaper }} variant="permanent" open >
                        {sidebarDrawer}
                    </Drawer>
                </Hidden>
            </nav>
        );

        const header = (
            <PrivateHeader handleDrawerToggle={this.handleDrawerToggle} btnMenuClass={classes.menuButton} />
        )

        const landingHeader = () => {
            const includeText = !['register-success', 'user-confirm-success'].includes(currentURL);
            return <LandingHeader includeText={includeText} />
        }

        const indexHeader = (
            <IndexHeader />
        )

        const footer = <AppBar position="fixed" className={classes.footer}>
            <div className="footer__container">
                {t("UserManagement:tradePromotionCompetitionLicenceNo")} xxxxx
            </div>
        </AppBar>;

        return (
            <div className={classes.root} style={{ fontFamily: i18n.language === 'en-US' ? 'Arial' : 'NotoSansCJKtc' }}>
                <CssBaseline />
                {!isPublicPage && !isIndexPage && header}
                {isPublicPage && !isIndexPage && landingHeader()}
                {isIndexPage && indexHeader}
                {/*{landingHeader}*/}
                {/*sidebarNav*/}
                <main className={classes.content}>
                    <div className={`full-height ${isPublicPage ? 'public-page' : 'private-page'}`}>
                        {/*<div className={isLandingPage ? '' : classes.toolbar} />*/}
                        {/* <div className={''} /> */}
                        {this.getComponent(urlArray, params)}

                        <Snackbar
                            key={messageInfo.key}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={openSnackbar}
                            autoHideDuration={2000}
                            onClose={this.handleCloseSnackbar}
                            onExited={this.handleExited}
                            ContentProps={{
                                'aria-describedby': 'message-id',
                            }}
                        >
                            <MySnackbarContentWrapper
                                variant={messageInfo.variantSnackbar}
                                message={messageInfo.messageSnackbar}
                                onClose={() => this.handleCloseSnackbar(null, 'click')}
                            />
                        </Snackbar>
                    </div>
                </main>
                {footer}
            </div>
        );
    }
}
App.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    route: state.router,
    auth: state.auth,
    messages: state.messages,
});

const mapDispatchToProps = dispatch => ({
    loginP: data => dispatch(login(data)),
    logoutP: data => dispatch(logout(data)),
    setBreadcrumbP: data => dispatch(setBreadcrumb(data)),
    setMessagesP: data => dispatch(setMessages(data))
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(App)));
