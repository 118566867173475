// Essential for all components
import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
// Styling
import Grid from '@material-ui/core/Grid';
import {Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, InputAdornment, withStyles} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { Flex } from 'reflexbox';
// Api
import {apiAuth} from '../../Api/ApiAuth';
// Redux
import {connect} from 'react-redux';
import {forgetPassword, getUserInfo, login, logout, register} from '../../Redux/Action/authAction';
// Utils
import {Field, Form, Formik} from 'formik';
import * as Yup from 'yup';
import {get} from 'lodash-es';
// Children components
import ErrorMessage from '../../components/100Include/ErrorMessage';
import {setAccessToken, setForceLogout, setforceLogoutMsg} from "../../Api/_ApiFactoryWithHeader";
import { CacheService } from "../../utils/CacheService";
import backIcon from "../../images/back.png"
import { apiUsers } from '../../Api/_ApiUsers';
import moment from 'moment';
import { clearLoginData } from '../../utils/AuthService';
import { apiTask } from '../../Api/_ApiTask';
import { apiBadges } from '../../Api/_ApiBadges';

const REGULAR_REVIEW_BADGE_ID = '434a93d6-f13d-400c-a603-82faa1dd5588';

const Divider = (props) => {
    const { text } = props;
    return (
        <div className="strike" style={{ marginTop: '1rem', marginBottom: '1rem', fontSize: '1rem' }}>
            <span>{text}</span>
        </div>
    )
}

const CustomCheckbox = withStyles({
    root: {
        color: 'black',
        padding: 0,
        alignItems: 'start',

        "& .MuiSvgIcon-root": {
            height: 25,
            width: 25,
            alignItems: 'start',
            fill: 'black',
        },
        '&$checked': {
            color: 'black'
        },
        '&::hover': {
            opacity: 0
        },
    },
    checked: {}
})((props) => <Checkbox {...props} />);

const CustomTextField = withStyles({
    root: {
        '& label.Mui-focused': {
            color: '#717171',
        },
    },
})((props) => <TextField {...props} />);

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            MessageContent: '',
            openDialog: false,
            registration: null,
            distributeRegularReviewBadge: null
        }
    }

    componentDidMount() {
        if (this.props.auth.auth) {
            this.logOut();
        }
        this._getTaskPermissionAsync();
    }

        _getTaskPermissionAsync = () => {
        return apiAuth.getClientCredentials().then((res) => {
            return this._getTaskPermission(res.data.access_token);
        })
    }

    _getTaskPermission = (token) => {
        const cb = (obj) => {
            if (obj) {
                obj.body.forEach(task => {
                    if (task.permission_name === 'registration') {
                        this.setState({ registration: task.is_permitted });
                    }
                    if (task.permission_name === 'distribute regular review badge') {
                        this.setState({ distributeRegularReviewBadge: task.is_permitted });
                    }
                })
            }
        }

        const eCb = (obj) => {
            console.log("eCb : ", obj);
        }

        const params = null;
        const accept = 'application/json';
        const contentType = "application/x-www-form-urlencoded";
        return apiTask.getTaskPermissions(params, token, cb, eCb, accept, contentType);
    }

    logOut = () => {
        clearLoginData()
        this.props.logoutP();
    };

    // login
    _signInAsync = (values) => {
        const { t, i18n } = this.props;
        if (typeof (values) !== 'undefined') {
            let submitUsername = values.username;
            let submitPassword = values.password;

            apiAuth.authenticate(submitUsername, submitPassword).then((res) => {
                if (res && res.status === 200) {
                    let data = {
                        token: res.data.access_token,
                        refreshToken: res.data.refresh_token,
                    }
                    setAccessToken(res.data.access_token);
                    setForceLogout(()=>this.props.logoutP(data));
                    setforceLogoutMsg(t("LoginRegister:placeholder.forceLogout"));
                    this.props.loginP(data);
                    CacheService.saveAuthData(res.data);
                    this._getUserInformation(res.data.access_token, () => {
                        const body = {
                            last_logged_in: moment().valueOf()
                        }
                        apiUsers.updateUser(submitUsername, body).then(() => {
                            const endpoint = '/index';
                            this.props.history.push('/' + i18n.language + endpoint);
                            this.props.location.pathname = `${i18n.language}${endpoint}`;
                            // this.checkDistributeRegularReviewBadge(values.username, body.last_logged_in);
                        })
                    });
                } else {
                    const error = res.data && res.data.error ? res.data.error_description : t("LoginRegister:login.YouHaveEnteredIncorrectUsernameOrPassword");
                    this.setState({
                        MessageContent: error
                    });
                }
            }).catch((e) => {
                this.setState({
                    MessageContent: t("LoginRegister:login.YouHaveEnteredIncorrectUsernameOrPassword")
                });
            });
        }
    }

    checkDistributeRegularReviewBadge = (username, wonTime) => {
        const { distributeRegularReviewBadge } = this.state;
        if (distributeRegularReviewBadge) {
            apiBadges.getUserBadges({ badge: REGULAR_REVIEW_BADGE_ID }).then(obj => {
                if (obj && obj.status === 200) {
                    if (obj.data.length === 0) {
                        const userBadgeBody = {
                            user: username,
                            badge: REGULAR_REVIEW_BADGE_ID,
                            won_time: wonTime
                        }
                        apiBadges.createUserBadges(userBadgeBody)
                    }
                }
            })
        }
    }

    _logout = () => {
        clearLoginData()
        this.props.logoutP();
    }

    _getUserInformation = (access_token, successCallback) => {
        const cb = (obj) => {
            const body = obj.body;
            const userStatus = body.status;
            if (userStatus === 'approved') {
                this.props.getUserInfoP(body);
                CacheService.saveProfileData(body);
                if (successCallback) {
                    successCallback(obj);
                }
            } else {
                this._logout();
                if (userStatus === 'pending') {
                    alert("Your account registration is still pending for approval. Please try again later");
                } else if (userStatus === 'not verified') {
                    alert("Your account registration is not confirmed. Please check the confirmation link sent to your email");
                }
            }
        }
        const eCb = (obj) => {
            console.log("eCb : ", obj);
        }
        const params = {
            // $expand: 'user_stores,tenant_members/tenant'
        };

        apiAuth.getUserInformation(params, access_token, cb, eCb);
    }

    handleRegisterPage = () => {
        const { i18n, history } = this.props;
        history.push('/' + i18n.language + '/register');
    }

    back = () => {
        const { i18n, history } = this.props;
        history.push('/' + i18n.language + '/');
    }

    handleOpenDialog = () => {
        this.setState({ openDialog: true });
    }

    handleCloseDialog = () => {
        this.setState({ openDialog: false });
    }

    handleResetPassword = (value) => {
        alert(value.email)
    }

    _forgetPasswordAsync = (values) => {
        if (typeof (values) !== 'undefined') {

            var promise = Promise.resolve();
            apiAuth.getClientCredentials().then((res) => {
                promise.then(() => {
                    this._forgetPassword(values, res.data.access_token);
                });
            })
        }
    }

    _forgetPassword = (values, token) => {
        const { t,
            //i18n
         } = this.props;

        const cb = (obj) => {
            if(obj && obj.status!==404){
                this.props.forgetPasswordP(values.email);
                alert(t('LoginRegister:forgetPassword.msgSent'));
                this.handleCloseDialog();
            }
            else{
                alert(t('LoginRegister:forgetPassword.emailNotExist'));
            }
        }

        const eCb = (obj) => {
            console.log("eCb : ", obj);
        }

        const params = null;
        const accept = 'application/json';
        const contentType = "application/x-www-form-urlencoded";
        apiAuth.forgetPassword(values.email, params, token, cb, eCb, accept, contentType);
    }

    // form login
    formLogin = ({ values, errors, touched, handleChange, setFieldValue }) => {
        const { t, i18n } = this.props;
        const { registration } = this.state;
        return (
            <Grid item xm={12} md={6} xl={4} className="grid">
                <Form
                    id="loginForm"
                    className="form-wrapper login-form"
                >
                    <Grid container>
                        <Grid item xs={12} className="grid">
                        {this.state.MessageContent &&
                        <Grid item xs={12} className="ErrorMessage" style={{ marginBottom: '1rem' }}>
                            <ErrorMessage
                                message={this.state.MessageContent}
                            />
                        </Grid>
                        }
                            <Field name="username" style={{ fontSize: 40 }}>
                                {({
                                    field, // { name, value, onChange, onBlur }
                                    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                                    meta,
                                }) => (
                                    <CustomTextField
                                        name={field.name}
                                        type="text"
                                        letterSpacing={i18n.language === 'en-US' ? 'normal' : '0.15rem'}
                                        label={t("LoginRegister:login.MobileNo")}
                                        fullWidth
                                        inputProps={{ maxLength: 8}}
                                        InputLabelProps={{ shrink: true, style: { fontSize: '1.5rem', letterSpacing: i18n.language === 'en-US' ? 'normal' : '0.15rem' }}}
                                        {...field}
                                    />
                                )}
                            </Field>
                            {errors.username && touched.username && <ErrorMessage name="username" message={errors.username } />}
                        </Grid>
                        <Grid item xs={12} className="grid">
                            <Field  name="password">
                                {({
                                    field, // { name, value, onChange, onBlur }
                                    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                                    meta,
                                }) => (
                                    <CustomTextField
                                        letterSpacing={i18n.language === 'en-US' ? 'normal' : '0.15rem'}
                                        name={field.name}
                                        type={values.showPassword ? "text" : "password"}
                                        label={t("LoginRegister:login.Password")}
                                        fullWidth
                                        InputLabelProps={{ shrink: true, style: { fontSize: '1.5rem', letterSpacing: i18n.language === 'en-US' ? 'normal' : '0.15rem' } }}
                                        InputProps={{
                                            endAdornment: values.showPassword ? <Visibility style={{ position: 'absolute', right: 0, color: '#00000080', cursor: 'pointer', top: '1.5' }} onClick={()=>{setFieldValue('showPassword', false)}} /> : <VisibilityOff style={{ position: 'absolute', right: 0, color: '#00000080', cursor: 'pointer'}} onClick={()=>{setFieldValue('showPassword', true)}}/>
                                        }}
                                        {...field}
                                    />
                                )}
                            </Field>
                            {errors.password && touched.password && <ErrorMessage name="password" message={errors.password } />}
                        </Grid>
                        <Grid item xs={12} className="grid">
                            <Flex alignItems="center">
                                <CustomCheckbox checked={values.rememberPassword} onChange={() => { setFieldValue('rememberPassword', !values.rememberPassword) }} />
                                <Flex letterSpacing={i18n.language === 'en-US' ? 'normal' : '0.15rem'} ml="0.5rem" fontSize="1.1rem">{t("LoginRegister:login.RememberPassword")}</Flex>
                            </Flex>
                            <ErrorMessage name="rememberPassword" />
                        </Grid>
                        <Grid item xs={12} className="grid">
                            <Button style={{ letterSpacing: i18n.language === 'en-US' ? 'normal' : '0.15rem' }} type="submit">{t("Common:General.Login")}</Button>
                        </Grid>
                        <Grid item className="grid">
                            <div onClick={this.handleOpenDialog} style={{ color: 'black', textDecoration: 'underline', fontSize: '1.1rem', cursor: 'pointer', letterSpacing: i18n.language === 'en-US' ? 'normal' : '0.15rem' }}>
                                {t("Common:General.ForgetPassword")}?
                            </div>
                        </Grid>
                        {/* {registration && <Grid container>
                            <Grid item xs={12} className="grid">
                                <Divider text={t("Common:General.or")} />
                            </Grid>
                            <Grid item xs={12} className="grid">
                                <Button type="button" onClick={this.handleRegisterPage} style={{ backgroundColor: 'transparent', color: 'black', borderStyle: 'solid', borderWidth: 2 }}>{t("Common:General.RegisterAnAccount")}</Button>
                            </Grid>
                        </Grid>} */}
                    </Grid>
                </Form>
            </Grid>
        )
    }

    formResetPwdConfiguration = ({values, errors, touched, handleChange}) => {
        const { t } = this.props;
        return values && (
            <Form id="resetPwdForm" className="full-width">
                <Flex>{t("LoginRegister:forgetPassword.description")}</Flex>
                <Field name="email">
                    {({ field }) => (
                        <CustomTextField
                            autoFocus
                            name={field.name}
                            label={t("UserManagement:email")}
                            // type="email"
                            fullWidth
                            InputProps={{ style: { fontSize: '1.5em' } }}
                            InputLabelProps={{ shrink: true, style: { fontSize: '1.3em', marginTop: '-0.5em' }}}
                            style={{ marginTop: '2rem', marginBottom: errors.email ? '1rem' : '2rem' }}
                            {...field}
                        />
                    )}
                </Field>
                {errors.email && touched.email && <ErrorMessage name="email" message={errors.email} />}

                <Flex justifyContent="flex-end">
                    <Button onClick={this.handleCloseDialog}>
                        {t("Common:Button.cancel")}
                    </Button>
                    <Button type="submit" style={{ backgroundColor: '#ec6453', color: 'white', marginLeft: '1rem' }}>
                        {t("Common:Button.reset")}
                    </Button>
                </Flex>
            </Form>
        )
    }

    render() {
        const { t, i18n } = this.props;

        const Schema = Yup.object().shape({
            username: Yup.string()
                .matches(/^[0-9]{8}/, t("LoginRegister:login.Checking5"))
                .required(t("LoginRegister:login.Checking5")),
            password: Yup.string()
                .typeError(t("LoginRegister:login.Checking3"))
                .required(t("LoginRegister:login.Checking4")),
        })

        const SchemaResetPassword = Yup.object().shape({
            email: Yup.string().required(t("LoginRegister:login.Checking1")),
        })

        return (
            <div className="login-page">
                <div className="main__container" style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#F8F8F8' }}>
                    <Flex letterSpacing={i18n.language === 'en-US' ? 'normal' : '0.15rem'} justifyContent="start" className="header-title-profile">
                        <img src={backIcon} className="back-icon" alt="back-icon" onClick={this.back}/>
                        <Flex alignItems="center" ml="0.5rem">
                            {t("Common:General.Login")}
                        </Flex>
                    </Flex>
                    <Flex justifyContent="center" mt="2rem">
                        <Formik
                            initialValues={{
                                username: '',
                                password: '',
                                showPassword: false,
                                rememberPassword: false
                            }}
                            validationSchema={Schema}
                            i18n={i18n}
                            onSubmit={this._signInAsync}
                            component={this.formLogin}
                        />
                    </Flex>
                    <Dialog open={this.state.openDialog} onClose={this.handleCloseDialog} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">{t("Common:General.ResetPassword")}</DialogTitle>
                            <DialogContent>
                                <DialogContentText style={{ color: '#717171' }}>
                                    <Formik
                                        enableReinitialize
                                        initialValues={{
                                            email: '',
                                        }}
                                        validationSchema={SchemaResetPassword}
                                        onSubmit={this._forgetPasswordAsync}
                                        component={this.formResetPwdConfiguration}
                                    />
                                </DialogContentText>
                            </DialogContent>
                    </Dialog>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    loginP: data => dispatch(login(data)),
    logoutP: data => dispatch(logout(data)),
    getUserInfoP: data => dispatch(getUserInfo(data)),
    registerP: data => dispatch(register(data)),
    forgetPasswordP: data => dispatch(forgetPassword(data))
});


export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(Login)));
