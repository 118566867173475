// Essential for all components
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import { setBreadcrumb } from "../../Redux/Action/breadcrumbAction";
import { Flex } from 'reflexbox';
import moment from 'moment';
import testNewsImg from "../../images/test-news.png";

import { getUserInfo } from "../../Redux/Action/authAction";
import { apiMiniGames } from "../../Api/_ApiMiniGames.js";
import backIcon from "../../images/back.png";
import forwardIcon from "../../images/forward.png";

const DATE_FORMAT = 'MMMM d, YYYY';

const PLAN_FOR_FUTURE_MINI_GAME_UUID = '0e10422b-7fdb-4163-a736-8cd9da97ea1a';

const GameList = props => {
    const { t, onClick, gameItems, i18n } = props;
    const isChinese = i18n.language === 'zh-HK'
    const periodStartFormat = isChinese ? 'YYYY年M月D日' : 'MMMM d';
    const periodEndFormat = isChinese ? 'M月D日' : 'MMMM d, YYYY';
    const locale = isChinese ? 'zh-hk' : 'en';
    
    return gameItems.map((item) => {
        const title = i18n.language === 'en-US' ? item.titleEng : item.titleCn;
        return (
            <Flex width="100%" key={item.id} flexDirection="column" backgroundColor="white" marginBottom="2rem" className='mini-game-container'>
                <img src={testNewsImg} width="100%" alt="img" />
                {/* <iframe width="100%" height="100%" src={item.video} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe> */}
                <Flex width="100%" flexDirection="column" padding="1rem">
                    <Flex fontSize="1.1rem" mt="1rem">
                        {t("MiniGame:mission")} {item.sequence}: {title}
                    </Flex>
                    {/* {item.startDate && <Flex fontWeight="bold" mt="1rem">
                        {t("Common:General.from")} {moment(item.startDate).locale(locale).format(periodStartFormat)} {t("Common:General.to")} {moment(item.endDate).locale(locale).format(periodEndFormat)}
                    </Flex>} */}
                    <Flex fontSize="1.1rem" alignItems="center" mt="2rem" justifyContent={item.completed ? "flex-end" : "flex-start"}>
                        {!item.completed && <Flex onClick={() => onClick(item)} style={{ cursor: 'pointer' }}>
                            <img src={forwardIcon} className="back-icon" style={{ width: '25px', height: '25px', marginRight: '8px' }} alt="forward-icon"/>
                            {t("MiniGame:playNow")}
                        </Flex>}
                        {item.completed && <Flex color="#01a758" padding="5px" style={{ border: 2, borderStyle: 'solid', borderColor: '#01a758', borderRadius: 5 }}>
                            {t("MiniGame:completed")}
                        </Flex>}
                    </Flex>
                </Flex>
            </Flex>
        )
    });
}

class MiniGameList extends Component {

    state = {
        gameItems: [],
    }

    back = () => {
        const { history } = this.props;
        history.goBack();
    }

    handleClick = (game) => {
        const { i18n, history } = this.props
        if (game.miniGameId === PLAN_FOR_FUTURE_MINI_GAME_UUID) {
            this.props.history.push('/' + i18n.language + '/manage-funds/2', {instructionType: 'new'});
        } else {
            history.push('/' + i18n.language + `/mini-game/${game.id}`, { quizzes: game.miniGameQuizzes, titleCn: game.titleCn, titleEng: game.titleEng, gameNumber: game.id, miniGameId: game.miniGameId, completed: game.completed, videoUrlEng: game.videoEng, videoUrlCn: game.videoCn });
        }
    }

    _getAllMiniGames = () => {
        const param = {
            '$orderby': 'sequence desc',
            is_playable: true
        }
        apiMiniGames.getAllMiniGames(param).then(obj => {
            const promises1 = obj.data.map((game) => {
                const miniGame = {
                    id: game.sequence,
                    sequence: game.sequence,
                    miniGameId: game.mini_game_id,
                    titleCn: game.title_cn,
                    titleEng: game.title_eng,
                    videoEng: game.video_url_eng,
                    videoCn: game.video_url_cn,
                    startDate: game.start_date,
                    endDate: game.end_date,
                    miniGameQuizzes: []
                };
                return apiMiniGames.getMiniGameQuizzess(miniGame.miniGameId).then(res => {
                    const promises2 = res.data.map((quiz, index) => {
                        const miniGameQuiz = {
                            id: index + 1,
                            miniGameQuiz: quiz.mini_game_quiz_id,
                            question: quiz.question
                        }
                        return apiMiniGames.getMiniGameQuizAnswers(miniGameQuiz.miniGameQuiz).then(answerRes => {
                            miniGameQuiz.quizSelections = answerRes.data.map(answer => {
                                let option;
                                if (answer.sequence === 1) {
                                    option = 'A';
                                } else if (answer.sequence === 2) {
                                    option = 'B';
                                } else if (answer.sequence === 3) {
                                    option = 'C';
                                }
                                const selection = {
                                    option: option,
                                    content: answer.answer_text,
                                    answer: answer.is_correct_answer
                                };
                                return selection;
                            });
                            return miniGameQuiz;
                        });
                    })
                    return Promise.all(promises2).then(miniGameQuizzes => {
                        miniGame.miniGameQuizzes = miniGameQuizzes
                        const userMiniGameBody = {
                            user: this.props.auth.userInfo.username,
                            mini_game: game.mini_game_id
                        }
                        return apiMiniGames.getUserMiniGames(userMiniGameBody).then(result => {
                            if (result.data.length > 0) {
                                miniGame.completed = true;
                            }
                            return miniGame;
                        })
                    })
                })

            })

            Promise.all(promises1).then(allMiniGames => {
                this.setState({ gameItems: allMiniGames });
            })
        });
    }

    componentDidMount() {
        this._getAllMiniGames();
    }

    render() {
        const { t, i18n } = this.props;
        const { gameItems } = this.state;
        return (                    
            <div>
                <Flex className="main__container head-container">
                    <Flex flex={1} flexDirection="column">
                        <Flex flex={1} justifyContent="start" alignItems="center" className="header-title">
                            <img src={backIcon} className="back-icon" alt="back-icon" onClick={this.back}/>
                            <Flex ml="1rem">
                            {/* <Flex letterSpacing={i18n.language === 'en-US' ? '' : '0.3rem'} ml="1rem"> */}
                                {t("MiniGame:miniGame")}
                            </Flex>
                        </Flex>
                        {/*<Flex className="comprehensive">*/}
                        {/*    {t("MiniGame:comprehensive")}.*/}
                        {/*</Flex>*/}
                        <Flex mt="1rem" flexDirection="column" flex={1} alignItems="center">
                            <GameList t={t} onClick={this.handleClick} gameItems={gameItems} i18n={i18n} />
                        </Flex>
                    </Flex>
                </Flex>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    breadcrumbArr: state.breadcrumb.breadcrumbArr
});
const mapDispatchToProps = dispatch => ({
    setBreadcrumbP: data => dispatch(setBreadcrumb(data)),
    getUserInfoP: data => dispatch(getUserInfo(data)),
});
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(MiniGameList)));