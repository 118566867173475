import { Button, Dialog, makeStyles, IconButton } from "@material-ui/core";
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { Flex } from "reflexbox";

const withStyles = (Component) => {
    return function WrappedComponent(props) {
        const useStyles = makeStyles({
            root: {
                "& .MuiPaper-root": {
                    backgroundColor: 'transparent',
                    margin: 0,
                    padding: 0
                },
                "& .MuiPaper-elevation24": {
                    boxShadow: 'none'
                }
            },
            closeButton: {
                width: 25,
                height: 25,
                zIndex: 2,
                position: 'absolute',
                top: 108,
                right: 15
            }
        });
        const classes = useStyles();
        return <Component classes={classes} {...props} />
    }
}

const MiddleDialog = (props) => {
    const { classes, open, handleCloseDialog, handleClickDialog, title, description, badge, buttonText } = props;
    return (
        <Dialog fullWidth open={open} classes={{ root: classes.root }} className="badge-dialog">
            {badge ? <Flex justifyContent="center" className="badge-image-container">
                <img src={badge.image_url ? require(`../../images/${badge.image_url}`) : null} className="badge-icon" alt="badge" />
            </Flex> :
            <Flex className="badge-icon" />
            }
            <Flex className="dialog-header" />
            {!badge && <IconButton className={classes.closeButton} onClick={handleCloseDialog} style={{ color: 'white' }}>
                <CloseIcon />
            </IconButton>}
            <Flex className="dialog-body">
                <Flex>
                    {title}
                </Flex>
                {/* <Flex mt="1rem" padding="0.5rem"> */}
                <Flex mt="1rem">
                    {description}
                </Flex>
                <Flex flex={1} justifyContent="center" mt="1rem">
                    <Flex width="100%" className="button-wrapper" justifyContent="center">
                        <Button className="primary-button m0" onClick={handleClickDialog}>{buttonText ? buttonText : 'OK'}</Button>
                    </Flex>
                </Flex>
            </Flex>
        </Dialog>
    )
}

export default withStyles(MiddleDialog);