// Essential for all components
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import { Flex } from 'reflexbox';

import backIcon from "../../images/back.png";
import { apiFunds } from '../../Api/_ApiFunds';
import moment from 'moment';

import riskIndex1 from "../../images/risk-index-1.png";
import riskIndex2 from "../../images/risk-index-2.png";
import riskIndex3 from "../../images/risk-index-3.png";
import riskIndex4 from "../../images/risk-index-4.png";
import riskIndex5 from "../../images/risk-index-5.png";
import riskIndex6 from "../../images/risk-index-6.png";
import riskIndex7 from "../../images/risk-index-7.png";

const FundPriceList = (props) => {
    const { t, i18n, asOfDate, funds } = props;
    return (
        <Flex flexDirection="column" width="100%">
            {funds.map(fund => {
                let riskIcon;
                switch (fund.risk_index) {
                    case 1: {
                        riskIcon = riskIndex1;
                        break;
                    }
                    case 2: {
                        riskIcon = riskIndex2;
                        break;
                    }
                    case 3: {
                        riskIcon = riskIndex3;
                        break;
                    }
                    case 4: {
                        riskIcon = riskIndex4;
                        break;
                    }
                    case 5: {
                        riskIcon = riskIndex5;
                        break;
                    }
                    case 6: {
                        riskIcon = riskIndex6;
                        break;
                    }
                    case 7: {
                        riskIcon = riskIndex7;
                        break;
                    }
                    default: {}
                }
                return !fund.fund_code.includes('DIS148 / DIS149') && (
                    <Flex flex={1} flexDirection="row" justifyContent="space-between" mt="3rem">
                        <Flex flex={2} flexDirection="column" alignItems="flex-start">
                            <Flex style={{ textDecoration: 'underline', 'text-underline-offset': '5px', 'text-decoration-thickness': '1.5px', textDecorationColor: '#33A35B'}}>
                                {fund.fund_code} {t(`Fund:${fund.fund_name}`)}
                            </Flex>
                            <Flex className="sub-text">
                                {t("FundPrice:hkd")}
                            </Flex>
                        </Flex>
                        {riskIcon && <Flex flex={1} flexDirection="column" alignItems="center" height="100%">
                            <Flex alignItems="center" height="100%">
                                <img className="risk-icon" src={riskIcon} alt="riskIcon" />
                            </Flex >
                            <Flex height="100%" className="sub-text">{t("FundPrice:asOf")} {moment(fund.review_date).format('DD/MM/YYYY')}</Flex>
                        </Flex>}
                        <Flex flex={1} flexDirection="column" alignItems="flex-end" height="100%">
                            <Flex alignItems="center" height="100%">{fund.unit_price_reference}</Flex>
                            {fund.unit_price_reference && <Flex height="100%" className="sub-text">{t("FundPrice:asOf")} {moment(asOfDate).format('DD/MM/YYYY')}</Flex>}
                        </Flex>
                    </Flex>
                )
            })}
            

            {/* <Flex width="100%" my={"3rem"}>
                <Flex width="50%" justifyContent="start">
                    <u style={{
                        textDecoration: 'underline',
                        textDecorationColor: '#8DCC9D',
                        textDecorationThickness: '2px',
                        textUnderlineOffset: '10px'
                    }}>
                        {t("FundPrice:Age65PlusFund")}
                    </u>
                </Flex>
                <Flex flexDirection="column" width="50%" justifyContent="center" alignItems="end">
                    <div>
                        10.830
                    </div>
                    <div style={{color: '#909090'}}>
                        {t("FundPrice:asOf")} 16/06/2023
                    </div>
                </Flex>
            </Flex> */}
        </Flex>
    )
}


class FundPrice extends Component {

    state = {
        funds: [],
        asOfDate: null
    }

    back = () => {
        const { history } = this.props;
        history.goBack();
    }

    componentDidMount() {
        this._getAllFunds();
    }

    _getAllFunds = () => {
        apiFunds.getCurrentAsOfDate().then(obj => {
            if (obj && obj.status === 200) {
                const asOfDate = obj.data.as_of_date;
                console.log(asOfDate)
                this.setState({ asOfDate: asOfDate });

                const param = {
                    latest_date: moment(asOfDate).utc().format('YYYY-MM-DD HH:mm:ss'),
                    '$orderby': 'sequence'
                }
                apiFunds.getAllFunds(param).then(res => {
                    if (res && res.status === 200) {
                        this.setState({ funds: res.data });
                    }
                });
            }
        })
    }

    render() {
        const { t, i18n } = this.props;
        const { asOfDate, funds } = this.state;
        return (
            <div>
                <Flex className="main__container head-container">
                    <Flex flex={1} flexDirection="column">
                        <Flex flex={1} justifyContent="start" alignItems="center" className="header-title">
                            <img src={backIcon} className="back-icon" alt="back-icon" onClick={this.back}/>
                            <Flex ml="1rem">
                                {t("Common:MenuItem.fundPrice")}
                            </Flex>
                        </Flex>
                        <Flex flexDirection="column" className="fund-price-page" flex={1} alignItems="center" maxWidth={700}>
                            <FundPriceList t={t} i18n={i18n} asOfDate={asOfDate} funds={funds} />
                        </Flex>
                    </Flex>
                </Flex>
            </div>
        );
    }
}

export default withTranslation()(withRouter(FundPrice));
