// Essential for all components
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import {Button} from "@material-ui/core";
import { Flex } from 'reflexbox'
import {connect} from "react-redux";

import {setBreadcrumb} from "../../Redux/Action/breadcrumbAction";
import {getUserInfo} from "../../Redux/Action/authAction";
import backIcon from "../../images/back.png";
import { apiRewards } from '../../Api/_ApiRewards';
import { apiBadges } from '../../Api/_ApiBadges';
import '../../css/07Reward/_reward.scss';
import MiddleDialog from '../../components/MiddleDialog/MiddleDialog';

const LineBreak = (props) => {
    const { text, fullWidth } = props;
    return ( text ?
        <div className="strike" style={{ marginTop: '1rem', marginBottom: '1rem' }}>
            <span>{text}</span>
        </div> :
        <hr className={fullWidth ? 'full-hr' : ''} style={{ marginTop: '0.5rem', marginBottom: '0.5rem', borderWidth: 1, borderStyle: 'solid', borderColor: '#c0c0c0' }} />
    )
}

const imagePaths = {
    'en-US': {
        iconMPF: require('../../images/Landingpg/Registration-period/logo english.png'),
    },

    'zh-HK': {
        iconMPF: require('../../images/Landingpg/Registration-period/MPFgameLogo.png'),
    }

};

const NoticeDialog = (props) => {
    const { t, open, handleCloseDialog, claimedReward } = props;
    return <MiddleDialog
        open={open}
        title={claimedReward.rewardNum === 7 ? t("Reward:claimRewardMessage3") : `${t("Reward:claimRewardMessage1")} ${claimedReward.rewardNum} ${t("Reward:claimRewardMessage2")}`}
        buttonText={t("Common:Button.close")}
        handleCloseDialog={handleCloseDialog}
        handleClickDialog={handleCloseDialog}
    />
}

class Reward1 extends Component {

    state = {
        badges: 0,
        rewards: [],
        openNoticeDialog: false,
        claiming: false,
        claimedReward: null
    };

    _getUserRewards = (rewardId) => {
        const userRewardParam = {
            user: this.props.auth.userInfo.username,
            reward: rewardId,
            '$expand': 'reward'
        }
        return apiRewards.getUserRewards(userRewardParam).then(res => {
            return res.data
        })
    }

    _getAllBadges = () => {
        const param = {
            '$orderBy': 'sequence',
            '$expand': 'reward'
        }
        return apiBadges.getAllBadges(param).then(obj => {
            if (obj && obj.data.length > 0) {
                return Promise.all(obj.data.map(async item => {
                    const reward = {
                        rewardId: item.reward.reward_id,
                        rewardNum: item.sequence,
                        title: item.reward.name,
                        // url: item.url,
                        // requiredBadgeCount: item.required_badge_count,
                        // voucherCount: item.voucher_to_send,
                        voucherAvailable: item.reward.redeem_count < item.reward.voucher_quota,
                        claimable: false,
                        claimed: false,
                        badgeNameEng: item.description_eng,
                        badgeNameCn: item.description_cn
                    }
                    const userRewards = await Promise.resolve(this._getUserRewards(reward.rewardId));
                    if(userRewards && userRewards.length > 0){
                        const userReward = userRewards[0];
                        reward.claimed = userReward.status === 'claimed' ? true : false;
                        reward.voucherAvailable = ['unclaimed', 'claimed'].includes(userReward.status);
                        reward.userRewardId = userReward.user_reward_id;
                        reward.claimable = userReward.reward.reward_id === reward.rewardId
                    }
                    return reward;
                })
            ).then(allRewards => {
                return this.setState({ rewards: allRewards });
            })}
        });
    }

    _getUserBadges = () => {
        const userBadgeParam = {
            user: this.props.auth.userInfo.username,
        }
        apiBadges.getUserBadges(userBadgeParam).then(obj => {
            this.setState({ badges: obj.data.length })
        })
    }

    componentDidMount() {
        this._getAllBadges();
    }

    _claimReward = (reward) => {
        const body = {
            status: 'claimed',
        }
        return apiRewards.claimReward(reward.userRewardId, body)
    }

    handleCloseNoticeDialog = () => {
        this.setState({ openNoticeDialog: false, claimedReward: null });
    };

    RewardList = (props) => {
        const { i18n } = this.props;
        const { rewards, claiming } = this.state;
        return rewards.map((reward, index) => {
            const { t, i18n } = this.props;
            const claimable = reward.claimable
            const disabled = !reward.voucherAvailable || !claimable || claiming || reward.claimed;
            const badgeName = i18n.language === 'en-US' ? reward.badgeNameEng : reward.badgeNameCn
            return (
                <Flex key={index} justifyContent="flex-start" width="100%" marginBottom="1.5rem">
                    <Flex flexDirection="row" alignItems="center" width="100%">
                        <Flex letterSpacing={i18n.language === 'en-US' ? '' : '0.3rem'} flex={3} className="reward-content" flexDirection="column">
                            <Flex style={{ fontWeight: "bold" }} className="reward-title-font">
                                {reward.rewardNum !== 7 && `${t("Reward:badge")} ${reward.rewardNum}:` } {badgeName}
                            </Flex>
                            <Flex className="reward-title-font">
                                {t("Reward:youHaveWon")}
                            </Flex>
                            <Flex className="reward-title-font">
                                {t(`Reward:${reward.title}`)}
                            </Flex>
                        </Flex>
                        <Flex flex={1} opacity={disabled ? 0.3 : 1} onClick={() => !disabled && this.handleClick(reward, reward.claimed)} style={{ cursor: !disabled && 'pointer' }} className="reward-button" alignItems="center">
                            <Button disabled={disabled} className="primary-button m0 reward-title-font"><span className="reward-title-font">{reward.voucherAvailable ? reward.claimed ? t("Reward:claimed") : t("Reward:claim") : t("Reward:outOfStock")}</span></Button>
                        </Flex>
                    </Flex>
                </Flex>
            )
        });
    }

    // BUTTON FUNCTION

    back = () => {
        const { history } = this.props;
        history.goBack();
    }

    handleClick = (reward, rewardClaimed) => {
        const { i18n } = this.props;
        const { title, voucherCount } = reward;
        if (!rewardClaimed) {
            this.setState({ claiming: true });
            const body = {
                username: this.props.auth.userInfo.username,
                reward_id: reward.rewardId
            }
            apiRewards.claimReward(body).then(obj => {
                if (obj && obj.status === 200) {
                    reward.claimed = true;
                    this.setState({ claiming: false });
                    // this.props.history.push(`/${i18n.language}/reward/2`, { unlockedRewards: earnedRewardCount, rewardTitle: title, url: reward.voucherAvailable ? reward.url : null, voucherCount: voucherCount });
                }
            })
        }
        this.setState({ claimedReward: reward, openNoticeDialog: true });
        // else {
        //     this.props.history.push(`/${i18n.language}/reward/2`, { unlockedRewards: earnedRewardCount, rewardTitle: title, url: reward.voucherAvailable ? reward.url : null, voucherCount: voucherCount });
        // }
    }

    render() {
        const { badges, rewards, openNoticeDialog, claimedReward } = this.state;
        const { t, i18n } = this.props;
        return (                    
            <div className="main__container head-container bg-ranking-badge">
                <Flex flex={1} flexDirection="column">
                    <Flex flex={1} justifyContent="start" alignItems="center" className="header-title">
                        <img src={backIcon} className="back-icon" alt="back-icon" onClick={this.back}/>
                        <Flex ml="0.5rem">
                            {` ${t("Reward:myRewards")} `}
                        </Flex>
                    </Flex>
                    <Flex flex={1} justifyContent="center" alignItems="center" mb="8vw">
                        <img src={imagePaths[i18n.language].iconMPF} className="logo" alt="logo"/>
                    </Flex>
                    {/* <Flex letterSpacing={i18n.language === 'en-US' ? '' : '0.3rem'} flex={1} justifyContent="start" alignItems="center" className="header-reward-content">
                        <Flex ml="1rem">
                            {`${t("Reward:myRewards")} (${badges}/${rewards.length})`}
                        </Flex>
                    </Flex> */}
                    <this.RewardList />
                    {/* <this.RewardList earnedRewardCount={badges} /> */}
                    {claimedReward && <NoticeDialog
                        t={t}
                        open={openNoticeDialog}
                        handleCloseDialog={this.handleCloseNoticeDialog}
                        claimedReward={claimedReward}
                    />}
                </Flex>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    breadcrumbArr: state.breadcrumb.breadcrumbArr
});
const mapDispatchToProps = dispatch => ({
    setBreadcrumbP: data => dispatch(setBreadcrumb(data)),
    getUserInfoP: data => dispatch(getUserInfo(data)),
});
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(Reward1)));
