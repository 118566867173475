import {api} from './_ApiFactoryWithHeader';

export const apiFunds = {

    getAllFunds: (param) => api.get(`funds`, param, null, null),

    getUserFunds: (param) => api.get(`user_funds`, param, null, null),

    deleteAllUserFunds: (param) => api.delete(`user_funds`, param, null, null),

    getFund: (id, param) => api.get(`funds/${id}`, param, null, null),
    
    updateUserFund: (userFundId ,param) =>  api.put(`user_funds/${userFundId}`, param),

    createInstructionHistory: (param) => api.post('instruction_histories', param),
    
    createUserFund: (param) => api.post('user_funds', param),

    deleteUserFund: (param) => api.delete(`user_funds/`, param),
    
    getUserFundTotalBalance: (param) => api.get('user_accounts', param),
    
    getInstructionHistories: (param) => api.get(`instruction_histories`, param, null, null),

    cancelInstructionHistory: (instructionId, param) => api.put(`instruction_histories/${instructionId}`, param, null, null),

    createFund: (body) => api.post(`funds`, body),

    getFundVersion: (param) => api.get(`internal_configurations/3`, param),

    deleteFund: (body) => api.delete(`funds`, body),
    
    updateFund: (fundId, body) => api.put(`funds/${fundId}`, body),

    getFutureAmount: (param) => api.get(`internal_configurations/4`, param),

    getReadyAsOfDate: () => api.get(`internal_configurations/6`),

    getCurrentAsOfDate: () => api.get(`internal_configurations/7`),

    setReadyAsOfDate: (asOfDate) => {
        const body = {
            as_of_date: asOfDate
        }
        return api.put(`internal_configurations/6`, body)
    },

    setCurrentAsOfDate: (asOfDate) => {
        const body = {
            as_of_date: asOfDate
        }
        return api.put(`internal_configurations/7`, body)
    },

};
