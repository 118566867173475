// Essential for all components
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {setBreadcrumb} from "../../Redux/Action/breadcrumbAction";
import { Flex } from 'reflexbox';

import {getUserInfo} from "../../Redux/Action/authAction";
import backIcon from "../../images/back.png";
import RankingTable from '../../components/RankingTable';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Button, Grid, TextField, withStyles } from '@material-ui/core';
import ErrorMessage from '../../components/100Include/ErrorMessage';
import { apiEmailSupport } from '../../Api/_ApiSupportEmail';
import { get } from 'lodash-es';

const CustomTextField = withStyles({
    root: {        
        '& label.Mui-focused': {
            color: '#717171',
        },
    },
})((props) => <TextField {...props} />);

class EmailSupport extends Component {

    back = () => {
        const { history } = this.props;
        history.goBack();
    }
    

    formEmail = ({ values, errors, touched, handleChange, setFieldValue }) => {
        const { t } = this.props;
        return (
            <Form style={{ marginTop: '1.5rem' }}>
                <Flex fontSize="1.2rem" mb="1rem">
                    {t("EmailSupport:enquiries")}
                </Flex>
                <Field name="enquiries">
                    {({
                        field,
                        form: { touched, errors },
                        meta,
                    }) => (
                        <TextField
                            style={{ border: '1px solid black', padding: '0.8rem', borderRadius: 5, height: '30vh', overflowY: 'scroll' }}
                            multiline
                            fullWidth
                            placeholder={t("EmailSupport:typeYourEnquiryHere")}
                            {...field}
                        />
                    )}
                </Field>
                <Flex justifyContent="center" mt="1rem">
                    <Flex flex={1} className="button-wrapper" mt="2rem" maxWidth={700}>
                        <Button type="submit" className="primary-button" style={{ margin: 0 }}>{t("Common:Button.submit")}</Button>
                    </Flex>
                </Flex>
            </Form>
        )
    }

    _sendSupportEmail = (values) => {
        const { auth, t, i18n, history } = this.props;
        const username = get(auth, ['userInfo', 'username']);
        const email = get(auth, ['userInfo', 'email']);
        const body = {
            enquiries: values.enquiries,
            username: username,
            email: email,
        }
        apiEmailSupport.sendSupportEmail(body).then(obj => {
            if (obj && obj.status === 204) {
                alert(t("EmailSupport:yourEnquiriesHasBeenSuccessfullySent"))
                history.push('/' + i18n.language + '/index');
            }
        })
    }

    render() {
        const { t } = this.props;
        const Schema = Yup.object().shape({
            enquiries: Yup.string().required(),
        })
        return (                    
            <div>
                <Flex className="main__container head-container">
                    <Flex flex={1} flexDirection="column">
                        <Flex flex={1} justifyContent="start" alignItems="center" className="header-title">
                            <img src={backIcon} className="back-icon" alt="back-icon" onClick={this.back}/>
                            <Flex ml="1rem">
                                {t("EmailSupport:support")} ({t("EmailSupport:emailForEnquiries")})
                            </Flex>
                        </Flex>
                        <Formik
                            initialValues={{
                                enquiries: ''
                            }}
                            validationSchema={Schema}
                            onSubmit={this._sendSupportEmail}
                            component={this.formEmail}
                        />
                    </Flex>
                </Flex>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    breadcrumbArr: state.breadcrumb.breadcrumbArr
});
const mapDispatchToProps = dispatch => ({
    setBreadcrumbP: data => dispatch(setBreadcrumb(data)),
    getUserInfoP: data => dispatch(getUserInfo(data)),
});
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(EmailSupport)));