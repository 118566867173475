import React from 'react';
import PropTypes from 'prop-types';
import {Button} from "@material-ui/core";

export default class EnhancedTablePagination extends React.Component {
    handleFirstPageButtonClick = event => {
        this.props.onChangePage(event, 0);
    };

    handleBackButtonClick = event => {
        this.props.onChangePage(event, this.props.page - 1);
    };

    handleNextButtonClick = event => {
        this.props.onChangePage(event, this.props.page + 1);
    };

    handleLastPageButtonClick = event => {
        this.props.onChangePage(
            event,
            Math.max(0, Math.ceil(this.props.count / parseInt(this.props.rowsPerPage)) - 1),
        );
    };

    render() {
        const { classes, count, page, rowsPerPage } = this.props;
        return (
            <div className={classes.root}>
                <div className="enhanced-table-pagination">
                    <Button
                        variant="contained"
                        onClick={this.handleFirstPageButtonClick}
                        disabled={page === 0}
                        className="pagination-button"
                    >
                        First
                    </Button>
                    <Button
                        variant="contained"
                        onClick={this.handleBackButtonClick}
                        disabled={page === 0}
                        className="pagination-button"
                    >
                        Previous
                    </Button>
                    <span className="pagination-location">Page {page + 1} of {Math.ceil(count / parseInt(rowsPerPage))}</span>
                    <Button
                        variant="contained"
                        onClick={this.handleNextButtonClick}
                        disabled={page >= Math.ceil(count /parseInt(rowsPerPage)) - 1}
                        className="pagination-button"
                    >
                        Next
                    </Button>
                    <Button
                        variant="contained"
                        onClick={this.handleLastPageButtonClick}
                        disabled={page >= Math.ceil(count /parseInt(rowsPerPage)) - 1}
                        className="pagination-button"
                    >
                        Last
                    </Button>
                </div>
            </div>
        );
    }
}
EnhancedTablePagination.propTypes = {
    classes: PropTypes.object.isRequired,
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    theme: PropTypes.object.isRequired,
};
