// Essential for all components
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {setBreadcrumb} from "../../Redux/Action/breadcrumbAction";
import { Flex } from 'reflexbox'

import {getUserInfo} from "../../Redux/Action/authAction";
import backIcon from "../../images/back.png"

const engDescription = [
    <div>Participants can log into their game account from 18 September 2023 to start the game and accomplish different missions.</div>,
    <div>Starting with a virtual MPF assets of HK$50,000, each participant’s game account will then be credited a virtual MPF contributions of HK$3,000 each month during the game period from 18 September to 17 December 2023. The amount will be invested in the Default Investment Strategy by default. Participants are free to select/switch their funds.</div>,
    <div>Participants who have successfully accomplished specific missions during the game period can earn badges to receive virtual MPF assets and different reward. Seize the chance to collect badges of the <span style={{ color: '#0000c1' }}>first 4 missions</span> on or <span style={{ color: '#00a759' }}>before 6 November 2023</span> to enter the lucky draw and stand a chance to win <span style={{ color: '#00a759' }}>Prizes valued over HK$200,000</span>!</div>
]
const cnDescription = [
    <div>參賽者可在2023年9月18日登入其遊戲帳戶開始遊戲，挑戰不同的任務。</div>,
    <div>每位參賽者的遊戲帳戶以虛擬強積金資產港幣50,000元開始，之後在2023年9月18日至12月17日的遊戲期間每月存入港幣3,000元的虛擬強積金供款，並預設投資於「預設投資策略」。參賽者可自由選擇/轉換基金。</div>,
    <div>參賽者於遊戲期間成功完成指定任務，可賺取徽章以領取虛擬強積金資產及獎賞。把握機會於<span style={{ color: '#00a759' }}>2023年11月6日或之前</span>集齊<span style={{ color: '#0000c1' }}>首4個任務</span>的徽章即可參加抽獎，有機會贏取<span style={{ color: '#00a759' }}>總值超過HK$200,000的獎品！</span></div>
]

const engEndingDescription = <span>At the end of the game, the <span style={{ color: '#00a759' }}>top 5 participants</span> with the <b>highest account balances</b> will have a chance to win grand prizes!</span>;
const cnEndingDescription = '在遊戲完結時，帳戶結餘最多的首5名參賽者有機會贏取大獎！';

class HowToPlay extends Component {

    back = () => {
        const { history } = this.props;
        history.goBack();
    }

    render() {
        const { t, i18n } = this.props;
        const description = i18n.language === 'en-US' ? engDescription : cnDescription;
        const endingDescription = i18n.language === 'en-US' ? engEndingDescription : cnEndingDescription;
        return (                    
            <div>
                <Flex className="main__container head-container">
                    <Flex flex={1} flexDirection="column">
                        <Flex flex={1} justifyContent="start" alignItems="center" className="header-title">
                            <img src={backIcon} className="back-icon" alt="back-icon" onClick={this.back}/>
                            <Flex ml="1rem">
                                {t("HowToPlay:howToPlay")}
                            </Flex>
                        </Flex>
                        <Flex flex={1} mt="2rem" justifyContent="center" className="video-container">
                            <Flex flex={1} className="video">    
                                <iframe width="100%" height="100%" src="https://www.youtube.com/embed/SydCBhbLyjg" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                            </Flex>
                        </Flex>
                        <Flex fontSize="1.3rem" mt="2rem">
                            {t("HowToPlay:descriptionAboutTheRulesOfTheGame")}
                        </Flex>
                        <Flex mt="2rem" flexDirection="column" fontSize="1.2rem">
                            {description.map((text, index) => <Flex mb="1rem" flexDirection="row">
                                <Flex mr="0.5rem" minWidth={20}>{index+1}.</Flex>
                                <Flex>{text}</Flex>
                            </Flex>)}
                        </Flex>
                        <Flex mt="1rem" fontSize="1.2rem">{endingDescription}</Flex>
                    </Flex>
                </Flex>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    breadcrumbArr: state.breadcrumb.breadcrumbArr
});
const mapDispatchToProps = dispatch => ({
    setBreadcrumbP: data => dispatch(setBreadcrumb(data)),
    getUserInfoP: data => dispatch(getUserInfo(data)),
});
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(HowToPlay)));