import * as MessageActionTypes from '../Constant/ActionType';

export const setMessages = (messageArr) => ({
    type: MessageActionTypes.SET_MESSAGE,
    messageArr,
});

export const addMessage = (messageItem) => ({
    type: MessageActionTypes.ADD_MESSAGE,
    messageItem,
});

export const getMessages = () => ({
    type: MessageActionTypes.GET_MESSAGE,
});