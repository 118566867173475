// Essential for all components
import React, {Component} from 'react';
import {Trans, withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import { Flex } from 'reflexbox';
import moment from 'moment';

import {setBreadcrumb} from "../../Redux/Action/breadcrumbAction";
import {getUserInfo} from "../../Redux/Action/authAction";
import backIcon from "../../images/back.png";
import { apiBadges } from '../../Api/_ApiBadges';
import { apiRewards } from '../../Api/_ApiRewards';
import MiddleDialog from '../../components/MiddleDialog/MiddleDialog';
import iconMission1 from "../../images/Landingpg/My badge/badge1.png";
import iconMission2 from "../../images/Landingpg/My badge/badge2.png";
import iconMission3 from "../../images/Landingpg/My badge/badge3.png";
import iconMission4 from "../../images/Landingpg/My badge/badge4.png";
import iconMission5 from "../../images/Landingpg/My badge/badge5.png";
import iconMission6 from "../../images/Landingpg/My badge/badge6.png";
import bgImage from "../../images/Landingpg/My badge/background .png";
import bgImages from "../../images/badge-6.png";
import '../../css/11badge/_badge.scss';

const imagePaths = {
    'en-US': {
        iconMPF: require('../../images/Landingpg/Registration-period/logo english.png'),
    },
    'zh-HK': {
        iconMPF: require('../../images/Landingpg/Registration-period/MPFgameLogo.png'),
    }
};

const BadgeList = props => {
    const { t, badges, i18n } = props;
    const isChinese = i18n.language === 'zh-HK'
    const dateFormat = isChinese ? 'YYYY年M月D日' : 'D MMM YYYY';
    const locale = isChinese ? 'zh-hk' : 'en';

    return badges.map((badge, index) => {
        return (
            <Flex key={index} alignItems="center" ml="-1rem" style={{ position: 'relative', opacity: badge.wonOn ? 1 : 0.5 }}>
                <img src={require(`../../images/${badge.imageUrl}`)} width="120px" alt="badge" style={{ objectFit: 'contain' }} />
                <Flex flexDirection="column">
                    <Flex fontSize="1.1rem">
                        {t(`Badge:${badge.title}`)}
                    </Flex>
                    <Flex color="#717171" fontSize="0.85rem">
                        {t(`Badge:${badge.description}`)}
                    </Flex>
                    {badge.wonOn && <Flex fontSize="0.95rem">
                        {`${t("Badge:wonOn")} ${moment(badge.wonOn).locale(locale).format(dateFormat)}`}
                    </Flex>}
                </Flex>
            </Flex>
        )
    });
}

const BadgeRule = (props) => {
    const { t, i18n, badges } = props;    
    return (
        <Flex maxWidth={600} letterSpacing={i18n.language === 'en-US' ? '' : '0.2rem'} className="font-container" width="100%">
            <Flex width="100%" flexDirection="column">
                {badges.map((badge, index) => {
                    const description = i18n.language === 'en-US' ? badge.descriptionEng : badge.descriptionCn;
                    return badge.sequence !== 7 && (
                        <Flex key={index} mb="2rem"  width="100%" flexDirection="column">
                            <Flex opacity={badge.wonOn ? 1 : 0.5} alignItems="center">
                                <Flex width="70%" alignItems="center" color="#4AB079">
                                    <Flex width="100%" style={{flexWrap: "wrap"}}>
                                        <Flex width="100%" >
                                            <Flex style={{ fontWeight: "bold", color: "#4AB079"}}>{`${t("MiniGame:mission")} ${badge.sequence}: ${description}`}</Flex>
                                        </Flex>
                                        {/* <Flex width="100%">
                                            {t("Badge:description-1")}
                                        </Flex>
                                        <Flex width="100%">
                                            {t("Badge:detail-1")}
                                        </Flex> */}
                                    </Flex>
                                </Flex>
                                <Flex width="30%" alignItems="center">
                                    <img className="badge-icon" src={badge.imageUrl ? require(`../../images/${badge.imageUrl}`) : null} alt="badge" />
                                </Flex>
                            </Flex>
                            {badge.sequence === 4 && <Flex width="100%" justifyContent="center" alignItems="center" style={{
                                backgroundColor: "#151D29",
                                borderRadius: "5rem",
                                color: "white",
                                padding: "1rem 0.5rem",
                                textAlign: "center",
                                fontSize: "1rem"
                            }}>
                                <div className="black-content" dangerouslySetInnerHTML={{ __html: t("Badge:note-1") }} />
                            </Flex>}
                            {badge.sequence === 6 && <Flex width="100%" justifyContent="center" alignItems="center" style={{
                                backgroundColor: "#151D29",
                                borderRadius: "5rem",
                                color: "white",
                                padding: "1rem 0.5rem",
                                textAlign: "center",
                                fontSize: "1rem"
                            }}>
                                <div className="black-content" dangerouslySetInnerHTML={{ __html: t("Badge:note-2") }} />
                            </Flex>}
                            
                        </Flex>
                    )
                })}
            </Flex>

        </Flex>
    )

}

class Badge extends Component {

    state = {
        badges: [],
        openDialog: false
    };

    dynamicSort(property, order) {
        const value = order === 'asc' ? 1 : -1;
        let sortOrder = 1;
        if(property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a,b) {
            /* next line works with strings and numbers,
            * and you may want to customize it to your needs
            */
            let result = (a[property] < b[property]) ? -value : (a[property] > b[property]) ? value : 0;
            return result * sortOrder;
        }
    }

    _getAllBadges = () => {
        const allBadgeParams = {
            '$expand': 'reward',
            '$orderby': 'sequence asc'
        }

        const param = {
            '$expand': 'badge',
            user: this.props.auth.userInfo.username,
        }
        return apiBadges.getAllBadges(allBadgeParams).then(obj => {
            return apiBadges.getUserBadges(param).then(res => {
                const allBadges = obj.data.map(item => {
                    const badge = {
                        // title: item.title,
                        descriptionCn: item.description_cn,
                        descriptionEng: item.description_eng,
                        sequence: item.sequence,
                        imageUrl: item.image_url,
                    }
                    res.data.forEach(userBadge => {
                        if (userBadge.badge.badge_id === item.badge_id && userBadge.won_time) {
                            badge.wonOn = userBadge.won_time
                        }
                    })
                    return badge;
                });
                const completedBadges = [];
                const uncompletedBadges = [];
                allBadges.forEach(badge => {
                    if (badge.wonOn) {
                        completedBadges.push(badge)
                    } else {
                        uncompletedBadges.push(badge)
                    }
                });
                // completedBadges.sort(this.dynamicSort("wonOn", "desc"))
                // uncompletedBadges.sort(this.dynamicSort("sequence", "asc"))
                // this.setState({ badges: [...uncompletedBadges, ...completedBadges] });
                this.setState({ badges: allBadges });
            })
        });
    }

    handleCloseDialog = () => {
        this.setState({ openDialog: false });
    }

    handleClickDialog = () => {
        const { history, i18n } = this.props;
        history.push('/' + i18n.language + '/reward/1');
    }

    _getUserRewards = () => {
        const userRewardParam = {
            user: this.props.auth.userInfo.username,
            status: 'unclaimed',
            '$expand': 'reward'
        }
        return apiRewards.getUserRewards(userRewardParam).then(res => {
            if (res && res.data.length > 0) {
                this.setState({ openDialog: true });
            }
        })
    }

    componentDidMount() {
        Promise.resolve(this._getAllBadges()).then(() => {
            this._getUserRewards();
        });
    }

    // BUTTON FUNCTION

    back = () => {
        const { history } = this.props;
        history.goBack();
    }

    render() {
        const { t, i18n } = this.props;
        const { badges, openDialog } = this.state;
        let collectedBadge = 0;
        badges.forEach(badge => { if (badge.wonOn) collectedBadge++; });
        return (
            <div>
                <Flex className="main__container bg-ranking-badge head-container">
                    <Flex flexDirection="column" flex={1}>
                        <Flex flex={1} justifyContent="start" alignItems="center" className="header-title">
                            <img src={backIcon} className="back-icon" alt="back-icon" onClick={this.back}/>
                            <Flex ml="0.5rem">
                                {/*{t("Badge:myBadges")} ({collectedBadge}/{badges.length})*/}
                                {t("Badge:myBadges")}
                            </Flex>
                        </Flex>
                        <Flex flex={1} width="100%" justifyContent="center" alignItems="center">
                            <img src={imagePaths[i18n.language].iconMPF} className="logo" alt="logo"/>
                        </Flex>
                        {/*<Flex mt="1rem" mx="5rem" flexDirection="column">*/}
                        {/*    <BadgeList t={t} badges={badges} i18n={i18n} />*/}
                        {/*</Flex>*/}
                        <Flex mt="1rem" width="100%" padding="1rem" alignItems="center" flexDirection="column">
                            <BadgeRule t={t} i18n={i18n} badges={badges} />
                        </Flex>
                    </Flex>
                </Flex>
                <MiddleDialog open={openDialog} title={t("Reward:youHaveUnclaimedRewards")} description={t("Reward:clickOkToClaimNow")} handleClickDialog={this.handleClickDialog} handleCloseDialog={this.handleCloseDialog} />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    breadcrumbArr: state.breadcrumb.breadcrumbArr
});
const mapDispatchToProps = dispatch => ({
    setBreadcrumbP: data => dispatch(setBreadcrumb(data)),
    getUserInfoP: data => dispatch(getUserInfo(data)),
});
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(Badge)));
