import * as PaginatorActionTypes from '../Constant/ActionType';
import {updateObject} from "../../utils/Utility";

const initialState = {
    currentPaginator: [],
};

const paginatorReducer = (state = initialState, action) => {
    switch (action.type) {
        case PaginatorActionTypes.SET_PAGINATOR:
            if(state.currentPaginator.length === 0) {
                return updateObject( state, { currentPaginator: [action.currentPaginatorItem] } );
            } else {
                const existItem = state.currentPaginator.find(item => item.page_id === action.currentPaginatorItem.page_id);
                let newPaginatorData = [];
                if(existItem) {
                    newPaginatorData = state.currentPaginator.map(p => {
                        if(p.page_id === action.currentPaginatorItem.page_id) {
                            return action.currentPaginatorItem;
                        } else {
                            return p;
                        }
                    })
                } else {
                    newPaginatorData = state.currentPaginator.concat([action.currentPaginatorItem]);
                }
                return updateObject( state, { currentPaginator: newPaginatorData} );
            }
        case PaginatorActionTypes.GET_PAGINATOR:
            return state;
        default:
            return state;
    }
};

export default paginatorReducer;