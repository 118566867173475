// Essential for all components
import React, {Component, useState} from 'react';
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {setBreadcrumb} from "../../Redux/Action/breadcrumbAction";
import { Flex } from 'reflexbox';
import moment from 'moment';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { PolarArea } from 'react-chartjs-2';
import ScrollContainer from 'react-indiana-drag-scroll'
import 'moment/locale/zh-hk';

import { apiUsers } from '../../Api/_ApiUsers';
import { apiUserAccount } from '../../Api/_ApiUserAccount';
import { apiFunds } from '../../Api/_ApiFunds';
import { apiTask } from '../../Api/_ApiTask';
import { apiMiniGames } from '../../Api/_ApiMiniGames';
import { apiNews } from '../../Api/_ApiNews';
import {getUserInfo} from "../../Redux/Action/authAction";
import RankingTable from '../../components/RankingTable';
import testNewsImg from "../../images/test-news.png";
import manageFundsIcon from '../../images/Landingpg/Icon for menu/Manage Fund.png';
import miniGameIcon from '../../images/Landingpg/Icon for menu/Mini Game.png';
import newsEducationIcon from '../../images/news-icon.png';
import fundFundIcon from '../../images/Landingpg/Icon for menu/Fund Price.png';
import rankingIcon from '../../images/Landingpg/Icon for menu/Ranking.png';
import badgeIcon from '../../images/Landingpg/Icon for menu/My Badges.png';
import rewardIcon from '../../images/Landingpg/Icon for menu/My Reward.png';
import dropDown from '../../images/dropDown.png';
import dropUp from '../../images/dropUp.png';
import forward from '../../images/forward.png';
import { get } from 'lodash-es';
import '../../css/00Home/_ladingAfterLogin.scss';

import * as echarts from 'echarts';
import 'echarts/lib/chart/pie';
import 'echarts/lib/component/legend';
import 'echarts/lib/component/title';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/toolbox';
import LandingExtend from "../../components/LandingExtend/landingExtend";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import {Drawer, styled} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { apiTradeHistories } from '../../Api/_ApiTradeHistories';

const imagePaths = {
    'en-US': {
        manageButton: require('../../images/Manager ENG.png'),
    },

    'zh-HK': {
        manageButton: require('../../images/Manager CHI.png'),
    }

};


ChartJS.register(ArcElement, Tooltip, Legend);

const plugins = [
    {
        beforeDraw: (chart) => {
            const datasetMeta = chart.getDatasetMeta(0);
            const innerRadius = datasetMeta.controller.innerRadius;
            const outerRadius = datasetMeta.controller.outerRadius;
            const heightOfItem = outerRadius - innerRadius;

            const countOfData = chart.getDatasetMeta(0).data.length;
            const additionalRadius = Math.floor(heightOfItem / countOfData * 0.4);

            const weightsMap = datasetMeta.data
                .map(v => v.circumference)
                .sort((a, b) => a - b)
                .reduce((a, c, ci) => {
                    a.set(c, ci + 1);
                    return a;
                }, new Map());

            datasetMeta.data.forEach((dataItem, index) => {
                const weight = weightsMap.get(dataItem.circumference);
                const dataLength = datasetMeta.data.length;
                if (dataLength < 6) {
                    dataItem.outerRadius = (innerRadius + additionalRadius * weight) + 30;
                } else {
                    if (index < dataLength - 1) {
                        dataItem.outerRadius = (innerRadius + additionalRadius * weight) + 40 ;
                    } else {
                        dataItem.outerRadius = (innerRadius + additionalRadius ) + 30;
                    }
                }
            });
        }
    }
]

const dataColor = ['#00a759', '#0000c0', '#ff7769', '#8e8fa1', '#282b3e'];
const remainingDataColor = '#ace5c4';

const options = {
    plugins: {
        legend: {
            display: false,
        },
        tooltip: {
            enabled: false,
            // xAlign: 'center',
            // callbacks: {
            //     label: function (context) {
            //         return context.label;
            //     },
            // }
        }
    },
    responsive: true,
    maintainAspectRatio: false
};

const iconList = {
    'funds': manageFundsIcon,
    'games': miniGameIcon,
    'news': newsEducationIcon,
    'fund': fundFundIcon,
    'ranking': rankingIcon,
    'badges': badgeIcon,
    'rewards': rewardIcon,
}

const quickLinks = [
    {
        title: 'manageFunds',
        icon: 'funds',
        page: 'manage-funds/0',
    },
    {
        title: 'miniGames',
        icon: 'games',
        page: 'mini-game',
    },
    // {
    //     title: 'fundPrice',
    //     icon: 'fund',
    //     page: 'news',
    // },
    {
        title: 'fundPrice',
        icon: 'fund',
        page: 'fund-price',
    },
    {
        title: 'myRanking',
        icon: 'ranking',
        page: 'ranking',
    },
    {
        title: 'myBadges',
        icon: 'badges',
        page: 'badge',
    },
    {
        title: 'myRewards',
        icon: 'rewards',
        page: 'reward/1',
    },
]

const HorizontalList = (props) => {
    const { itemList, i18n, history, t, locale } = props;
    const periodStartFormat = i18n.language === 'zh-HK' ? 'YYYY年M月D日' : 'MMMM d'
    const periodEndFormat = i18n.language === 'zh-HK' ? 'M月D日' : 'MMMM d, YYYY'
    const handleItemClick = (event, page) => {
        history.push('/' + i18n.language + `/${page}`);
    }

    return (
        <ScrollContainer style={{ display: 'flex', flexDirection: 'row' }}>
            {itemList.map((item, index) => {
                const title = i18n.language === 'en-US' ? item.titleEng : item.titleCn;
                // if (index < 5) {
                    return <div key={index} className="horizontal-item" onClick={(e)=>{handleItemClick(e, item.page)}} style={{ cursor: 'pointer' }}>
                        {item.videoUrl ? 
                            <iframe width="100%" height="140px" src={item.videoUrl} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe> :
                            <img src={testNewsImg} width="100%" height="140px" alt="img" />
                        }
                        <Flex flexDirection="column" paddingY="1rem" paddingX="0.5rem" textAlign="start">
                            <Flex fontSize="1rem">{t("MiniGame:mission")} {item.sequence}: {title}</Flex>
                            {/* <Flex fontSize="0.9rem">{`${t("Landing:period")}: ${moment(item.startDate).locale(locale).format(periodStartFormat)} - ${moment(item.endDate).locale(locale).format(periodEndFormat)} (${t('Common:General.hkt')})`}</Flex> */}
                        </Flex>
                    </div>
                // } else {
                //     return null
                // }
            })}
        </ScrollContainer>
    )
}

const QuickLinks = (props) => {
    const { t, onClick } = props;
    return (
        <div className="quick-link-container">
            {quickLinks.map((link, index) => (
                <div key={index} className="quick-link-item" onClick={()=>onClick(link.page)}>
                    <img src={iconList[link.icon]} width={45} height={45} alt="icon" />
                    <div style={{ width: '100%', marginTop: '0.5rem', fontSize: '1rem' }}>{t(`Common:MenuItem.${link.title}`)}</div>
                </div>
            ))}
        </div>
    )
}

const GreenLineFlex = styled(Flex)({
    'text-decoration': 'underline',
    'text-decoration-color': '#01a758',
    'text-decoration-thickness': '2px'
})

class Landing extends Component {

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
        this.getUserFunds().then(userFunds => {
            this.getUserFutureContributionAllocation();
            this.settingPieChartOption(userFunds);
            this.getUserDetail();
            this.getUserTotalBalance();
            this.calculateBalanceIncrement();
            this.getInfoItems();
            this.getAsOfDate();
        });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    getUserFutureContributionAllocation = () => {
        const params = {
            user: this.props.auth.userInfo.username,
            fund_type: 'futureInvestment',
            'status[in]': 'success,completed',
            '$orderby': 'createddate desc',
            '$expand': 'instruction_histories/switch_in_fund'
        }
        apiTradeHistories.getUserTradeHistories(params).then(obj => {
            const DISFundHistories = [];
            if (obj && obj.status === 200 && obj.data.length > 0) {
                const instructionHistories = obj.data[0].instruction_histories;
                const filteredHistories = instructionHistories.filter(history => {
                    if (history.switch_in_fund.fund_code.includes('DIS')) {
                        DISFundHistories.push(history);
                    }
                    return !history.switch_in_fund.fund_code.includes('DIS');
                })
                
                const DISFundHistory = {
                    switch_in_percentage: 0,
                    switch_in_fund: {
                        fund_name: 'DefaultInvestmentStrategy',
                        fund_code: 'DIS148 / DIS149'
                    }
                }
                DISFundHistories.forEach(DISHistory => {
                    DISFundHistory['switch_in_percentage'] += DISHistory.switch_in_percentage;
                })
                if (DISFundHistory.switch_in_percentage > 0) {
                    filteredHistories.push(DISFundHistory);
                }
                this.setState({ instructionHistories: filteredHistories });
            }
        })
    }

    handleResize = () => {
        this.setState({
            windowWidth: window.innerWidth,
        });
    };

    settingPieChartOption(userFunds) {
        const chartElement = document.getElementById('pieChart');
        const { i18n } = this.props;
        let { windowWidth } = this.state;
        if (!windowWidth) {
            windowWidth = window.innerWidth;
        }

        const currentURL = window.location.href;

        const data = userFunds;

        if (chartElement) {
            const chart = echarts.init(chartElement);
            let outerRadius;
            let innerRadius;

            if (windowWidth <= 540) {
                outerRadius = 130;
                innerRadius = 80;
            } else if (windowWidth > 540 && windowWidth <= 767 ) {
                outerRadius = 150;
                innerRadius = 90;
            } else if (windowWidth > 767 && windowWidth <= 992 ) {
                outerRadius = 160;
                innerRadius = 90;
            } else {
                outerRadius = 180;
                innerRadius = 100;
            }

            const options = {
                tooltip: {
                    trigger: `${windowWidth >= 992 ? '' : 'item'}`,
                    position: `${windowWidth >= 992 ? '' : 'center'}`
                },
                toolbox: {
                    show: false,
                    feature: {
                        mark: { show: true },
                        dataView: { show: true, readOnly: false },
                        restore: { show: true },
                        saveAsImage: { show: true },
                    },
                },
                series: [
                    {
                        // name: 'Nightingale Chart',
                        type: 'pie',
                        radius: [innerRadius, outerRadius],
                        center: ['50%', '50%'],
                        label: {
                            show: windowWidth >= 992,
                            formatter: function (params) {
                                const value2 = params.value;
                                const nameText = `{name|${params.name}}`;
                                const colorBox = `{colorBox|}`;
                                const percentageText = `{percent|${params.percent}%}`;
                                const valueText = `{percentText|${currentURL.includes('en-US')  ? 'HKD' : '港元'} ${value2}}`;
                                return `${nameText}\n\n${colorBox} ${percentageText} ${valueText}`;
                            },
                            rich: {
                                name: {
                                    fontSize: 14,
                                    fontWeight: '600',
                                    color: '#000000',
                                    align: 'left',
                                },
                                colorBox: {
                                    width: 10,
                                    height: 10,
                                    borderRadius: 2,
                                    padding: [2, 4],
                                    align: 'left',
                                },
                                percent: {
                                    fontSize: 14,
                                    fontWeight: '600',
                                    color: 'black',
                                    align: 'left',
                                },
                                percentText: {
                                    fontSize: 14,
                                    color: 'gray',
                                    align: 'left',
                                }
                            },
                        },
                        data: data.map((item,index) => ({
                            value: item.value,
                            name: item.name,
                            color: item.color,
                            itemStyle: {
                                color: item.color, // Set the color for each segment
                            },
                            label: {
                                rich: {
                                    colorBox: {
                                        backgroundColor: item.color, // Set the backgroundColor based on the segment's color
                                    },
                                },
                            },
                        })),

                        roseType: 'radius',
                    },
                ],
            };
            chart.setOption(options);
        }
    }

    state = {
        user: {
            nickname: '',
            display_name: '',
            last_logged_in: ''
        },
        totalFundBalance: 0,
        dataLabels: null,
        dataValues: null,
        dataColors: null,
        netContributions: 0,
        accountGain: 0,
        infoItems: [],
        asOfDate: null,
        userOverallRanking: '- ',
        totalUserCount: '',
        userFunds: [],
        instructionHistories: []
    };


    updateUserOverallRanking = (rank, totalUser) => {
        this.setState({ userOverallRanking: rank, totalUserCount: totalUser });
    }

    data = {
        labels: [],
        datasets: [{
            data: [],
            backgroundColor: [],
            borderWidth: 0,
            cutout: '65%',
        }],
    };

    getAsOfDate = () => {
        apiFunds.getCurrentAsOfDate().then(obj => {
            if (obj && obj.status === 200) {
                const asOfDate = obj.data.as_of_date;
                this.setState({ asOfDate: asOfDate });
            }
        })
    }

    getAllMiniGames = () => {
        const miniGamesParam = {
            is_playable: true,
            '$orderby': 'sequence desc'
        }
        return apiMiniGames.getAllMiniGames(miniGamesParam).then(obj => {
            if (obj && obj.status === 200) {
                return obj.data.map(item => {
                    const infoItem = {
                        id: item.mini_game_id,
                        sequence: item.sequence,
                        titleEng: item.title_eng,
                        titleCn: item.title_cn,
                        daysLeft: moment(item.end_date).diff(moment(), 'days'),
                        howToPlay: true,
                        startDate: item.start_date,
                        endDate: item.end_date,
                        page: 'mini-game',
                        videoUrl: item.video_url,
                        createdDate: item.createddate
                    }
                    return infoItem;
                })
            }
        })
    }

    getAllNews = () => {
        const params = {
            '$orderby': 'createddate desc',
            '$top': 5
        }
        return apiNews.getAllNews(params).then(obj => {
            if (obj && obj.status === 200) {
                return obj.data.map(item => {
                    const infoItem = {
                        id: item.news_id,
                        title: item.title,
                        daysLeft: moment(item.end_time).diff(moment(), 'days'),
                        howToPlay: false,
                        startDate: item.start_time,
                        endDate: item.end_time,
                        page: 'news',
                        videoUrl: item.video_url,
                        createdDate: item.createddate
                    }
                    return infoItem
                })
            }
        })
    }

    dynamicSort(property) {
        let sortOrder = 1;
        if(property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a,b) {
            /* next line works with strings and numbers, 
            * and you may want to customize it to your needs
            */
            let result = (a[property] < b[property]) ? 1 : (a[property] > b[property]) ? -1 : 0;
            return result * sortOrder;
        }
    }

    getInfoItems = () => {
        this.getAllMiniGames().then(data => {
            if (data) {   
                this.setState({ infoItems: [...data] });
            }
        })
    }

    calculateBalanceIncrement = () => {
        Promise.resolve(this.getFundVersion()).then(versionNumber => {
            const username = this.props.auth.userInfo.username;
            apiUserAccount.getUserAccount(username, versionNumber).then(obj => {
                if (obj && obj.status === 200 && obj.data.length > 0) {
                    const totalBalance = obj.data[0].total_fund_balance;
                    const contributions = [obj.data[0].contribution_1, obj.data[0].contribution_2];
    
                    apiUserAccount.getInitialContribution().then(res => {
                        const initialContribution = res.data.int_value;
                        let monthContributionSum = 0;
                        contributions.forEach(contribution => {
                            if (contribution) {
                                monthContributionSum += contribution;
                            }
                        })
                        const netContributions = initialContribution + monthContributionSum;
                        const accountGain = totalBalance - netContributions;
    
                        this.setState({ netContributions: netContributions, accountGain: accountGain });
                    })
                }
            })
        })
    }

    getFundVersion = () => {
        return apiFunds.getFundVersion().then(obj => {
            if (obj && obj.status === 200) {
                return obj.data.int_value
            }
        });
    }

    getUserFunds = () => {
        const { t } = this.props;
        const body = {
            user: this.props.auth.userInfo.username,
            '$orderby': 'amount desc',
            '$expand': 'fund'
        }
        return apiFunds.getUserFunds(body).then(obj => {
            if (obj && obj.status === 200 && !obj.error && obj.data.length > 0) {
                const labels = [];
                const values = [];
                const colors = [];
                const userFunds = [];
                const otherFund = {
                    amount: 0,
                    name: t(`Fund:other`),
                    color: remainingDataColor,
                    fund: {
                        fund_name: t(`Fund:other`)
                    }
                }
                obj.data.forEach((item, index) => {
                    if (index < 5) {
                        labels.push(t(`Fund:${item.fund.fund_name}`));
                        values.push(item.amount);
                        colors.push(dataColor[index]);
                        item.fund['fund_name'] = t(`Fund:${item.fund.fund_name}`);
                        item.color = dataColor[index];
                        userFunds.push(item);
                    } else {
                        otherFund.amount += item.amount;
                    }
                })
                if (otherFund.amount > 0) {
                    labels.push(otherFund.name);
                    values.push(otherFund.amount);
                    colors.push(otherFund.color);
                    userFunds.push(otherFund);
                }
                return this.setState({ dataLabels: labels, dataValues: values, dataColors: colors, userFunds: userFunds });
            }
        })
    }

    getUserTotalBalance = () => {
        Promise.resolve(this.getFundVersion()).then(versionNumber => {
            const username = this.props.auth.userInfo.username;
            apiUserAccount.getUserAccount(username, versionNumber).then(obj => {
                if (obj.data.length > 0) {
                    this.setState({ totalFundBalance: obj.data[0].total_fund_balance });
                }
            })
        });
    }

    prepareData = data => {
        const item = {...data};
        return item;
    };

    getUserDetail = () => {
        apiUsers.getUserDetail(this.props.auth.userInfo.username, null).then(obj => {
            if (obj.data && obj.status === 200) {
                const userDetail = obj.data;
                this.setState({
                    user: this.prepareData(userDetail)
                });
            }
        })
    }

    handleQuickNavigation = (page) => {
        const { history, i18n } = this.props;

        if (page.includes('manage-fund')) {
            const param = {
                permission_name: 'switch fund'
            }
            apiTask.getTaskPermission(param).then(obj => {
                if (obj.data[0].is_permitted) {
                    history.push('/' + i18n.language + `/${page}`)
                } else {
                    alert('This instruction is not allowed at this moment');
                }
            })
        } else {
            history.push('/' + i18n.language + `/${page}`)
        }
    }

    viewDown = () => {
      const element = document.getElementById('viewDown');
      element.classList.add('view-down');

        const elementUp = document.getElementById('viewUp');
        elementUp.classList.remove('box-detail');
    }

    viewUp = () => {
        const elementUp = document.getElementById('viewUp');
        elementUp.classList.add('box-detail');

        const element = document.getElementById('viewDown');
        element.classList.remove('view-down');
    }

    render() {
        const { t, i18n, history } = this.props;
        const { user, netContributions, accountGain, totalFundBalance, dataLabels, dataValues, dataColors, infoItems, asOfDate, userOverallRanking, totalUserCount, userFunds, instructionHistories } = this.state;
        const isChinese = i18n.language === 'zh-HK'
        const userRankingText = isChinese ? `您目前的綜合排名是 ${userOverallRanking}/${totalUserCount}！` : `You are now ${userOverallRanking}/${totalUserCount} in overall ranking!`
        const loggedInTimeFormat = isChinese ? 'YYYY年M月D日 a h:mm' : 'h:mma D MMM, YYYY';
        const asOfDateFormat = isChinese ? 'YYYY年M月D日' : 'D MMM, YYYY';
        const locale = isChinese ? 'zh-hk' : 'en'
        this.data = {
            ...this.data,
            labels: dataLabels,
            datasets: [{
                ...this.data.datasets[0],
                data: dataValues,
                backgroundColor: dataColors
            }]
        }

        return (                    
            <div className="landing-after-login">
                <Flex className="main__container" style={{backgroundColor: '#F8F8F8'}}>
                    <Flex flex={1} flexDirection="column">
                        <Flex flex={1} justifyContent="start" flexDirection="column" className="header-title" backgroundColor="#01a758" color="white" fontWeight={400}>
                            <Flex fontSize="1.5rem">
                                {`${t("Landing:hi")} ${user.nickname},`}
                            </Flex>
                            <Flex fontSize="1.2rem">
                                {userRankingText}
                            </Flex>
                            <Flex fontSize="1.2rem">
                                {t("Landing:heresYourVirtualPortfolio")}
                            </Flex>
                            <Flex fontSize="1.1rem">
                                {`${t("Landing:lastLoggedIn")} ${user.last_logged_in ? moment(user.last_logged_in).locale(locale).format(loggedInTimeFormat) : ''} (${t('Common:General.hkt')})`}
                            </Flex>
                        </Flex>

                        <HorizontalList t={t} itemList={infoItems} i18n={i18n} history={history} locale={locale} />

                        <Flex fontWeight="bold" fontSize="1.2rem" mt="1rem">
                            {t("Landing:quickLinks")}
                        </Flex>

                        <QuickLinks t={t} onClick={this.handleQuickNavigation} />

                        <Flex fontWeight="bold" fontSize="1.2rem" mt="1.5rem">
                            {t("Landing:competitionAccountBalance")}
                        </Flex>
                        <Flex className="chart-box" flexDirection="column" mt="1rem" padding="1rem" style={{backgroundColor: '#FFFFFF' }}>
                            <Flex fontSize="1rem" justifyContent="space-between">
                                <Flex>
                                    {t("Landing:asOf")} {moment(asOfDate).locale(locale).format(asOfDateFormat)}
                                </Flex>
                                {/* <Flex>
                                    <img className="forward" src={forward} alt='forward' />
                                </Flex> */}
                            </Flex>
                            <Flex height="480px" alignContent="center" justifyContent="center" style={{ position: 'relative' }}>
                                <Flex className="total-value" flexDirection="column" alignItems="center" style={{ position: 'absolute', top: '45%' }} >
                                    <Flex>
                                        {t("Landing:totalFundBalance")} ({t("Landing:HKD")})
                                    </Flex>
                                    <Flex fontSize="1.2rem">
                                        {totalFundBalance.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                    </Flex>
                                </Flex>
                                {/* <div i18n={i18n} className="bg-white pie-chart" id="pieChart" style={{ display: 'flex', justifyContent: 'center' }}></div> */}
                                {this.data.datasets[0].data && <Doughnut data={this.data} options={options} plugins={plugins} />}
                            </Flex>
                            <hr style={{ color: '#e0e0e0', marginBottom: '1rem' }} />
                            <Flex flexDirection="column" fontSize= "1rem">
                                <Flex marginBottom="0.5rem" justifyContent="space-between">
                                    <Flex>
                                        {t("Landing:netContributions")}
                                    </Flex>
                                    <Flex>
                                        {t("Common:General.hkd")} {netContributions ? netContributions.toLocaleString(undefined, {minimumFractionDigits: 2}) : ''}
                                    </Flex>
                                </Flex>
                                <Flex marginBottom="0.5rem" justifyContent="space-between">
                                    <Flex>
                                        {t("Landing:accountGain")} / ({t("Landing:loss")})
                                    </Flex>
                                    <Flex>
                                        {t("Common:General.hkd")} {accountGain.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                                    </Flex>
                                </Flex>
                                <Flex justifyContent="flex-end" color="#1717c5">
                                    {accountGain > 0 ? '+' :  accountGain === 0 ? '' : '-'} {accountGain ? `${(Math.abs(accountGain / netContributions) * 100).toFixed(2)} %`: ''}
                                </Flex>
                            </Flex>
                            {/* <Flex onClick={this.viewDown} id="viewDown" style={{marginTop: '1vw'}}  marginBottom="0.5rem" justifyContent="center">
                                <Flex alignItems="center" className="view-more-button">
                                    <img className="view-more-img" src={dropDown} alt="drop" />
                                    <span className="view-more">{t("Landing:viewMore")}</span>
                                </Flex>
                            </Flex> */}
                        </Flex>
                        <Flex className="chart-box" fontSize="1rem" flexDirection="column" padding="1rem" style={{ backgroundColor: '#FFFFFF', borderTop: 0 }}>
                            {userFunds.map((item, index) =>
                                {
                                    const percentage = (item.amount / totalFundBalance) * 100;
                                    return  (
                                        <Flex key={index} flexDirection="column" alignItems="start" flex={1} style={{ marginBottom: "1rem" }}>
                                            <Flex flexDirection="row" alignItems="center" justifyContent="space-between" width="100%">
                                                <Flex alignItems="center">
                                                    <Flex className="percentage" style={{ backgroundColor: item.color, marginRight: '0.5rem' }}></Flex>
                                                    <Flex>{percentage.toFixed(2)} %</Flex>
                                                </Flex>
                                                <Flex justifyContent="center" alignItems="center" >
                                                    <span>{`${i18n.language === 'en-US' ? 'HKD' : '港元'} ${item.amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}</span>
                                                </Flex>
                                            </Flex>
                                            <Flex fontSize="0.9rem" flexDirection="row" justifyContent="space-between" alignItems="center" width="100%">
                                                <Flex style={{ textDecoration: 'underline', 'text-underline-offset': '3px', 'text-decoration-thickness': '2px', textDecorationColor: '#01a758'}}>{item.fund.fund_code} {item.fund.fund_code && '-'} {item.fund.fund_name}</Flex>
                                                {item.holding_unit && <Flex color="gray">{item.holding_unit.toLocaleString(undefined, { minimumFractionDigits: 3, maximumFractionDigits: 3 })} {t("Landing:unit")}</Flex>}
                                            </Flex>
                                            {item.holding_unit && <Flex fontSize="0.9rem" color="gray" flexDirection="row" justifyContent="space-between" alignItems="center" width="100%">
                                                <Flex>({t("Landing:HKD")})</Flex>
                                                <Flex>{(item.amount / item.holding_unit).toLocaleString(undefined, { minimumFractionDigits: 3, maximumFractionDigits: 3 })} {t("Landing:unitPrice")}</Flex>
                                            </Flex>}
                                        </Flex>
                                    )
                                }
                            )}
                            {/* <Flex onClick={this.viewUp} style={{marginTop: '1vw'}}  marginBottom="0.5rem" justifyContent="center">
                                <Flex alignItems="center" className="view-more-button">
                                    <img className="view-more-img" src={dropUp} alt="drop" />
                                    <span className="view-more">{t("Landing:viewMore")}</span>
                                </Flex>
                            </Flex> */}
                        </Flex>

                        <Flex fontWeight="bold" fontSize="1.2rem" mt="1.5rem">
                            {t("Landing:futureContributionAllocation")}
                        </Flex>
                        <Flex className="chart-box" marginTop="1rem" fontSize="1rem" flexDirection="column" padding="1rem" style={{ backgroundColor: '#FFFFFF' }}>
                            <Flex justifyContent="space-between" alignItems="center">
                                <Flex flexDirection="column">
                                    <Flex>{t("ManageFunds:fundCode")}/</Flex>
                                    <Flex>{t("ManageFunds:fundName")}</Flex>
                                </Flex>
                                <Flex>
                                    {t("ManageFunds:contribution")} %
                                </Flex>
                            </Flex>
                        </Flex>
                        <Flex className="chart-box" fontSize="1rem" flexDirection="column" padding="1rem" style={{ backgroundColor: '#FFFFFF', borderTop: 0 }}>
                            {instructionHistories.map((instruction, index) => {
                                const fundCode = instruction.switch_in_fund.fund_code;
                                const fundName = instruction.switch_in_fund.fund_name;
                                const percentage = instruction.switch_in_percentage;
                                return <Flex flexDirection="column">
                                    <Flex key={index} justifyContent="space-between" alignItems="center">
                                        <Flex flexDirection="column">
                                            <Flex>{fundCode}</Flex>
                                            <Flex>{t(`Fund:${fundName}`)}</Flex>
                                        </Flex>
                                        <Flex>
                                            {percentage} %
                                        </Flex>
                                    </Flex>
                                    {index !== instructionHistories.length - 1 &&<hr style={{ marginTop: '1.5rem', marginBottom: '1.5rem' }} />}
                                </Flex>
                            })}
                        </Flex>
                        {/* <Flex  mt="1.5rem" mb="1.5rem">
                            <Flex flex={1} justifyContent="start" flexDirection="column">
                                <Flex fontWeight={600}>
                                    <span className="personalMPF">{t("Landing:personalMPF")}</span>
                                </Flex>
                                <Flex className="investOverview">
                                    {t("Landing:investOverview")}
                                </Flex>
                                <Flex className="dateTitle">
                                    {t("Landing:dateTitle")}
                                </Flex>
                            </Flex>
                            <Flex>
                                <img className="manage-button" src={imagePaths[i18n.language].manageButton} alt='Manage Button' />
                            </Flex>
                        </Flex> */}
                        <RankingTable hide={true} updateUserOverallRanking={this.updateUserOverallRanking} />
                        {/* <LandingExtend maxWidth="100%" /> */}
                    </Flex>
                </Flex>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    breadcrumbArr: state.breadcrumb.breadcrumbArr
});
const mapDispatchToProps = dispatch => ({
    setBreadcrumbP: data => dispatch(setBreadcrumb(data)),
    getUserInfoP: data => dispatch(getUserInfo(data)),
});
export default   withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(Landing)));
