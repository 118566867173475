import * as BreadcrumbActionTypes from '../Constant/ActionType';
import {updateObject} from "../../utils/Utility";

const initialState = {
    breadcrumbArr: [],
};

const breadcrumbReducer = (state = initialState, action) => {
    switch (action.type) {
        case BreadcrumbActionTypes.SET_BREADCRUMB:
            return updateObject( state, { breadcrumbArr: action.breadcrumbArr.breadcrumbData } );
        case BreadcrumbActionTypes.ADD_BREADCRUMB:
            let currentArr = Array.from(state.breadcrumbArr);
            currentArr.push(action.breadcrumbItem);
            return updateObject( state, { breadcrumbArr: currentArr } );
        case BreadcrumbActionTypes.GET_BREADCRUMB:
            return state;
        default:
            return state;
    }
};

export default breadcrumbReducer;