// Essential for all components
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import { Flex } from 'reflexbox'
import moment from 'moment';

import {setBreadcrumb} from "../../Redux/Action/breadcrumbAction";
import {getUserInfo} from "../../Redux/Action/authAction";
import backIcon from "../../images/back.png";
import {styled} from "@material-ui/styles";

// const Flex = styled(Flex)({
//     backgroundColor: '#fafafa',
//     width: '100%',
//     padding: '0.5rem'
// });

const TradeDetails = (props) => {
    const { history, t, dateFormat, locale, showDetails, showInstructionDetails } = props;
    let description = '';
    if (history.title === 'futureInvestment') {
        description = t("TradeHistory:futureInvestment");
    } else if (history.title === 'fundSwitch') {
        if (history.instructionHistories.length > 0 && history.instructionHistories[0].is_one_time_rebalance) {
            description = `${t("TradeHistory:fundSwitchingInstruction")} - ${t("ManageFunds:oneTimeRebalancing")}`
        } else {
            description = `${t("TradeHistory:fundSwitchingInstruction")} - ${t("TradeHistory:fundToFundsSwitching")}`
        }
    } else if (history.title === 'virtualCapitalReward') {
        description = t("TradeHistory:virtualCapitalReward");
    }

    return (
        <Flex flexDirection="column">
            <Flex flexDirection={"column"} mb="1.5rem">
                <Flex fontWeight={"bold"}>
                    {t("TradeHistory:transactionDes")}
                </Flex>
                <Flex color={"gray"}>
                    {description}
                </Flex>
            </Flex>
            <Flex flexDirection={"column"} mb="1.5rem">
                <Flex fontWeight={"bold"}>
                    {t("TradeHistory:transactionSubmit")}
                </Flex>
                <Flex color={"gray"}>
                    {moment(history.dateTime).locale(locale).format(dateFormat)}
                </Flex>
            </Flex>
            <Flex flexDirection={"column"} mb="1.5rem">
                <Flex fontWeight={"bold"}>
                    {t("TradeHistory:status")}
                </Flex>
                <Flex>
                    <Flex color={"gray"}>
                        {t(`TradeHistory:${history.status}`)}
                    </Flex>
                </Flex>
            </Flex>
            <Flex flexDirection={"column"}>
                <Flex fontWeight={"bold"}>
                    {t("TradeHistory:fundName")}
                </Flex>
                {showInstructionDetails ? <InstructionDetails instructions={history.instructionHistories} t={t} /> :
                    history.instructionHistories.map((instruction, index) => {
                        const { switch_in_fund, switch_in_percentage, is_reward } = instruction;
                        return (
                            <div key={index} style={{ cursor: 'pointer', color: "gray", width: 'max-content' }} onClick={()=>{showDetails(index)}}>
                                {switch_in_fund && (is_reward ? t("TradeHistory:virtualCapitalReward") : t(`Fund:${switch_in_fund.fund_name}`) + (switch_in_percentage ? " - " + Math.abs(switch_in_percentage).toFixed(2) + "%" : ''))}
                            </div>
                        )
                    })  
                }
            </Flex>
        </Flex>
    )
}

const InstructionDetailTable = (props) => {
    const { t, fundName, percentage, holdingUnit, amount } = props;
    return <Flex flexDirection="column">
        <Flex className="gray-cell">{t(`Fund:${fundName}`)}</Flex>
        <Flex mt="2px">
            <Flex className="gray-cell" marginRight="2px">{t("TradeHistory:switching")} %</Flex>
            <Flex className="gray-cell" justifyContent="flex-end">{percentage ? Math.abs(percentage.toFixed(2)) + '%' : '-'}</Flex>
        </Flex>
        <Flex mt="2px">
            <Flex className="gray-cell" marginRight="2px">{t("TradeHistory:unitSwitched")}</Flex>
            <Flex className="gray-cell" justifyContent="flex-end">{holdingUnit ? holdingUnit.toLocaleString(undefined, {minimumFractionDigits: 3, maximumFractionDigits: 3}) : '-'}</Flex>
        </Flex>
        <Flex mt="2px">
            <Flex className="gray-cell" marginRight="2px">{t("TradeHistory:tradingPrice")}</Flex>
            <Flex className="gray-cell" justifyContent="flex-end">{holdingUnit ? (amount/holdingUnit).toFixed(3) : '-'}</Flex>
        </Flex>
        <Flex mt="2px" marginBottom="0.5rem">
            <Flex className="gray-cell" marginRight="2px">{t("TradeHistory:amount")}</Flex>
            <Flex className="gray-cell" justifyContent="flex-end">{amount ? amount.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) : '-'}</Flex>
        </Flex>
    </Flex>
}

const InstructionDetails = (props) => {
    const { instructions, t } = props;
    let switchInTitleShown = false;
    return <Flex flexDirection="column" marginY="0.5rem">
        {instructions.map((instruction, index) => {
            const { switch_in_fund, switch_out_fund, switch_in_holding_unit, switch_in_amount, is_one_time_rebalance, is_reward, switch_in_percentage, switch_out_amount, switch_out_holding_unit } = instruction;
            return (
                <Flex flexDirection="column" key={index}>
                    {!is_one_time_rebalance && !is_reward && switch_out_fund && index === 0 && <Flex flexDirection="column">
                        <Flex fontWeight="bold" marginBottom="0.5rem" marginLeft="0.5rem">{t("TradeHistory:switchOutFrom")}</Flex>
                        <InstructionDetailTable t={t} fundName={switch_out_fund.fund_name} percentage={100} holdingUnit={switch_out_holding_unit} amount={switch_out_amount} />
                    </Flex>}
                    {!is_one_time_rebalance && switch_in_fund && switch_in_percentage && !switchInTitleShown && <Flex fontWeight="bold" marginBottom="0.5rem" marginLeft="0.5rem">{t("ManageFunds:switchIn")}</Flex>}
                    {!is_one_time_rebalance && switch_in_fund && switch_in_percentage ? switchInTitleShown = true : null}
                    {switch_in_fund && <InstructionDetailTable t={t} fundName={switch_in_fund.fund_name} percentage={switch_in_percentage} holdingUnit={switch_in_holding_unit} amount={switch_in_amount} />}
                </Flex>
            )
        })}
    </Flex>
}


class TradeHistory2 extends Component {
    
    state = {
        history: this.props.history.location.state.history,
        showInstructionDetails: false
    };
    
    // BUTTON FUNCTION
    
    back = () => {
        const { history } = this.props;
        history.goBack();
    }

    showDetails = (index) => {
        this.setState({ showInstructionDetails: true });
    }

    render() {
        const { t, i18n } = this.props;
        const { history, showInstructionDetails } = this.state;
        const isChinese = i18n.language === 'zh-HK'
        const locale = isChinese === 'zh-HK' ? 'zh-hk' : 'en';
        const dateFormat = isChinese ? 'YYYY年M月D日 HH:mm' : 'D MMM, YYYY HH:mm';
        return (                    
            <div>
                <Flex className="main__container head-container" >
                    <Flex flex={1} flexDirection="column" fontSize="1rem">
                        <Flex flex={1} justifyContent="start" alignItems="center" className="header-title">
                            <img src={backIcon} className="back-icon" alt="back-icon" onClick={this.back}/>
                            <Flex ml="1rem">
                                {t("TradeHistory:transactionDetails")}
                            </Flex>
                        </Flex >
                        <Flex flexDirection="column" backgroundColor={'white'} padding={'20px'}>
                            <TradeDetails t={t} history={history}  dateFormat={dateFormat} locale={locale} showDetails={this.showDetails} showInstructionDetails={showInstructionDetails} />
                        </Flex>
                    </Flex>
                </Flex>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    breadcrumbArr: state.breadcrumb.breadcrumbArr
});
const mapDispatchToProps = dispatch => ({
    setBreadcrumbP: data => dispatch(setBreadcrumb(data)),
    getUserInfoP: data => dispatch(getUserInfo(data)),
});
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(TradeHistory2)));
