// Essential for all components
import {Button, MenuItem, Select, styled} from '@material-ui/core';
import React, {Component, useState} from 'react';
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import {Flex,} from 'reflexbox';
import Papa from 'papaparse';
import moment from 'moment';
import '../../css/18AdminPortal/_adminPortal.scss';

import {apiUsers} from '../../Api/_ApiUsers';
import {apiFunds} from '../../Api/_ApiFunds';
import {apiTask} from '../../Api/_ApiTask';
import {Field, Form, Formik} from "formik";
import {apiAdminPortal} from "../../Api/_ApiAdminPortal";
import XLSX from "xlsx";
import Grid from "@material-ui/core/Grid";
import ErrorMessage from "../../components/100Include/ErrorMessage";
import {get, values} from "lodash-es";
import {apiUserAccount} from "../../Api/_ApiUserAccount";
import {apiMiniGames} from "../../Api/_ApiMiniGames";
import * as formik from "lodash-es";

const StyledButton = styled(Button)({
    width: '40%',
    fontSize: '1rem',
    border: 2,
    borderStyle: 'solid',
    backgroundColor: '#ec6453',
    borderColor: '#c0c0c0',
    color: 'white',
    cursor: 'pointer',
    borderRadius: 5,
    marginBottom: '1rem',
    '&:hover': {
        backgroundColor: '#ec6453',
    },
    zIndex: 1
});

const StyledDiv = styled('div')({
    fontSize: '1rem',
    textAlign: 'center',
    borderStyle: 'solid',
    padding: '0.8rem',
    backgroundColor: '#ec6453',
    color: 'white',
    borderRadius: 5,
    marginRight: '1rem'
});


const CustomPagination = ({ totalItems, itemsPerPage, currentPage, onPageChange, onPageSizeChange }) => {
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
            onPageChange(newPage);
        }
    };

    const handlePageSizeChange = (event) => {
        const newSize = parseInt(event.target.value, 10);
        if (!isNaN(newSize)) {
            onPageSizeChange(newSize);
            handlePageChange(1); // Reset to the first page when changing page size
        }
    };

    return (
        <div className="pagination-container">
            <div className="pagination-left">
                <span>Page Size:</span>
                <select onChange={handlePageSizeChange} value={itemsPerPage}>
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="25">25</option>
                </select>
            </div>
            <div className="pagination-right">
                <button onClick={() => handlePageChange(1)}>First</button>
                <button onClick={() => handlePageChange(currentPage - 1)}>Previous</button>
                <span>Page {currentPage} of {totalPages}</span>
                <button onClick={() => handlePageChange(currentPage + 1)}>Next</button>
                <button onClick={() => handlePageChange(totalPages)}>Last</button>
            </div>
        </div>
    );
};

const SelectMiniGame = (props) => {
    const { miniGames } = props;
    const items = miniGames || [];

    return (
        <select id="miniGames" name="miniGames" required>
            <option value="">Select a Mini Game</option>
            {items.map((miniGame) => (
                <option key={miniGame.id} value={miniGame.id}>
                    {miniGame.name}
                </option>
            ))}
        </select>
    );
}

const Paging = (props) => {

    const { allUsers, getUsersFunc } = props;
    let items;
    if (allUsers) {
        items = allUsers;
    } else {
        items = [];
    }

    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [currentPage, setCurrentPage] = useState(1);

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentPageData = items.slice(startIndex, endIndex);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handlePageSizeChange = (newSize) => {
        setItemsPerPage(newSize);
    };

    return (
        <div>
            <Table1 getUsersFunc={(sortingColumn, sortingOrder) => getUsersFunc(sortingColumn, sortingOrder)} startIndex={startIndex} currentPageData={currentPageData} /> {/* Pass currentPageData as prop */}
            <CustomPagination
                totalItems={items.length}
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
            />
        </div>
    );
};

const Paging2 = (props) => {

    const { allUserAccounts, getUserAccountsFunc } = props

    let items;

    if (allUserAccounts) {
        items = allUserAccounts;
    } else  {
        items = [];
    }

    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [currentPage, setCurrentPage] = useState(1);

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentPageData = items.slice(startIndex, endIndex);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handlePageSizeChange = (newSize) => {
        setItemsPerPage(newSize);
    };

    return (
        <div>
            <Table2 getUserAccountsFunc={(sortingColumn, sortingOrder) => getUserAccountsFunc(sortingColumn, sortingOrder)} startIndex={startIndex} currentPageData={currentPageData} />
            <CustomPagination
                totalItems={items.length}
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
            />
        </div>
    );
};

const Table1 = ({ currentPageData, startIndex, getUsersFunc }) => {

    const [selectedRowIndex, setSelectedRowIndex] = useState(null);
    const [updateRowIndex, setUpdateRowIndex] = useState(false);

    const handleCheckboxClick = (event) => {
        event.stopPropagation();
    };

    const handleRowClick = (index) => {
        setSelectedRowIndex(index);
    };

    const handleUpdateUser = (username, body) => {
        apiUsers.updateUser(username, body);
    }

    const [sortOrders, setSortOrders] = useState({
        legal_full_name: 'none',
        phone: 'none',
        policy_number: 'none',
        first_four_digit_id: 'none',
        email: 'none',
    });

    // const handleSort = async (column) => {
    //     const newSortOrder = sortOrders[column] === 'asc' ? 'desc' : sortOrders[column] === 'desc' ? 'none' : 'asc';
    //     setSortOrders(prevSortOrders => ({
    //         ...prevSortOrders,
    //         [column]: newSortOrder,
    //     }));
    //
    //     // await _getUsers(column, newSortOrder); // Call _getUsers after updating sortOrders
    // };

    const handleSort = async (column) => {
        const newSortOrder = sortOrders[column] === 'asc' ? 'desc' : 'asc';

        // const newSortOrder = sortOrders[column] === 'asc' ? 'desc' : sortOrders[column] === 'desc' ? 'none' : 'asc';

        setSortOrders(prevSortOrders => ({
            ...Object.fromEntries(Object.keys(prevSortOrders).map(key => [key, 'none'])),
            [column]: newSortOrder,
        }));

        await getUsersFunc(column, newSortOrder);
    };

    const handleInputChange = (event, field) => {
        const newFieldValue = event.target.value;
        const updatedData = [...currentPageData];
        updatedData[selectedRowIndex][field] = newFieldValue;
    };

    return (
        <div>
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                <tr style={{ borderBottom: '1px solid #ccc' }}>
                    <th style={{ padding: '10px', textAlign: 'left' }}>
                        <input type="checkbox" />
                    </th>
                    <th style={{ padding: '10px', textAlign: 'left' }}>Index</th>
                    <th style={{ padding: '10px', textAlign: 'left', cursor: 'pointer' }} onClick={() => handleSort('legal_full_name')}>
                        <span>Legal Full Name</span>
                        {sortOrders['legal_full_name'] === 'asc' && <span className="sort-icon asc"></span>}
                        {sortOrders['legal_full_name'] === 'desc' && <span className="sort-icon desc"></span>}
                        {/*{sortOrders['legal_full_name'] === 'none' && <span><span className="sort-icon asc"></span><span className="sort-icon desc"></span></span>}*/}
                    </th>
                    <th style={{ padding: '10px', textAlign: 'left', cursor: 'pointer' }} onClick={() => handleSort('phone')}>
                        <span>Mobile No.</span>
                        {sortOrders['phone'] === 'asc' && <span className="sort-icon asc"></span>}
                        {sortOrders['phone'] === 'desc' && <span className="sort-icon desc"></span>}
                        {/*{sortOrders['phone'] === 'none' && <span><span className="sort-icon asc"></span><span className="sort-icon desc"></span></span>}*/}
                    </th>
                    <th style={{ padding: '10px', textAlign: 'left', cursor: 'pointer' }} onClick={() => handleSort('policy_number')}>
                        <span>Menulife Policy No.</span>
                        {sortOrders['policy_number'] === 'asc' && <span className="sort-icon asc"></span>}
                        {sortOrders['policy_number'] === 'desc' && <span className="sort-icon desc"></span>}
                        {/*{sortOrders['policy_number'] === 'none' && <span><span className="sort-icon asc"></span><span className="sort-icon desc"></span></span>}*/}
                    </th>
                    <th style={{ padding: '10px', textAlign: 'left', cursor: 'pointer' }} onClick={() => handleSort('first_four_digit_id')}>
                        <span>First 4 digit of ID card</span>
                        {sortOrders['first_four_digit_id'] === 'asc' && <span className="sort-icon asc"></span>}
                        {sortOrders['first_four_digit_id'] === 'desc' && <span className="sort-icon desc"></span>}
                        {/*{sortOrders['first_four_digit_id'] === 'none' && <span><span className="sort-icon asc"></span><span className="sort-icon desc"></span></span>}*/}
                    </th>
                    <th style={{ padding: '10px', textAlign: 'left', cursor: 'pointer' }} onClick={() => handleSort('email')}>
                        <span>Email</span>
                        {sortOrders['email'] === 'asc' && <span className="sort-icon asc"></span>}
                        {sortOrders['email'] === 'desc' && <span className="sort-icon desc"></span>}
                        {/*{sortOrders['email'] === 'none' && <span><span className="sort-icon asc"></span><span className="sort-icon desc"></span></span>}*/}
                    </th>
                </tr>
                </thead>
                <tbody>
                {currentPageData.map((item, index) => (
                    <tr
                        key={index}
                        style={{ borderBottom: '1px solid #ccc', cursor: 'pointer' }}
                        onClick={() => handleRowClick(index)}
                    >
                        <td style={{ padding: '10px' }}>
                            <input type="checkbox" onClick={handleCheckboxClick} />
                        </td>
                        <td style={{ padding: '10px' }}>{index + startIndex + 1}</td>
                        <td style={{ padding: '10px' }}>{item.legalFullName}</td>
                        <td style={{ padding: '10px' }}>{item.mobileNo}</td>
                        <td style={{ padding: '10px' }}>{item.policyNo}</td>
                        <td style={{ padding: '10px' }}>{item.firstFourDigit}</td>
                        <td style={{ padding: '10px' }}>{item.email}</td>
                    </tr>
                ))}
                </tbody>
            </table>

            {selectedRowIndex !== null && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100vw',
                        height: '100vh',
                        maxHeight: '100%',
                        background: 'rgba(68, 70, 84, 0.9)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 1,
                    }}
                >
                    <div style={{ width: '50%', background: '#fff', padding: '3rem', borderRadius: '5px', overflow: 'hidden' }}>
                        <Flex alignItems='center' mb={'2rem'} justifyContent='space-between' >
                            <div>
                                <h2 style={{ margin: 0, padding: 0}}>{!updateRowIndex ? 'Account Management Detail' : 'Account Management Edit'}</h2>
                            </div>

                            <div>
                                { !updateRowIndex && (
                                    <button style={{ margin: '1rem 0' }}
                                            onClick={() => {
                                                setUpdateRowIndex(true)
                                            }}>
                                        Edit
                                    </button>
                                ) }
                            </div>

                        </Flex>

                        <Formik
                            initialValues={{
                                username: currentPageData[selectedRowIndex].username,
                                nickname: currentPageData[selectedRowIndex].nickname,
                                age: currentPageData[selectedRowIndex].age,
                                user_referal_code: currentPageData[selectedRowIndex].referralCode,
                                legal_full_name: currentPageData[selectedRowIndex].legalFullName,
                                phone: currentPageData[selectedRowIndex].mobileNo,
                                policy_number: currentPageData[selectedRowIndex].policyNo,
                                first_four_digit_id: currentPageData[selectedRowIndex].firstFourDigit,
                                email: currentPageData[selectedRowIndex].email,
                            }}

                            onSubmit={async (values, { setSubmitting }) => {
                                try {
                                    // Assuming apiUsers.updateUser is a function that updates user data
                                    const response = await apiUsers.updateUser(values.username, values);
                                    if (response.status === 200) {
                                        // Show your success popup here
                                        alert('Update successful');
                                    } else {
                                        // Handle other status codes if needed
                                        alert('Update fail');
                                        console.error('Update failed:', response.status);
                                    }
                                } catch (error) {
                                    console.error('Update error:', error);
                                } finally {
                                    setSubmitting(false);
                                }
                            }}

                        >
                            {formik => (
                                <Form>
                                    {[
                                        { label: 'Nick Name:', name: 'nickname' },
                                        {
                                            label: 'Age:',
                                            name: 'age',
                                            render: (field, formik) => (
                                                <Field name={field.name} type="text">
                                                    {({ field: innerField }) => (
                                                        <Select
                                                            {...innerField}
                                                            fullWidth
                                                            className={`render-age ${updateRowIndex ? '' : 'disable-true'}`}
                                                            label='Age'
                                                            value={innerField.value || 'Please Select'}
                                                            renderValue={innerField.value !== "" ? () => innerField.value : () => 'Please Select'}
                                                        >
                                                            <MenuItem style={{ border: "1px solid lightgray", borderWidth: "0 0 1px 0" }} value='Below 50'>Below 50</MenuItem>
                                                            {Array.from({ length: 14 }, (_, index) => 50 + index).map((age) => (
                                                                <MenuItem style={{ border: "1px solid lightgray", borderWidth: "0 0 1px 0" }} key={age} value={age}>
                                                                    {age}
                                                                </MenuItem>
                                                            ))}
                                                            <MenuItem style={{ border: "1px solid lightgray", borderWidth: "0 0 1px 0" }} value='64 and above'>64 and above</MenuItem>
                                                        </Select>
                                                    )}
                                                </Field>
                                            ),
                                        },
                                        { label: 'Referral Code:', name: 'user_referal_code' },
                                        { label: 'Legal Full Name:', name: 'legal_full_name' },
                                        { label: 'Mobile No:', name: 'phone' },
                                        { label: 'Menulife Policy No:', name: 'policy_number' },
                                        { label: 'First 4 digit of ID card:', name: 'first_four_digit_id' },
                                        { label: 'Email:', name: 'email' },
                                    ].map((field) => (
                                        <div className="form-row" key={field.name}>
                                            <label style={{ maxWidth: '30%' }} htmlFor={field.name}>
                                                {field.label}
                                            </label>
                                            {field.render ? field.render(field, formik) : (
                                                <input
                                                    value={formik.values[field.name]}
                                                    onChange={formik.handleChange}
                                                    className={updateRowIndex ? '' : 'disable-true'}
                                                    type="text"
                                                    id={field.name}
                                                    name={field.name}
                                                    disabled={!updateRowIndex}
                                                />
                                            )}
                                        </div>
                                    ))}

                                    <div>
                                        {updateRowIndex && (
                                            <button type="submit" style={{ marginRight: '1rem' }}>
                                                Save
                                            </button>
                                        )}

                                        <button
                                            style={{ margin: '1rem 0' }}
                                            onClick={() => {
                                                setSelectedRowIndex(null);
                                                setUpdateRowIndex(false);
                                            }}
                                        >
                                            Close
                                        </button>
                                    </div>
                                </Form>
                            )}

                        </Formik>

                    </div>
                </div>
            )}

        </div>
    );
};


const Table2 = ({ currentPageData, startIndex, getUserAccountsFunc }) => {

    const [selectedRowIndex, setSelectedRowIndex] = useState(null);
    const [updateRowIndex, setUpdateRowIndex] = useState(false);

    const handleCheckboxClick = (event) => {
        event.stopPropagation();
    };

    const handleRowClick = (index) => {
        setSelectedRowIndex(index);
    };

    const [sortOrders, setSortOrders] = useState({
        legal_full_name: 'none',
        email: 'none',
        phone: 'none',
        nav: 'none',
        return: 'none',
        ranking: 'none'
    });


    const handleSort = async (column) => {
        const newSortOrder = sortOrders[column] === 'asc' ? 'desc' : 'asc';

        setSortOrders(prevSortOrders => ({
            ...Object.fromEntries(Object.keys(prevSortOrders).map(key => [key, 'none'])),
            [column]: newSortOrder,
        }));

        // await getUserAccountsFunc(column, newSortOrder);
    };

    return (
        <div>
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                <tr style={{ borderBottom: '1px solid #ccc' }}>
                    <th style={{ padding: '10px', textAlign: 'left' }}><input type="checkbox"/></th>
                    <th style={{ padding: '10px', textAlign: 'left' }}>Index</th>
                    <th style={{ padding: '10px', textAlign: 'left', cursor: 'pointer' }} onClick={() => handleSort('legal_full_name')}>
                        <span>Legal Full Name</span>
                        {sortOrders['legal_full_name'] === 'asc' && <span className="sort-icon asc"></span>}
                        {sortOrders['legal_full_name'] === 'desc' && <span className="sort-icon desc"></span>}
                        {/*{sortOrders['legal_full_name'] === 'none' && <span><span className="sort-icon asc"></span><span className="sort-icon desc"></span></span>}*/}
                    </th>
                    <th style={{ padding: '10px', textAlign: 'left', cursor: 'pointer' }} onClick={() => handleSort('email')}>
                        <span>Email</span>
                        {sortOrders['email'] === 'asc' && <span className="sort-icon asc"></span>}
                        {sortOrders['email'] === 'desc' && <span className="sort-icon desc"></span>}
                        {/*{sortOrders['email'] === 'none' && <span><span className="sort-icon asc"></span><span className="sort-icon desc"></span></span>}*/}
                    </th>
                    <th style={{ padding: '10px', textAlign: 'left', cursor: 'pointer' }} onClick={() => handleSort('phone')}>
                        <span>Mobile No.</span>
                        {sortOrders['phone'] === 'asc' && <span className="sort-icon asc"></span>}
                        {sortOrders['phone'] === 'desc' && <span className="sort-icon desc"></span>}
                        {/*{sortOrders['phone'] === 'none' && <span><span className="sort-icon asc"></span><span className="sort-icon desc"></span></span>}*/}
                    </th>
                    <th style={{ padding: '10px', textAlign: 'left', cursor: 'pointer' }} onClick={() => handleSort('nav')}>
                        <span>NAV</span>
                        {sortOrders['nav'] === 'asc' && <span className="sort-icon asc"></span>}
                        {sortOrders['nav'] === 'desc' && <span className="sort-icon desc"></span>}
                        {/*{sortOrders['nav'] === 'none' && <span><span className="sort-icon asc"></span><span className="sort-icon desc"></span></span>}*/}
                    </th>
                    <th style={{ padding: '10px', textAlign: 'left', cursor: 'pointer' }} onClick={() => handleSort('return')}>
                        <span>Overall Return %</span>
                        {sortOrders['return'] === 'asc' && <span className="sort-icon asc"></span>}
                        {sortOrders['return'] === 'desc' && <span className="sort-icon desc"></span>}
                        {/*{sortOrders['return'] === 'none' && <span><span className="sort-icon asc"></span><span className="sort-icon desc"></span></span>}*/}
                    </th>
                    <th style={{ padding: '10px', textAlign: 'left', cursor: 'pointer' }} onClick={() => handleSort('ranking')}>
                        <span>OverallRanking</span>
                        {sortOrders['ranking'] === 'asc' && <span className="sort-icon asc"></span>}
                        {sortOrders['ranking'] === 'desc' && <span className="sort-icon desc"></span>}
                        {/*{sortOrders['ranking'] === 'none' && <span><span className="sort-icon asc"></span><span className="sort-icon desc"></span></span>}*/}
                    </th>
                </tr>
                </thead>
                <tbody>
                {currentPageData.map((item, index) => (

                    <tr key={index}
                        style={{ borderBottom: '1px solid #ccc', cursor: 'pointer' }}
                        onClick={() => handleRowClick(index)} >
                        <td style={{ padding: '10px', borderBottom: '1px solid #ccc' }}>
                            <input type="checkbox" />
                        </td>
                        <td style={{ padding: '10px', borderBottom: '1px solid #ccc' }}>
                            {index + startIndex + 1}
                        </td>
                        <td style={{ padding: '10px', borderBottom: '1px solid #ccc' }}>
                            {item.legalFullName}
                        </td>
                        <td style={{ padding: '10px', borderBottom: '1px solid #ccc' }}>
                            {item.email}
                        </td>
                        <td style={{ padding: '10px', borderBottom: '1px solid #ccc' }}>
                            {item.mobileNo}
                        </td>
                        <td style={{ padding: '10px', borderBottom: '1px solid #ccc' }}>
                            ${item.nav}
                        </td>
                        <td style={{ padding: '10px', borderBottom: '1px solid #ccc' }}>
                            {item.overallReturn}
                        </td>
                        <td style={{ padding: '10px', borderBottom: '1px solid #ccc' }}>
                            {item.overallRanking}
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>

            {selectedRowIndex !== null && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100vw',
                        height: '100vh',
                        maxHeight: '100%',
                        background: 'rgba(68, 70, 84, 0.9)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 1,
                    }}
                >
                    <div style={{ width: '50%', background: '#fff', padding: '3rem', borderRadius: '5px', overflow: 'hidden' }}>
                        <Flex alignItems='center' mb={'2rem'} justifyContent='space-between' >
                            <div>
                                <h2 style={{ margin: 0, padding: 0}}>{!updateRowIndex ? 'Performance and Trade History Detail' : 'Performance and Trade History Edit'}</h2>
                            </div>

                            {/*<div>*/}
                            {/*    { !updateRowIndex && (*/}
                            {/*        <button style={{ margin: '1rem 0' }}*/}
                            {/*                onClick={() => {*/}
                            {/*                    setUpdateRowIndex(true)*/}
                            {/*                }}>*/}
                            {/*            Edit*/}
                            {/*        </button>*/}
                            {/*    ) }*/}
                            {/*</div>*/}

                        </Flex>

                        <Formik
                            initialValues={{
                                nickName: '',
                                age: '',
                                etc: '',
                                referralCode: '',
                                legalFullName: currentPageData[selectedRowIndex].legalFullName,
                                mobileNo: currentPageData[selectedRowIndex].mobileNo,
                                email: currentPageData[selectedRowIndex].email,
                                nav: currentPageData[selectedRowIndex].nav,
                                overallReturn: currentPageData[selectedRowIndex].overallReturn,
                                overallRanking: currentPageData[selectedRowIndex].overallRanking,
                            }}
                            onSubmit={values => {

                            }}
                        >
                            {formik => (
                                <Form>
                                    {[
                                        { label: 'Legal Full Name:', name: 'legalFullName' },
                                        { label: 'Email:', name: 'email' },
                                        { label: 'Mobile No:', name: 'mobileNo' },
                                        { label: 'NAV:', name: 'nav' },
                                        { label: 'Overall Return:', name: 'overallReturn' },
                                        { label: 'Overall Ranking:', name: 'overallRanking' },

                                    ].map(field => (
                                        <div className="form-row" key={field.name}>
                                            <label style={{ maxWidth: '30%' }} htmlFor={field.name}>
                                                {field.label}
                                            </label>
                                            <input
                                                value={formik.values[field.name]}
                                                onChange={formik.handleChange}
                                                className={updateRowIndex ? '' : 'disable-true'}
                                                type="text"
                                                id={field.name}
                                                name={field.name}
                                                disabled={!updateRowIndex}
                                            />
                                        </div>
                                    ))}

                                    <div>
                                        {updateRowIndex && (
                                            <button type="submit" style={{ marginRight: '1rem' }}>
                                                Save
                                            </button>
                                        )}

                                        <button
                                            style={{ margin: '1rem 0' }}
                                            onClick={() => {
                                                setSelectedRowIndex(null);
                                                setUpdateRowIndex(false);
                                            }}
                                        >
                                            Close
                                        </button>
                                    </div>
                                </Form>
                            )}

                        </Formik>

                    </div>
                </div>
            )}
        </div>
    );
};

class AdminPortal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formSearchVirtualDollarData: {
                mobile: '',
                address: '',
            },
            showFormSendVirtualDollar: false,
            allUsers: [],
            allUserAccounts: [],

            emailAddress: '',
            valueCoupon: 50,
            remarks: '',

            // For #6
            searchResults: [], // Danh sách user sau khi tìm kiếm
            selectedUser: null,

            //for #7
            searchCouponResultList: [],
            showMessage: '',
            searchUserNotFound: '',
            searchUserToCoupon: '',
            searchUserLabel: '',
        };
    }

    _getUserMiniGame = () => {
        const body = {
            '$orderby': 'createddate desc'
        }
        apiMiniGames.getUserMiniGames(body)
            .then(response => {
                const miniGames = response.data.map(item => item.mini_game);
                this.setState({ miniGames });
            })
            .catch(error => {
                console.error("Error fetching mini games:", error);
            });

    }

    _getAllMiniGame = () => {
        const body = {
            '$orderby': 'createddate desc'
        }
        apiMiniGames.getAllMiniGames(body)
            .then(response => {
                this.setState({ allMiniGames: response.data });
            })
            .catch(error => {
                console.error("Error fetching mini games:", error);
            });

    }

    _getData = async (apiFunction, body) => {
        try {
            const response = await apiFunction(body);
            return response.data; // Return the data directly
        } catch (error) {
            console.error("Error fetching data:", error);
            return [];
        }
    };

    _getUsers = async (sortingColumn, sortingOrder) => {
        try {
            const body = {
                '$orderby': `${sortingColumn} ${sortingOrder}`, // Apply sorting
            };
            const usersData = await this._getData(apiAdminPortal.getUsers, body);

            const formattedUsers = usersData.map(userItem => ({
                userId: userItem.phone,
                username: userItem.username,
                nickname: userItem.nickname,
                age: userItem.age,
                referralCode: userItem.referralCode,
                legalFullName: userItem.legal_full_name,
                mobileNo: userItem.phone,
                policyNo: userItem.policy_number,
                firstFourDigit: userItem.first_four_digit_id,
                email: userItem.email,
                address: userItem.address
            }));

            this.setState({ allUsers: formattedUsers });
        } catch (error) {
            console.error("Error fetching users:", error);
        }
    };

    // _getUsers = async () => {
    //     try {
    //         const body = {
    //             '$orderby': 'createddate desc',
    //         };
    //         const usersData = await this._getData(apiAdminPortal.getUsers, body);
    //
    //         const formattedUsers = usersData.map(userItem => ({
    //             userId: userItem.phone,
    //             username: userItem.username,
    //             nickname: userItem.nickname,
    //             age: userItem.age,
    //             referralCode: userItem.referralCode,
    //             legalFullName: userItem.legal_full_name,
    //             mobileNo: userItem.phone,
    //             policyNo: userItem.policy_number,
    //             firstFourDigit: userItem.first_four_digit_id,
    //             email: userItem.email,
    //             address: userItem.address
    //         }));
    //
    //         this.setState({ allUsers: formattedUsers });
    //     } catch (error) {
    //         console.error("Error fetching users:", error);
    //     }
    // };

    _getUserAccounts = async (sortingColumn, sortingOrder) => {
        try {
            const initialCapital = await this._getInitialCapital();
            const initialCapitalValue = initialCapital.int_value;

            const body = {
                '$orderby': `${sortingColumn} ${sortingOrder}`,
                '$expand': 'user'
            };

            const allUserAccountsData = await this._getData(apiAdminPortal.getUserAccounts, body);

            let rankingCounter = 1;
            const formatterUserAccount = allUserAccountsData.map(accountItem => ({
                legalFullName: accountItem.user.legal_full_name,
                email: accountItem.user.email,
                mobileNo: accountItem.user.phone,
                nav: accountItem.total_fund_balance,
                overallReturn: accountItem.total_fund_balance / (initialCapitalValue + accountItem.contribution_1 + accountItem.contribution_2) * 100,
                overallRanking: rankingCounter++,
                createdDate: accountItem.createddate,
            }))

            formatterUserAccount.sort((a, b) => new Date(a.createdDate) - new Date(b.createdDate));

            this.setState({ allUserAccounts: formatterUserAccount.filter(account => account !== null) });
        } catch (error) {
            console.error("Error fetching user accounts:", error);
        }
    };

    _getInitialCapital = async () => {
        try {
            const body = {
                '$orderby': 'createddate desc'
            };
            const obj = await apiAdminPortal.getInitialCapital(body);
            return obj.data;
        } catch (error) {
            console.error("Error fetching initial capital:", error);
            throw error; // Rethrow the error to be caught by the caller
        }
    };

    handleExportToExcel = (data, filename) => {
        try {
            const csv = Papa.unparse(data);
            const xlsxData = this.convertCsvToXlsx(csv); // Convert CSV to XLSX data
            this.exportToExcel(xlsxData, filename); // Use provided filename
        } catch (error) {
            console.error("Error exporting to Excel:", error);
            throw error;
        }
    };

    handleExportAccountManagement = () => {
        this.handleExportToExcel(this.state.allUsers, 'account_management.xlsx');
    }

    handleExportTradingHistory = () => {
        const tradingHistoryDataWithoutCreatedDate = this.state.allUserAccounts.map(item => {
            const { createdDate, ...rest } = item; // Destructure createddate and the rest of the fields
            return rest; // Return only the rest of the fields
        });

        this.handleExportToExcel(tradingHistoryDataWithoutCreatedDate, 'Performance_And_Trading_History.xlsx');
    };


    handleExportVouchers = () => {
        const body = {
            '$orderby': 'createddate desc'
        };

        apiAdminPortal.getVouchers(body)
            .then(obj => {
                const allVouchers = obj.data.map(item => ({
                    voucherId: item.voucher_id,
                    user: item.user,
                    value: item.value,
                    voucherUrl: item.voucher_url,
                    qrCode: item.qr_code_url,
                    code: item.code,
                    refNo: item.ref_no,
                    wonTime: item.won_time
                }));

                this.handleExportToExcel(allVouchers, 'all_vouchers.xlsx');
            })
            .catch(error => {
                console.error("Error fetching vouchers:", error);
                throw error;
            });
    };

    convertCsvToXlsx = (csvData) => {
        const parsedCsv = Papa.parse(csvData, { header: true });
        const worksheet = XLSX.utils.json_to_sheet(parsedCsv.data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        return excelBuffer;
    }

    exportToExcel = (data, fileName) => {
        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();
        URL.revokeObjectURL(url);
    }

    componentDidMount() {
        this._getUsers('createddate', 'desc');
        this._getUserAccounts('total_fund_balance', 'desc');
        this._getUserMiniGame();
        this._getAllMiniGame();
    }


    handleExportUser = () => {
        const params = {
            'email[null]': false,
            'policy_number[null]': false,
            'legal_full_name[null]': false,
        }
        apiUsers.getUserList(params).then(obj => {

            const userList = obj.data.map(item => {
                const user = {};
                user.username = item.phone;
                user.nickname = item.nickname;
                user.legal_full_Name = item.legal_full_name;
                user.policy_number = item.policy_number;
                user.email = item.email;
                user.phone = item.phone;
                user.status = item.status;
                return user;
            })
            var csv = Papa.unparse(userList);
            this.downloadCsv(csv, 'pending_user_list');
        })
    }

    downloadCsv = (data, fileName) => {
        var csvData = new Blob([data], {type: 'text/csv;charset=utf-8;'});
        var csvURL =  null;
        if (navigator.msSaveBlob){
            csvURL = navigator.msSaveBlob(csvData, `${fileName}.csv`);
        } else {
            csvURL = window.URL.createObjectURL(csvData);
        }
        var tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', `${fileName}.csv`);
        tempLink.click();
    }

    handleImportUsers = (csvData) => {
        const approvedUsernames = [];
        csvData.forEach((data, index) => {
            const status = data[6];
            if (index !== 0 && status === 'approved') {
                let username = data[0]
                const charMissing = 8 - username.length;
                if (username.length < 8) {
                    for (let i = 0; i < charMissing; i++){
                        username = '0' + username;
                    }
                }
                approvedUsernames.push(username);
            }
        })
        const approvedUsernamesStr = approvedUsernames.toString();
        this.updateApprovedUser(approvedUsernamesStr);
    }
    
    updateApprovedUser = (approvedUsernamesStr) => {
        const body = {
            usernames: approvedUsernamesStr,
            send_welcome_email: true,
            status: 'approved'
        }
        apiUsers.sendWelcomeEmail(body).then(obj => {
            if (obj.status === 204) {
                alert("Successfully imported");
                document.getElementById("import-user-button").value = ""
            }
        });
    }

    deleteUnversionedFund = () => {
        const body = {
            'version[null]': true
        }
        return apiFunds.deleteFund(body);
    }

    handleImportFundPrice = (csvData) => {
        const date = csvData[2][0];
        const dateTimestamp = moment(moment(date).locale('en').format("D MMM YYYY")).endOf('day').valueOf();
        if (dateTimestamp) {
            const funds = [];
            const deleteUnversionedFundPromise = Promise.resolve(this.deleteUnversionedFund());
            apiFunds.setReadyAsOfDate(dateTimestamp).then(() => {
                deleteUnversionedFundPromise.then(() => {
                    csvData.forEach((data, index) => {
                        const fundDetails = {};
                        if (index > 3 && data[2]) {
                            fundDetails.fund_name = data[1].trim();
                            fundDetails.fund_code = data[2];
                            fundDetails.latest_date = dateTimestamp;
                            fundDetails.unit_price_reference = data[3] ? +data[3] : null;
                            fundDetails.sequence = index - 3;
                            fundDetails.interest = data[4] ? data[4] : null;
                            fundDetails.dividend = data[5] ? data[5] : null;
                            fundDetails.fund_type = data[6];
                            fundDetails.risk_index = data[7] ? data[7] : null;
                            fundDetails.review_date = data[8] ? moment(moment(data[8]).locale('en').format("D MMM YYYY")).endOf('day').valueOf() : null;
                            funds.push(fundDetails);
                        }
                        if (['SHK148', 'SHK149'].includes(data[2])) {
                            const DISFundDetails = { ...fundDetails };
                            const fundName = data[1].trim();
                            const fundCode = data[2];
                            DISFundDetails.fund_name = `${fundName}(DIS)`;
                            DISFundDetails.fund_code = fundCode.replace('SHK', 'DIS');
                            DISFundDetails.sequence = null;
                            funds.push(DISFundDetails);
                        }
                    })
                    this.createFund(funds);
                })
            })
        } else {
            alert("Incorrect date");
        }
    }

    createFund = (funds) => {
        Promise.all(
            funds.map(fund => {
                return apiFunds.createFund(fund);
            })
        ).then(() => {
            alert("Successfully imported");
            document.getElementById("import-fund-button").value = ""
        });
    }

    getUnversionedFund = () => {
        const param = {
            'version[null]': true
        }
        return apiFunds.getAllFunds(param).then(obj=> obj.data)
    }

    updateFundPrice = () => {
        const task = {};
        task.task = 'update fund price';
        task.start_date = moment().valueOf();
        task.end_date = moment().add(30, 'minutes').valueOf();
        task.is_task_completed = false;
        this.createTask([task]);
    }

    downloadFundPriceSample = () => {
        const downloadUrl = '/Fund Price.csv';
        window.location.href = downloadUrl;
    }

    exportScheduledList = () => {
        const param = {
            '$orderby': 'start_date'
        }
        apiTask.getAllTasks(param).then(obj => {
            const tasksList = obj.data.map(item => {
                const task = {};
                task.task = item.task;
                task.start_date = moment(item.start_date).format('YYYY-MM-DD HH:mm:ss');
                task.end_date = moment(item.end_date).format('YYYY-MM-DD HH:mm:ss');
                task.is_task_completed = item.is_task_completed;
                return task;
            })
            var csv = Papa.unparse(tasksList);
            this.downloadCsv(csv, 'scheduled_task_list');
        })
    }

    createTask = (tasks) => {
        Promise.all(
            tasks.map(task => {
                return apiTask.createTask(task);
            })
        ).then(() => {
            alert("Successfully imported");
            document.getElementById("import-task-button").value = ""
        });
        
    }

    importScheduledList = (csvData) => {
        apiTask.deleteAllTasks().then(() => {
            const tasks = [];
            csvData.forEach((data, index) => {
                const task = {};
                if (index > 0) {
                    task.task = data[0].trim();
                    task.start_date = moment(data[1], 'YYYY-MM-DD HH:mm:ss').valueOf();
                    task.end_date = moment(data[2], 'YYYY-MM-DD HH:mm:ss').valueOf();
                    task.is_task_completed = data[3];
                    tasks.push(task);
                }
            })
            this.createTask(tasks);
        })
    }

    // handleFormSearchVirtualDollarSubmit = (e) => {
    //     e.preventDefault();
    //     const formData = {
    //         mobile: e.target.mobile.value,
    //         emailAddress: e.target.emailAddress.value,
    //     };
    //
    //     // Check if any of the fields have data to perform the search
    //     if (formData.mobile.trim() !== '' || formData.emailAddress.trim() !== '') {
    //         // Check if the mobile or address matches any user
    //         const searchUser = this.state.allUsers.find(user => (
    //             user.mobileNo === formData.mobile || user.emailAddress.includes(formData.emailAddress)
    //         ));
    //
    //         if (searchUser) {
    //             this.setState({
    //                 formSearchVirtualDollarData: formData,
    //                 showFormSendVirtualDollar: true,
    //                 searchUser: searchUser,
    //             });
    //         } else {
    //             alert('No user found with the provided mobile or address.');
    //         }
    //     } else {
    //         alert('Please enter mobile or address for search.');
    //     }
    //
    // };

    handleFormSearchVirtualDollarSubmit = (e) => {
        e.preventDefault();
        const formData = {
            mobile: e.target.mobile.value,
            emailAddress: e.target.emailAddress.value,
        };

        // Check if any of the fields have data to perform the search
        if (formData.mobile.trim() !== '' || formData.emailAddress.trim() !== '') {
            let searchResults = [];

            if (formData.mobile.trim() !== '') {
                // Tìm kiếm chính xác theo mobile
                searchResults = this.state.allUsers.filter(user => (
                    user.mobileNo === formData.mobile
                ));
            } else if (formData.emailAddress.trim() !== '') {
                // Tìm kiếm "like" theo email
                searchResults = this.state.allUsers.filter(user => (
                    user.email && user.email.includes(formData.emailAddress)
                ));
            }

            if (searchResults.length > 0) {
                this.setState({
                    formSearchVirtualDollarData: formData,
                    searchResults: searchResults,
                    selectedUser: null,
                });
            } else {
                alert('No user found with the provided mobile or email address.');
            }
        } else {
            alert('Please enter mobile or email address for search.');
        }
    };

    handleUserSelection = (user) => {
        this.setState({
            selectedUser: user,
            showFormSendVirtualDollar: true,
        });
    };

    handleFormReset = () => {
        this.setState({
            mobile: '',
            emailAddress: '',
            searchResults: [], // Đặt searchResults về trạng thái rỗng
            selectedUser: null,
            showFormSendVirtualDollar: false
        });
    }

    handleFormCouponReset = () => {
        this.setState({
            emailAddress: '',
        })
    }


    handleFormSendVirtualDollarSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData(e.target);

        const payload = {
            user: formData.get('username'),
            mini_game: formData.get('miniGameId'), // Lấy giá trị của miniGame từ biểu mẫu
            won_time: Math.floor(Date.now() / 1000),
            remarks: formData.get('reason'),
        };

        try {
            const response = await apiMiniGames.completeMiniGame(payload); // Gọi hàm completeMiniGame

            if (response.status == 200 || response.status == 201) {
                // Handle success
                // For example, you can reset the form or display a success message
                alert("Send success")
            } else {
                // Handle error
                // Display an error message or take appropriate action
                alert("Send failed")
            }
        } catch (error) {
            // Handle error
            // Display an error message or take appropriate action
        }

    };

    searchCoupon() {
        this.setState({ couponButton: true });
    }

    handleInputChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    }

    handleFormSearchCouponSubmit = () => {
        const { emailAddress } = this.state;

        if (!emailAddress) {
            alert('Please enter an email address for search.');
            return;
        }

        const { allUsers } = this.state;
        const searchUser = allUsers.find(user => user.email === emailAddress);
        const searchCouponResults = allUsers.filter(user => user.email.includes(emailAddress));

        if (searchCouponResults.length < 1) {
            alert('No user found with the provide email address.');
            return;
        }
        this.setState({
            couponButton: true,
            searchCouponResultList: searchCouponResults ? searchCouponResults : [],
            showMessage: !searchUser, // A flag to show a message if user is not found
            searchUserNotFound: !searchUser, // Another flag to trigger specific UI
        });
    }


    handleFormSendSubmit = async () => {
        const { searchUserToCoupon, valueCoupon, remarks } = this.state;

        const payload = {
            username: searchUserToCoupon,
            value: valueCoupon,
            remarks: remarks
        };

        console.log(payload);

        try {
            // Assuming apiAdminPortal.sendVoucher is a function that sends the voucher
            const response = await apiAdminPortal.sendVoucher(payload);
            if (response.status === 200) {
                // Show your success popup here
                alert('Send successful');
            } else {
                // Handle other status codes if needed
                console.error('Send failed:', response.status);
            }
        } catch (error) {
            console.error('Send error:', error);
        }
    };

    handleUserSelectionCoupon = (user) => {
        this.setState({
            searchUserToCoupon: user.username,
            searchUserLabel: user.legalFullName,
        });
    };

    render() {
        const { t } = this.props;

        const { formSearchVirtualDollarData, showFormSendVirtualDollar, allUsers, allUserAccounts, couponButton, miniGames, allMiniGames, searchUser, emailAddress, searchUserToCoupon, searchUserLabel, remarks, searchCouponResultList } = this.state;

        // const rankingTableInstance = new RankingTable(); // Create an instance
        // const rowData = rankingTableInstance.getRowData();

        let dataTable1 = [
            {}
        ];

        return (
            <div className="main__container" style={{ display: 'flex', flexDirection: 'column' }}>
                <Flex fontSize="1rem" mt="8rem" flexDirection="column">
                    <Flex fontSize="1.5rem" fontWeight="bold" mb="2rem" flexDirection="column">
                        <Flex mb="1rem">1. Scheduled task</Flex>
                        <StyledButton onClick={this.exportScheduledList}>
                            Export Scheduled Task List
                        </StyledButton>
                        <Flex alignItems="center">
                            <StyledDiv>
                                Import Scheduled Task List
                            </StyledDiv>
                            <input
                                accept=".csv"
                                id="import-task-button"
                                type="file"
                                onChange={(event) => {
                                    let newCsvFile = event.currentTarget.files[0];
                                    if (newCsvFile) {
                                        Papa.parse(newCsvFile, {
                                            complete: result => {
                                                this.importScheduledList(result.data)
                                            }
                                        })
                                    };
                                }}
                            />
                        </Flex>
                    </Flex>

                    <Flex mb="2rem" flexDirection="column">
                        <Flex fontSize="1.5rem" fontWeight="bold" mb="1rem">2. Approval User</Flex>
                        <StyledButton onClick={this.handleExportUser}>
                            Export Pending User
                        </StyledButton>
                        <Flex alignItems="center">
                            <StyledDiv>
                                Import Pending User
                            </StyledDiv>
                            <input
                                accept=".csv"
                                id="import-user-button"
                                type="file"
                                onChange={(event) => {
                                    let newCsvFile = event.currentTarget.files[0];
                                    if (newCsvFile) {
                                        Papa.parse(newCsvFile, {
                                            complete: result => {
                                                this.handleImportUsers(result.data)
                                            }
                                        })
                                    };
                                }}
                            />
                        </Flex>
                    </Flex>

                    <Flex mb="2rem" flexDirection="column">
                        <Flex fontSize="1.5rem" fontWeight="bold" mb="1rem">3. Fund update</Flex>
                        <Flex alignItems="center">
                            <StyledDiv>
                                Import Fund Price
                            </StyledDiv>
                            <input
                                accept=".csv"
                                id="import-fund-button"
                                type="file"
                                onChange={(event) => {
                                    let newCsvFile = event.currentTarget.files[0];
                                    if (newCsvFile) {
                                        Papa.parse(newCsvFile, {
                                            complete: result => {
                                                this.handleImportFundPrice(result.data)
                                            }
                                        })
                                    };
                                }}
                            />
                        </Flex>
                        <StyledButton onClick={this.downloadFundPriceSample} style={{ marginTop: '2rem' }}>
                            Download Fund Price Sample
                        </StyledButton>
                        <StyledButton onClick={this.updateFundPrice}>
                            Update Fund Price
                        </StyledButton>
                    </Flex>

                    <Flex mb="2rem" flexDirection="column">
                        <Flex mb="1rem" alignItems="center" justifyContent="space-between">
                            <Flex fontSize="1.5rem" fontWeight="bold">4. Account Management</Flex>
                            <Button style={{
                                backgroundColor: '#007bff',
                                color: '#fff',
                                padding: '10px 20px',
                                borderRadius: '5px',
                                border: 'none',
                                cursor: 'pointer'
                            }} onClick={this.handleExportAccountManagement}>Download Export</Button>
                        </Flex>
                        <Paging getUsersFunc={this._getUsers} allUsers={ allUsers } />
                    </Flex>

                    <Flex mb="2rem" flexDirection="column">
                        <Flex mb="1rem" alignItems="center" justifyContent="space-between">
                            <Flex fontSize="1.5rem" fontWeight="bold">5. Performance and Trade History</Flex>
                            <Button style={{
                                backgroundColor: '#007bff',
                                color: '#fff',
                                padding: '10px 20px',
                                borderRadius: '5px',
                                border: 'none',
                                cursor: 'pointer'
                            }} onClick={this.handleExportTradingHistory}>Export to Excel</Button>
                        </Flex>
                        
                        <Paging2 getUserAccountsFunc={this._getUserAccounts} allUserAccounts={ allUserAccounts } />
                    </Flex>

                    <Flex mb="2rem" flexDirection="column">
                        <Flex mb="1rem" alignItems="center" justifyContent="space-between">
                            <Flex fontSize="1.5rem" fontWeight="bold">6. Virtual Dollar Adjustment</Flex>
                        </Flex>

                        <Flex alignItems="center">
                            <Flex width="100%" alignItems="start" flexDirection="column">
                                <Formik>
                                    {formikProps => (
                                        <>
                                            <Form style={{ width: '70%', padding: '20px 20px 0', backgroundColor: '#fafafa', border: 'none' }}
                                                  onSubmit={this.handleFormSearchVirtualDollarSubmit}
                                                  onReset={this.handleFormReset}
                                            >
                                                <h2>Search</h2>

                                                <div className="form-row">
                                                    <label style={{maxWidth: '30%'}} htmlFor="mobile">Mobile:</label>
                                                    <input type="text" id="mobile" name="mobile" />
                                                </div>

                                                <div className="form-row search-input">
                                                    <label style={{maxWidth: '30%'}}  htmlFor="emailAddress">Email Address:</label>
                                                    <div style={{display: 'flex', maxWidth: '100%'}} >
                                                        <input style={{marginRight: '2rem' }}  type="text" id="emailAddress" name="emailAddress" />
                                                        <button style={{marginRight: '1rem'}} type="submit">Search</button>
                                                        <button type="reset" >Reset</button>
                                                    </div>

                                                </div>

                                            </Form>

                                            {this.state.searchResults.length > 0 && !showFormSendVirtualDollar && (
                                                <div>
                                                    <h3>Search Results:</h3>
                                                    <ul>
                                                        {this.state.searchResults.map(user => (
                                                            <li key={user.id}>
                                                                <input
                                                                    type="radio"
                                                                    name="selectedUser"
                                                                    id={`radio_${user.id}`}
                                                                    onChange={() => this.handleUserSelection(user)}
                                                                />
                                                                <label htmlFor={`radio_${user.id}`}>{user.emailAddress} - {user.username}</label>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            )}

                                            {/* Hiển thị FormSendVirtualDollar nếu showFormSendVirtualDollar là true */}
                                            {showFormSendVirtualDollar && this.state.selectedUser && (
                                                <Form style={{ width: '70%', padding: '0 20px', backgroundColor: '#fafafa', border: 'none' }}
                                                      onSubmit={this.handleFormSendVirtualDollarSubmit}

                                                >
                                                    <div className="form-row">
                                                        <label style={{maxWidth: '30%'}} htmlFor="fullname">Legal Full Name:</label>
                                                        <input style={{border: "none", backgroundColor: '#fafafa'}} type="text" value={this.state.selectedUser.legalFullName} id="fullname" name="fullname" />
                                                    </div>

                                                    <div className="form-row">
                                                        <label style={{maxWidth: '30%'}} htmlFor="username">User Name:</label>
                                                        <input style={{border: "none", backgroundColor: '#fafafa'}} type="text" value={this.state.selectedUser.username} id="username" name="username" required />
                                                    </div>

                                                    <div className="form-row">
                                                        <label style={{maxWidth: '30%'}} htmlFor="miniGame">Select a Mini Game:</label>
                                                        <select id="miniGameId" name="miniGameId" required>
                                                            <option value="">Select a Mini Game</option>
                                                            {allMiniGames.map((miniGame, index) => (
                                                                <option key={index} value={miniGame.mini_game_id}>
                                                                    {miniGame.title_eng}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>


                                                    <div className="form-row">
                                                        <label style={{maxWidth: '30%'}} htmlFor="virtual">Virtual Dollar:</label>
                                                        <input type="text" id="virtual" name="virtual" required />
                                                    </div>

                                                    <div className="form-row">
                                                        <label style={{maxWidth: '30%'}} htmlFor="reason">Reason:</label>
                                                        <input style={{marginRight: '2rem' }} type="text" id="reason" name="reason" required />
                                                        <button type="submit">Send</button>
                                                    </div>

                                                </Form>
                                            )}

                                        </>

                                    )}
                                </Formik>

                            </Flex>
                        </Flex>
                    </Flex>

                    <Flex mb="2rem" flexDirection="column">
                        <Flex mb="1rem" alignItems="center" justifyContent="space-between">
                            <Flex fontSize="1.5rem" fontWeight="bold">7. Ecoupon</Flex>
                        </Flex>
                        <Flex alignItems="center">
                            <StyledDiv>
                                Import QR code/links (CSV File)
                            </StyledDiv>
                            <input
                                accept=".csv"
                                id="import-task-button"
                                type="file"
                                onChange={(event) => {
                                    let newCsvFile = event.currentTarget.files[0];
                                    if (newCsvFile) {
                                        Papa.parse(newCsvFile, {
                                            complete: result => {
                                                this.importScheduledList(result.data)
                                            }
                                        })
                                    };
                                }}
                            />
                        </Flex>
                        {/*<StyledButton onClick={this.exportScheduledList}>*/}
                        {/*    Import QR code/links (CSV File)*/}
                        {/*</StyledButton>*/}

                        <Flex alignItems="center">
                            <Flex width="70%" alignItems="center">
                                <Formik
                                >
                                    {formikProps => (
                                        <Form style={{ width: '100%', padding: '20px', backgroundColor: '#fafafa', border: 'none' }}
                                              onReset={this.handleFormCouponReset}
                                        >
                                            <h2>Send E-coupon: to</h2>

                                            <div className="form-row search-input">
                                                <label style={{ maxWidth: '30%' }} htmlFor="emailAddress">
                                                    Email Address:
                                                </label>
                                                <div style={{ display: 'flex', maxWidth: '100%' }}>
                                                    <input
                                                        style={{ marginRight: '1rem' }}
                                                        type="text"
                                                        id="emailAddress"
                                                        name="emailAddress"
                                                        value={this.state.emailAddress}
                                                        onChange={this.handleInputChange}
                                                    />
                                                    <button
                                                        type="button"
                                                        onClick={this.handleFormSearchCouponSubmit}
                                                        style={{ marginRight: '0.5rem' }}
                                                    >
                                                        Search
                                                    </button>
                                                    <button type="reset">Reset</button>
                                                </div>
                                            </div>

                                            {this.state.searchCouponResultList.length > 0 && couponButton && (
                                                <div>
                                                    <h3>Search Results:</h3>
                                                    <ul>
                                                        {this.state.searchCouponResultList.map(user => (
                                                            <li key={user.id}>
                                                                <input
                                                                    type="radio"
                                                                    name="selectedUser"
                                                                    id={`radio_${user.id}`}
                                                                    onChange={() => this.handleUserSelectionCoupon(user)}
                                                                />
                                                                <label htmlFor={`radio_${user.id}`}>{user.emailAddress} - {user.username}</label>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            )}

                                            <div className="form-row">
                                                <label style={{maxWidth: '30%'}} htmlFor="fullname">Full Name:</label>
                                                <input style={{border: "none", backgroundColor: '#fafafa'}} type="text" value={searchUserLabel} id="fullname" name="fullname" disabled={true} />
                                            </div>

                                            <div className="form-row">
                                                <label style={{maxWidth: '30%'}} htmlFor="fullname">Username:</label>
                                                <input style={{border: "none", backgroundColor: '#fafafa'}} type="text" value={searchUserToCoupon} id="fullname" name="fullname" disabled={true} />
                                            </div>

                                            <div className="form-row search-input">
                                                <label style={{maxWidth: '30%'}}  htmlFor="couponLink">Coupon Link:</label>
                                                <div style={{display: 'flex', maxWidth: '100%'}} >
                                                    <input style={{marginRight: '2rem' }}  type="text" id="couponLink" name="couponLink" />
                                                </div>
                                            </div>

                                            <div className="form-row search-input">
                                                <label style={{maxWidth: '30%'}}  htmlFor="valueCoupon">Value:</label>
                                                <div style={{display: 'flex', maxWidth: '100%'}} >
                                                    <select id="valueCoupon" name="valueCoupon" onChange={this.handleInputChange}>
                                                        <option value="50">50</option>
                                                        <option value="100">100</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="form-row">
                                                <label style={{maxWidth: '30%'}} htmlFor="mobile">Reason:</label>
                                                <div style={{display: 'flex', maxWidth: '100%'}} >
                                                    <input style={{ marginRight: '2rem' }} type="text" id="address" name="remarks" onChange={this.handleInputChange}/>
                                                    {couponButton && <button type="button" onClick={this.handleFormSendSubmit}>Send</button>}
                                                </div>
                                            </div>

                                        </Form>
                                    )}
                                </Formik>

                            </Flex>
                        </Flex>
                    </Flex>

                    <Flex mb="2rem" flexDirection="column">
                        <Flex mb="1rem" alignItems="center" justifyContent="space-between">
                            <Flex fontSize="1.5rem" fontWeight="bold">8. Claim Report</Flex>
                        </Flex>
                        <StyledButton onClick={this.handleExportVouchers}>
                            Download Claim Report
                        </StyledButton>
                    </Flex>


                </Flex>
            </div>
        )
    }
}

export default withTranslation()(withRouter(AdminPortal));