import {api} from "./_ApiFactoryWithHeader";

export const apiAdminPortal = {
    getUsers: (params) => api.get('users', params),
    getUserAccounts: (params) => api.get('user_accounts', params),
    getInitialCapital: (params) => api.get('internal_configurations/5', params),
    getVouchers: (params) => api.get('vouchers', params),
    sendVoucher: (param) => api.post(`send_voucher`, param, null, null)
}

