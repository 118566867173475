// Essential for all components
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import { Flex } from 'reflexbox'
import {Button, makeStyles, Radio} from "@material-ui/core";

import {setBreadcrumb} from "../../Redux/Action/breadcrumbAction";
import {getUserInfo} from "../../Redux/Action/authAction";
import backIcon from "../../images/back.png";
import { apiTask } from '../../Api/_ApiTask';

const cnFutureInvestmentDescription = <span>更改尚未分配至您賬戶的所有未來款項投資分配（包括<b>定期供款及轉移自另一計劃的資產</b>）。參閱以下「重要事項」。</span>

const engFutureInvestmentDescription = <span>Change investment allocations for all your future monies (including <b>regular contributions and assets transferred from another scheme</b>) which have not been allocated to your account. See "Important notes" below. </span>

const withStyles = (Component) => {
    return function WrappedComponent(props) {
        const useStyles = makeStyles({
            root: {
                color: '#01a758',
                padding: 0,
                alignItems: 'start',
                "& .MuiSvgIcon-root": {
                    height: 30,
                    width: 30,
                    alignItems: 'start',
                    top: 0,
                },
                '&$checked': {
                    color: '#01a758'
                },
                '&::hover': {
                    opacity: 0
                },
            },
            checked: {}
        });
        const classes = useStyles();
        return <Component classes={classes} {...props} />
    }
}

class ManageFunds0 extends Component {

    state = {
        instructionType: 'existing',
    };

    componentDidMount() {
        const { history, i18n } = this.props;
        const param = {
            permission_name: 'switch fund'
        }
        apiTask.getTaskPermission(param).then(obj => {
            if (!obj.data[0].is_permitted) {
                alert('This instruction is not allowed at this moment');
                history.push('/' + i18n.language + `/index`)
            }
        })
    }

    // BUTTON FUNCTION

    back = () => {
        const { history } = this.props;
        history.goBack();
    }

    handleChange = e => {
        this.setState({ instructionType: e.target.value });

        // const svgIcons = document.getElementsByClassName('PrivateRadioButtonIcon-layer-23');
        // if (svgIcons.length > 0) {
        //     svgIcons[0].classList.add('MuiSvgIcon-checked');
        // }
    }

    handleSubmit = e => {
        const { instructionType } = this.state;
        const { i18n } = this.props;
        if (instructionType === 'existing') {                
            this.props.history.push('/' + i18n.language + '/manage-funds/1', {instructionType: instructionType});
        } else {
            this.props.history.push('/' + i18n.language + '/manage-funds/2', {instructionType: instructionType});
        }
    }

    render() {
        const classes = this.props.classes;
        const { t, i18n } = this.props;
        const { instructionType } = this.state;
        const isChinese = i18n.language === 'zh-HK';
        const futureInvestDesciption = isChinese ? cnFutureInvestmentDescription : engFutureInvestmentDescription;
        return (                    
            <div>
                <Flex className="main__container fund-manager">
                    <Flex flex={1} flexDirection="column">
                        <Flex flex={1} justifyContent="start" alignItems="center" className="header-title">
                            <img src={backIcon} className="back-icon" alt="back-icon" onClick={this.back}/>
                            <Flex ml="1rem">
                                {t("ManageFunds:manageFunds")}
                            </Flex>
                        </Flex>
                        <Flex flex={1} flexDirection="column" justifyContent="start" className="container">
                            <Flex flex={1} justifyContent="start" className="section-title large-font-size">
                                {t("ManageFunds:selectInstructionType")}
                            </Flex>
                            <Flex flex={1} flexDirection="column">
                                <Flex flex={1} justifyContent="start" className="section-type">                            
                                    {t("ManageFunds:forExistingAssets")}
                                </Flex>
                                <Flex flex={1} flexDirection="row" justifyContent="start" className="section-selection">
                                    <Flex minWidth={40}>
                                        <Radio disableRipple checked={instructionType === 'existing'} value="existing" onChange={this.handleChange} className={`${classes.root} ${classes.checked}`} style={{ backgroundColor: 'transparent' }} />
                                    </Flex>
                                    <Flex flexDirection="column">
                                        <Flex  className="selection-title bold">
                                            {`${t("ManageFunds:switchFunds")}`}
                                        </Flex>    
                                        <p className="selection-content small-font-size">
                                            {t("ManageFunds:switchExistingFundDescription")}
                                            <a href={`https://www.manulife.com.hk/${isChinese ? 'zh-hk' : 'en'}/individual/services/manage-your-account/switch-funds-pf-how-it-works.html`} target="_blank" rel="noopener noreferrer" className='hyperlink bold link'>{t("Common:General.learnMore")}</a>
                                        </p>    
                                    </Flex>
                                </Flex>
                            </Flex>
                            <Flex flex={1} flexDirection="column">
                                <Flex flex={1} justifyContent="start" className="section-type">                            
                                    {t("ManageFunds:forNewMonies")}
                                </Flex>
                                <Flex flex={1} flexDirection="row" justifyContent="start" className="section-selection">
                                    <Flex minWidth={40}>
                                        <Radio disableRipple checked={instructionType === 'new'} value="new" onChange={this.handleChange} className={`${classes.root} ${classes.checked}`} style={{ backgroundColor: 'transparent' }} />
                                    </Flex>
                                    <Flex flexDirection="column">
                                        <Flex  className="selection-title bold">
                                            {t("ManageFunds:changeFutureInvestments")}
                                        </Flex>    
                                        <p className="selection-content small-font-size"> 
                                            {futureInvestDesciption}
                                            <a href={`https://www.manulife.com.hk/${isChinese ? 'zh-hk' : 'en'}/individual/services/manage-your-account/change-future-investments-pf.html`} target="_blank" rel="noopener noreferrer" className='hyperlink bold link'>{t("Common:General.learnMore")}</a>
                                        </p>    
                                    </Flex>
                                </Flex>
                            </Flex>
                            <Flex className="black-text small-font-size" padding="1rem 1rem 0 1rem" mt="1rem" flexDirection="column" mb="1rem">
                                {t("ManageFunds:notes")}:
                                <Flex flexDirection="column" mt="1rem" className="light-black">
                                    <Flex flexDirection="row">
                                        <Flex flex={1/15} maxWidth={30}>1.</Flex>
                                        <Flex flex={1}>{t('ManageFunds:thisIsAVirtualInvestmentGame')}</Flex>
                                    </Flex>
                                </Flex>
                            </Flex>
                            <Flex flex={1} className="button-wrapper">
                                <Button disabled={!instructionType} onClick={this.handleSubmit} className="primary-button m0 p1rem" style={{ maxWidth: '100%', background: '#ec6453', color: 'white', border: 'none', borderRadius: 'none' }}>{t("Common:Button.continue")}</Button>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    breadcrumbArr: state.breadcrumb.breadcrumbArr
});
const mapDispatchToProps = dispatch => ({
    setBreadcrumbP: data => dispatch(setBreadcrumb(data)),
    getUserInfoP: data => dispatch(getUserInfo(data)),
});
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(ManageFunds0))));
