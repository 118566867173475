function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

export function naturalSorting(data, order, orderBy){

    if(data.length === 0 || typeof(data[0][orderBy]) == 'undefined'){
        if(orderBy === 'desc'){
            return data.reverse();
        }else{
            return data;
        }
    } 
    const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
    let sorted;

    if(order === 'asc'){
        sorted = data.sort((a, b) => collator.compare([a[orderBy]], [b[orderBy]]) );
    }else{
        sorted = data.sort((a, b) => -collator.compare([a[orderBy]], [b[orderBy]]) );

    }

    return sorted;
}

export function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

export function getOrderData(property, stateOrderBy, stateOrder) {
    const orderBy = property;
    let order = 'desc';
    if (stateOrderBy === property && stateOrder === 'desc') {
        order = 'asc';
    }
    return {orderBy: orderBy, order: order};
}

export function getSelectedData(id, currentSelected) {
    const selectedIndex = currentSelected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
        newSelected = newSelected.concat(currentSelected, id);
    } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(currentSelected.slice(1));
    } else if (selectedIndex === currentSelected.length - 1) {
        newSelected = newSelected.concat(currentSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
            currentSelected.slice(0, selectedIndex),
            currentSelected.slice(selectedIndex + 1),
        );
    }

    return newSelected;
}