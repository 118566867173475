import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Flex } from 'reflexbox';
import { connect } from 'react-redux';

import { apiAuth } from '../../Api/ApiAuth';
import { login, forgetPasswordClear } from '../../Redux/Action/authAction';


class ResetPassword extends Component {

    state = {
        userStatus: ''
    }

    _confirmUserAsync = (username) => {
        var promise = Promise.resolve();
        apiAuth.getClientCredentials().then((res) => {
            promise.then(() => {
                this._confirmUser(username, res.data.access_token);
            });
        })
    }

    _confirmUser = (values, token) => {
        const { i18n, history } = this.props;

        const cb = (obj) => {
            const userStatus = obj.body.status;
            if (userStatus === 'pending') {
                this.setState({ userStatus: 'pending' });
            } else {
                history.push(`/${i18n.language}/landing`);
            }
        }

        const eCb = (obj) => {
            console.log("eCb : ", obj);
        }

        const params = null;
        const accept = 'application/json';
        const contentType = "application/x-www-form-urlencoded";
        apiAuth.confirmUser(values, params, token, cb, eCb, accept, contentType);
    }

    componentDidMount() {
        let params = (new URL(document.location)).searchParams;
        let username = params.get('username');
        this._confirmUserAsync(username);
    }

    render() {
        const { i18n } = this.props
        const { userStatus } = this.state;
        return (
            <div className='landing-content' style={{ height: '100%' }}>
                <div className="main__container" style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white', minHeight: '100%' }}>
                    {userStatus === 'pending' && <Flex marginY="3rem">
                        {i18n.language === 'en-US' ? <Flex flexDirection="column">
                            <p>Congratulations! You are already registered to the <b>MPF FUN GAME</b>. We are currently reviewing your account information and verifying your details. This process may take up to 24-72 hours to complete. We kindly ask for your patience and understanding during this time. Once your account has been approved, you will receive a welcome email.</p>
                            <p>Thank you for your interest in the <b>MPF FUN GAME</b>. If you have any questions or concerns regarding your account verification, please do not hesitate to contact us at info@mpfree.com.hk.</p>
                            <p>We wish you the best of luck in the Game!</p>
                        </Flex> :
                        <Flex flexDirection="column">
                            <p>恭喜您！您已登記參加MPF積FUN賽。我們正在審示您的帳戶並核實資料。整個過程約需24-72小時才能完成。期間請耐心等侯，還請見諒。待帳戶一經批核，您將會收到一封歡迎電郵。</p>
                            <p>多謝您對MPF積FUN賽感興趣，如果您對帳戶驗證有任何疑問，請隨時電郵至info@mpfree.com.hk與我們聯絡。</p>
                        </Flex>}
                    </Flex>}
                    {/* {userStatus === 'approved' &&
                        <Flex style={{ justifyContent: 'center', position: 'absolute', top: '50%', width: '100%' }}>
                            Your account registration is approved. You can now login to your account.
                        </Flex>
                    }
                    {userStatus === 'not verified' &&
                        <Flex style={{ justifyContent: 'center', position: 'absolute', top: '50%', width: '100%' }}>
                            Something went wrong. Please try again.
                        </Flex>
                    } */}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    loginP: data => dispatch(login(data)),
    forgetPasswordClearP: data => dispatch(forgetPasswordClear(data))
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(ResetPassword)));
