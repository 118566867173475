export const getValAfterSlash = (val) => {
    const index = val.lastIndexOf('/');
    const sum = val.substring(index+1);
    console.log(`Total amount of data: ${sum}`);
    return sum;
}

export const handleSpecialFieldName = (val) => {
    switch (val){
        // General
        case 'created_date' : return 'createddate';
        case 'updated_date' : return 'lastmoddate';
        // Field update date in Registration form
        case 'update_date' : return 'lastmoddate';
        // Field "Language" in all list
        case 'language_display' : return 'language';
        // Field "Type" in /file-mgt/* 
        case 'type_display' : return 'type';
        case 'status_display' : return 'status';
        // Field "Hightlight" in /seed-wishes-mgt
        case 'highlight' : return 'highlight_type';
        // Field "Status" in /hashtag-mgt/*
        case 'status' : return 'article_approval_status';
        // Field "keywords" in /wish-categories
        case 'no_of_keywords' : return 'wish_keyword_count';

        default: return val;
    }
}

export const getPreviousPage = (currentPaginator, pageId) => {
    return [...currentPaginator].find(item => item.page_id === pageId);
}

export const getCurrentStateOfPage = (previousPage, rowsPerPageDefault, orderByDefault, orderDefault) => {
    const currentPage = previousPage ? previousPage.page : 0,
        rowsPerPage =  previousPage? previousPage.rowsPerPage : rowsPerPageDefault,
        orderBy = previousPage? previousPage.orderBy : orderByDefault,
        order = previousPage? previousPage.order : orderDefault;
    return {currentPage, rowsPerPage, orderBy, order}
}