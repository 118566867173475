// Essential for all components
import React, { Component, createRef } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import i18n from '../../i18n/i18n';
import '../../css/00Home/_landing.scss';

import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import { apiAuth } from '../../Api/ApiAuth';
import { apiTask } from '../../Api/_ApiTask';
import { Flex } from 'reflexbox';

const imagePaths = {
    'en-US': {
        // bg: require('../../images/Landingpg/Registration-period/Background eng2_1.png'),
        iconMPF: require('../../images/Landingpg/Registration-period/logo english.png'),
        buttonRegister: require('../../images/Landingpg/Registration-period/Joinbutton.png'),
        gameInfo: require('../../images/Landingpg/Registration-period/Gameinfo ENG.png'),
        gameInfoMobi: require('../../images/Landingpg/Registration-period/Mobile page/Game info Mobile eng.png'),
        hk200: require('../../images/Landingpg/Registration-period/ticket-04.png'),
        hk100: require('../../images/Landingpg/Registration-period/ticket-05.png'),
        howToPlay: require('../../images/Landingpg/Registration-period/How to play ENG.png'),
        howToPlayMobi: require('../../images/Landingpg/Registration-period/Bubble mobile ENG.png'),
        step: require('../../images/Landingpg/Registration-period/Bubble ENG.png'),
        rewards: require('../../images/Landingpg/Registration-period/RewardsENG.png'),
        aCash: require('../../images/Landingpg/Registration-period/Asset 183.png'),
        place1st: require('../../images/Landingpg/Registration-period/1stplace.png'),
        place2nd: require('../../images/Landingpg/Registration-period/2ndplace.png'),
        place3rd: require('../../images/Landingpg/Registration-period/3rdplace.png'),
        place4th: require('../../images/Landingpg/Registration-period/4thplace.png'),
        place5th: require('../../images/Landingpg/Registration-period/5thplace.png'),
        content1st: require('../../images/Landingpg/Registration-period/1st prize.png'),
        content2nd_5th: require('../../images/Landingpg/Registration-period/2nd-5th prize.png'),
        luckydraw: require('../../images/Landingpg/Registration-period/LuckydrawENG.png'),
        result_1st: require('../../images/Landingpg/Registration-period/1st eng.png'),
        result_2nd: require('../../images/Landingpg/Registration-period/2nd eng.png'),
        result_3rd: require('../../images/Landingpg/Registration-period/3rd eng_3.png'),
        result_4th: require('../../images/Landingpg/Registration-period/4th eng_2.png'),
        result_5th: require('../../images/Landingpg/Registration-period/5th eng_2.png'),
        result_6th_200th: require('../../images/Landingpg/Registration-period/6th-200th eng .png'),
    },

    'zh-HK': {
        // bg: require('../../images/Landingpg/Registration-period/Background 1.png'),
        iconMPF: require('../../images/Landingpg/Registration-period/MPFgameLogo.png'),
        buttonRegister: require('../../images/Landingpg/Registration-period/Joinbutton.png'),
        gameInfo: require('../../images/Landingpg/Registration-period/gameinfo CHIN.png'),
        gameInfoMobi: require('../../images/Landingpg/Registration-period/Mobile page/Game info Mobile Chin.png'),
        hk200: require('../../images/Landingpg/Registration-period/ticket-06.png'),
        hk100: require('../../images/Landingpg/Registration-period/ticket-07.png'),
        howToPlay: require('../../images/Landingpg/Registration-period/How to play CHINESE.png'),
        howToPlayMobi: require('../../images/Landingpg/Registration-period/Mobile page/Bubble CHI mobile.png'),
        step: require('../../images/Landingpg/Registration-period/Bubble CHI.png'),
        rewards: require('../../images/Landingpg/Registration-period/prize Chinese.png'),
        aCash: require('../../images/Landingpg/Registration-period/Asset 181.png'),
        place1st: require('../../images/Landingpg/Registration-period/1stplace.png'),
        place2nd: require('../../images/Landingpg/Registration-period/2ndplace.png'),
        place3rd: require('../../images/Landingpg/Registration-period/3rdplace.png'),
        place4th: require('../../images/Landingpg/Registration-period/4thplace.png'),
        place5th: require('../../images/Landingpg/Registration-period/5thplace.png'),
        content1st: require('../../images/Landingpg/Registration-period/1st prize.png'),
        content_2nd_5th: require('../../images/Landingpg/Registration-period/2nd-5th prize.png'),
        luckydraw: require('../../images/Landingpg/Registration-period/Luckydraw.png'),
        result_1st: require('../../images/Landingpg/Registration-period/1st.png'),
        result_2nd: require('../../images/Landingpg/Registration-period/2nd CHI.png'),
        result_3rd: require('../../images/Landingpg/Registration-period/3rd_1.png'),
        result_4th: require('../../images/Landingpg/Registration-period/4th_1.png'),
        result_5th: require('../../images/Landingpg/Registration-period/5th_1.png'),
        result_6th_200th: require('../../images/Landingpg/Registration-period/6th200th.png'),
    }

};

class Landingpg extends Component {

    state = {
        registration: null,
    }

    componentDidMount = () => {
        this._getTaskPermissionAsync();

        const { location } = this.props;
        const section = location.hash.replace('#', '');
        const element = document.getElementById(section);
        if (element) {
            element.scrollIntoView();
        }
    }

    _getTaskPermissionAsync = () => {
        return apiAuth.getClientCredentials().then((res) => {
            return this._getTaskPermission(res.data.access_token);
        })
    }

    _getTaskPermission = (token) => {
        const cb = (obj) => {
            if (obj) {
                obj.body.forEach(task => {
                    if (task.permission_name === 'registration') {
                        this.setState({ registration: task.is_permitted });
                    }
                })
            }
        }

        const eCb = (obj) => {
            console.log("eCb : ", obj);
        }

        const params = null;
        const accept = 'application/json';
        const contentType = "application/x-www-form-urlencoded";
        return apiTask.getTaskPermissions(params, token, cb, eCb, accept, contentType);
    }

    handleClickTandC = () => {
        const { i18n } = this.props;
        window.open(`/${i18n.language}/lucky-draw-tandc`, '_blank');
    }

    render() {
        const { t, i18n } = this.props;
        const { registration } = this.state;

        return (
            <div className='landing-container'>
                {/* <img src={require('../../images/Landingpg/Registration-period/Background eng2_1.png')} style={{ position: 'absolute', width: '100vw', zIndex: -5, height: 'auto'}} alt="" /> */}
                <div className={`landing-content ${i18n.language === 'en-US' ? 'landing-content-eng' : ''}`}>
                    <div id='introduction' className="iconMPF">
                        <img src={imagePaths[i18n.language].iconMPF} alt='Icon MPF' />
                    </div>

                    <div className={`text ${i18n.language === 'en-US' ? 'text-eng' : ''}`}>
                        <p style={{letterSpacing: i18n.language === 'en-US' ? '0rem' : '0.2rem'}}>
                            {t("Landingpg:contentPage-1")}
                        </p>
                        <p style={{letterSpacing: i18n.language === 'en-US' ? '0rem' : '0.2rem'}}>
                            {t("Landingpg:contentPage-2")}
                        </p>
                        {i18n.language === 'en-US' && <p style={{letterSpacing: i18n.language === 'en-US' ? '0rem' : '0.2rem'}}>
                            {t("Landingpg:contentPage-3")}
                        </p>}
                        {i18n.language === 'en-US' && <p style={{letterSpacing: i18n.language === 'en-US' ? '0rem' : '0.2rem'}}>
                            {t("Landingpg:contentPage-4")}
                        </p>}
                    </div>

                    {registration && <div style={{ marginBottom: i18n.language === 'en-US' ? 0 : '5vw' }} className={`align-items-center ${i18n.language ==='en-US' && 'align-items-center-eng'}`}>
                        <div
                            style={{ letterSpacing: i18n.language === 'en-US' ? '0rem' : '0.2rem', cursor: 'pointer' }} className={`text-green ${i18n.language === 'en-US' && 'text-green-eng' }`}
                            onClick={() => {
                                this.props.history.push(`/${i18n.language}/register`);
                            }}
                        >
                            {t("Landingpg:register")}
                        </div>
                    </div>}

                    {/*<div className='participation-container'>*/}
                    {/*    <div className="participation-1">*/}
                    {/*        {t("Landingpg:Participation Quota 1")}*/}
                    {/*    </div>*/}
                    {/*    <div className={`participation-2 ${i18n.language === 'zh-HK' ? 'letterCHI' : ''}`}>*/}
                    {/*        {t("Landingpg:Participation Quota 2")}*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className='game-info' style={{ marginTop: registration ? 0 : i18n.language === 'en-US' ? '5vw' : '11.5vw' }}>
                        <img src={imagePaths[i18n.language].gameInfo} alt='Game Info' />
                    </div>

                    <div className='game-info-mobile'>
                        <img src={imagePaths[i18n.language].gameInfoMobi} alt='Game Info' />
                    </div>

                    <div className={`stand ${i18n.language === 'en-US' && 'stand-eng'}`}>
                        <p>
                            {t("Landingpg:winFabulousPrizes")}
                        </p>
                    </div>

                    <div className={`HK-container ${i18n.language !== 'en-US' && 'HK-container-cn'} ${!registration && 'HK-container-non-registration'}`}>
                        <img src={imagePaths[i18n.language].hk200} alt='HK200'/>
                        {/*<span>+</span>*/}
                        <span>
                            <AddRoundedIcon style={{ fontSize: '10vw' }} />
                        </span>
                        <img src={imagePaths[i18n.language].hk100} alt='HK100' />
                    </div>

                    <div className='howToPlay' id='howToPlay'>
                        <img src={imagePaths[i18n.language].howToPlay} alt='How to Play' />
                    </div>

                    <div className={`howToPlayMobi ${i18n.language == 'en-US' ? 'howToPlayMobi-eng' : ''}`} id='howToPlayMobi'>
                        <img src={imagePaths[i18n.language].howToPlayMobi} alt='How to Play' />
                    </div>

                    <div className={`step ${i18n.language} ==='en-US' ? 'step-eng' : ''`}>
                        <img src={imagePaths[i18n.language].step} alt='Step' className='step' />
                    </div>

                    <div className={`prize  ${i18n.language === 'en-US' ? 'prize-eng' : ''}`} id='prizeDesk'>
                        <img src={imagePaths[i18n.language].rewards} alt='prize Chinese' />
                        <div className='prizeDesk'>
                            <p style={{letterSpacing: i18n.language === 'en-US' ? '0rem' : '0.2rem'}}>
                                {t("Landingpg:atTheEndOfTheGame-1")}
                            </p>
                            <p style={{letterSpacing: i18n.language === 'en-US' ? '0rem' : '0.2rem'}}>
                                {t("Landingpg:atTheEndOfTheGame-2")}
                            </p>
                            <p style={{letterSpacing: i18n.language === 'en-US' ? '0rem' : '0.2rem'}}>
                                {t("Landingpg:atTheEndOfTheGame-3")}
                            </p>
                        </div>
                        <div className={`prizeMobi ${i18n.language === 'en-US' ? 'prizeMobi-eng' : ''}`} id='prizeMobi'>
                            <p>
                                {t("Landingpg:atTheEndOfTheGame-1")}
                            </p>
                            <p>
                                {t("Landingpg:atTheEndOfTheGame-2")}
                            </p>
                            <p>
                                {t("Landingpg:atTheEndOfTheGame-3")}
                            </p>
                        </div>
                    </div>


                    <div className='rank'>
                        <div className='rank-1-2-3'>
                            <div className='wraperRank-2nd-3rd'>
                                <div className='rank-2nd-3rd'>
                                    <img src={imagePaths[i18n.language].place2nd} alt='place2nd' />
                                    <div>
                                        <div className={`border-2rd-5th ${i18n.language === 'en-US' ? 'border-2rd-5th-eng' : ''}`}>
                                            <p>
                                                {t("Landingpg:hk10-up")}
                                            </p>
                                            <div>
                                                {t("Landingpg:hk10-down")}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='rank-1st'>
                                <img src={imagePaths[i18n.language].place1st} alt='place1st' />
                                <div>
                                    {/*<img style={{width: '40vw', height: 'auto'}} src={imagePaths[i18n.language].aCash} alt='aCash' />*/}
                                    <div className={`border-1st ${i18n.language === 'en-US' ? 'border-1st-eng' : ''}`}>
                                        <p style={{lineHeight: 1}}>
                                            {t("Landingpg:cashRewardEquivalent")}
                                        </p>
                                        <div>
                                            {t("Landingpg:ofHisHerVirtual")}
                                        </div>
                                        <div style={{fontSize: '1.3vw', fontWeight: '100'}}>
                                        {/* <div style={{fontSize: '1.3vw', fontWeight: '100', fontFamily: 'jf-openhuninn'}}> */}
                                            {t("Landingpg:capped")}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='wraperRank-2nd-3rd'>
                                <div className='rank-2nd-3rd'>
                                    <img src={imagePaths[i18n.language].place3rd} alt='place3rd' />
                                    <div>
                                        <div className={`border-2rd-5th ${i18n.language === 'en-US' ? 'border-2rd-5th-eng' : ''}`}>
                                            <p>
                                                {t("Landingpg:hk8-up")}
                                            </p>
                                            <div>
                                                {t("Landingpg:hk8-down")}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='rank-1-2-3-Mobi'>

                            <div className='rank-1st'>
                                <img src={imagePaths[i18n.language].place1st} alt='place1st' />
                                {/*<div>*/}
                                {/*    <img style={{width: '40vw', height: 'auto'}} src={imagePaths[i18n.language].aCash} alt='aCash' />*/}
                                {/*</div>*/}
                                <div className={`border-1st ${i18n.language === 'en-US' ? 'border-1st-eng' : ''}`}>
                                    <p>
                                        {t("Landingpg:cashRewardEquivalent")}
                                    </p>
                                    <div>
                                        {t("Landingpg:ofHisHerVirtual")}
                                    </div>
                                </div>
                            </div>

                            <div className='rank-2-3-Mobi'>
                                <div className='wraperRank-2nd-3rd'>
                                    <div className='rank-2nd-3rd'>
                                        <img src={imagePaths[i18n.language].place2nd} alt='place2nd' />
                                        <div>
                                            <div className={`border-2rd-5th ${i18n.language === 'en-US' ? 'border-2rd-5th-eng' : ''}`}>
                                                <p>
                                                    {t("Landingpg:hk10-up")}
                                                </p>
                                                <div>
                                                    {t("Landingpg:hk10-down")}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='wraperRank-2nd-3rd'>
                                    <div className='rank-2nd-3rd'>
                                        <img src={imagePaths[i18n.language].place3rd} alt='place3rd' />
                                        <div>
                                            <div className={`border-2rd-5th ${i18n.language === 'en-US' ? 'border-2rd-5th-eng' : ''}`}>
                                                <p>
                                                    {t("Landingpg:hk8-up")}
                                                </p>
                                                <div>
                                                    {t("Landingpg:hk8-down")}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='rank-4-5' style={{ marginTop: i18n.language === 'en-US' && '1rem' }}>
                            <div className='rank-4th-5th'>
                                <img src={imagePaths[i18n.language].place4th} alt='place4th' />
                                <div>
                                    {/*<div className={`border-2rd-5th ${i18n.language === 'en-US' ? 'border-2rd-5th-eng' : ''}`}>*/}
                                    <div className={`border-2rd-5th ${i18n.language === 'en-US' ? 'border-2rd-5th-eng' : ''}`}>
                                        <p>
                                        {/* <p style={{ ...(i18n.language === 'en-US' && {fontSize: '1.6vw', letterSpacing: '0.1vw', fontWeight: 'bold'}) }}> */}
                                            {t("Landingpg:hk6-up")}
                                        </p>
                                        <div>
                                            {t("Landingpg:hk6-down")}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='rank-4th-5th'>
                                <img src={imagePaths[i18n.language].place5th} alt='place5th' />
                                <div>
                                    <div className={`border-2rd-5th ${i18n.language === 'en-US' ? 'border-2rd-5th-eng' : ''}`}>
                                        <p>
                                            {t("Landingpg:hk5-up")}
                                        </p>
                                        <div>
                                            {t("Landingpg:hk5-down")}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={`star ${i18n.language === 'en-US' ? 'star-eng' : ''}`}>
                        <img src={imagePaths[i18n.language].luckydraw} alt='luckydraw' />
                    </div>

                    <div className='result'>
                        <div className='result-1-2-3'>
                            <img src={imagePaths[i18n.language].result_1st} alt='result_1st' />
                            <img src={imagePaths[i18n.language].result_2nd} alt='result_2nd' />
                            <img src={imagePaths[i18n.language].result_3rd} alt='result_3rd' />
                        </div>

                        <div className='result-4-5'>
                            <img src={imagePaths[i18n.language].result_4th} alt='result_4th' />
                            <img src={imagePaths[i18n.language].result_5th} alt='result_5th' />
                        </div>

                        <div style={{ flexDirection: 'column', alignItems: 'center' }} className={`result-6 ${i18n.language === 'en-US' ? 'result-6-eng' : ''}`} id="tAndC">
                            <img src={imagePaths[i18n.language].result_6th_200th} alt='result_6th_200th' />
                            <Flex className='total-prizes'>
                                {t("Landingpg:total200")}
                            </Flex>
                            <div style={{padding: `${i18n.language === 'en-US' ? `10px` : `5px`} 10px`}} className="draw-result-container">
                                <div className="draw-result" style={{ fontWeight: 'bolder' }}>
                                    {t("Landingpg:drawResult")}
                                </div>
                                <div className="draw-result-note">
                                    {t("Landingpg:drawResult_note")}
                                </div>
                            </div>
                        </div>
                    </div>

                    <Flex className="terms" onClick={this.handleClickTandC}>
                        <Flex flexDirection="column">
                            <Flex>
                                {t("Landingpg:footer1")}
                            </Flex>
                            <Flex style={{paddingLeft: `${i18n.language === 'en-US' ? '0' : '1vw'}`}}>
                                {t("Landingpg:footer2")}
                            </Flex>
                        </Flex>
                        <NavigateNextIcon style={{ fontSize: '5vw' }} />
                    </Flex>

                    {/* <div className={`footer ${i18n.language === 'en-US' ? 'footer-eng' : 'footer-chi'}`}>
                        <div className='text-desktop'>
                            <div>
                                {t("Landingpg:footer-des")}
                            </div>
                        </div>
                        <div className='next'>
                            <NavigateNextIcon className='icon-next' />
                        </div>
                    </div> */}

                    {/* <div className='footer-Mobi'>
                        <div className='text'>
                            {t("Landingpg:footer-des")}
                        </div>
                        <div className='next'>
                            <p>
                                {t("Landingpg:footer1")}
                            </p>
                            <p className='text-last' style={{paddingLeft: `${i18n.language === 'en-US' ? '0' : '5px'}`}}>
                                {t("Landingpg:footer2")}
                            </p>
                            <NavigateNextIcon className='icon-next' />
                        </div>
                    </div> */}
                </div>
            </div>
        )
    }

}


export default withTranslation()(withRouter((Landingpg)));
